import InputContainer from '../../../../../common/InputContainer';
import './moderatorAddDisplay.css'

const ModeratorAddDisplay = ({
    handelKeyPressAddingModerator,
    newModeratorInput,
    setNewModeratorInput,
    inputDisplayMessage,
    isButtonDisabled,
    addModerator
}) => {
    
    return <div className='moderatorBroadcasterDisplayInput'>
        <InputContainer 
            onInput={e => setNewModeratorInput(e.target.value)}
            placeholder='Add a new moderator!'
            buttonText='Add'
            buttonIsGray={isButtonDisabled} 
            buttonOnClick={addModerator}
            messageText={inputDisplayMessage}
            onKeyPress={handelKeyPressAddingModerator}
            value={newModeratorInput}
        />
    </div>
 }
 
 export default ModeratorAddDisplay;