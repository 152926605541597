import memeService from '../../../../../services/MemeService';
import notificationService from '../../../../../services/NotificationService';
import BlueButton from '../../../../common/BlueButton/BlueButton';
import errorHandler from '../../../../../services/ErrorHandler';
import BlueSlider from '../../../../common/BlueSlider/BlueSlider';
import { useState } from 'react';

const TimeDisplayComponent = ({
    browserSourceSettings,
    setBrowserSourceSettings, 
}) => {

    const [isSaveButtonGray, setIsSaveButtonGray] = useState(false);
    const [newInterval, setNewInterval] = useState(null);
    const [newTimeOut, setNewTimeOut] = useState(null);

    //convert ms to minutes and seconds
    const millisToMinutesAndSeconds = (millisRaw) => {
        let millis = parseInt(millisRaw)
        let minutes = Math.floor(millis / 60000);
        let seconds = ((millis % 60000) / 1000).toFixed(0);
        if (!minutes) {
        return (seconds < 10 ? '0' : '') + seconds + ' seconds';
        }
        else {
        return minutes + ":" + (seconds < 10 ? '0' : '') + seconds + ' minutes';
        }
    };
    
     //updates user settings
    const updateUserSettings = async () => {
        setIsSaveButtonGray(true);
        
        let intervalToUpdate = newInterval ? parseInt(newInterval) : browserSourceSettings.browserSourceInterval
        let timeOutToUpdate = newTimeOut ? parseInt(newTimeOut) : browserSourceSettings.browserSourceTimeOut
        let notifySoundToUpdate = browserSourceSettings.browserSourceNotifySound
        let themeStyleToUpdate = browserSourceSettings.broadcasterThemeStyle 
    
        if (
        !isNaN(intervalToUpdate) &&
        !isNaN(timeOutToUpdate) && 
        !isNaN(notifySoundToUpdate) && 
        !isNaN(themeStyleToUpdate)
        ) {
            let settings = await memeService.updateBroadcasterSettings(
                intervalToUpdate,
                notifySoundToUpdate,
                themeStyleToUpdate,
                timeOutToUpdate
            )

            if (!settings.errorCode) {
                notificationService.setMessage('Your settings have been saved successfully!');
                notificationService.setTitle('Settings')

                setBrowserSourceSettings({
                    browserSourceInterval: parseInt(settings.browserSourceInterval),
                    browserSourceTimeOut: parseInt(settings.browserSourceTimeOut),
                    browserSourceNotifySound: parseInt(settings.browserSourceNotifySound),
                    broadcasterThemeStyle: parseInt(settings.broadcasterThemeStyle),
                })

            } else {
                notificationService.setMessage(errorHandler.handleErrorCode(settings));
                notificationService.setTitle('Settings')
            }
            
        } else {
            notificationService.setMessage('Sorry! I was unable to update your settings. Please try again and if you still have difficulty, contact our support team for assistance.');
            notificationService.setTitle('Settings');
        }

        setIsSaveButtonGray(false);
    };

    const display = <>
        <h3>Display time on stream</h3>
        <p className="settingsDescription">This determines how long the meme will remain visible.</p>
        <BlueSlider 
            text={'Duration '+ (newInterval ? millisToMinutesAndSeconds(newInterval) : millisToMinutesAndSeconds(browserSourceSettings.browserSourceInterval))} 
            id='intervalDisplay'
            name='intervalDisplay'
            min={5000}
            max={20000}
            defaultValue={JSON.stringify(browserSourceSettings.browserSourceInterval)}
            onChange={(e) => setNewInterval(parseInt(e.target.value))}
            step={500}
        />
        <br/>
        <br/>
        <h3>Meme Frequency</h3>
        <p className="settingsDescription">This determines how frequently viewers can create new memes.
        We try our best to restrict meme creation to this limit but it is sometimes possible for multiple memes to be created at once.</p>
        <BlueSlider 
            text={'Duration '+ (newTimeOut ? millisToMinutesAndSeconds(newTimeOut) : millisToMinutesAndSeconds(browserSourceSettings.browserSourceTimeOut))} 
            id='timeOutDisplay'
            name='timeOutDisplay'
            min={1}
            max={300001}
            defaultValue={JSON.stringify(browserSourceSettings.browserSourceTimeOut)}
            onChange={(e) => setNewTimeOut(parseInt(e.target.value))}
            step={10000}
        />
        <br/>
        <br/>
        <BlueButton text='save' onClick={updateUserSettings} isCenter={true} isGray={isSaveButtonGray}/>
    </> 

    return display
}

export default TimeDisplayComponent;