const FilterHeader = ({
    activeFilters
}) => {
    return(
    <>
        <h3 className='centerText'>Blocked Terms and Phrases</h3>
        <p className='filterInfoText'>
        Control the types of memes created on your stream by defining terms and phrases to filter out. Use this feature to block any text entered by viewers that matches words or phrases you've defined, ensuring the safety and enjoyment of your stream for all viewers. Decide which words or phrases to censor, such as swear words or those associated with hate speech or harassment.
        </p>
    </>
    )
}

export default FilterHeader;