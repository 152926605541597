import './BlueCheckBox.css';
const BlueCheckBox = ({
    text,
    id,
    onChange,
    defaultChecked
}) => {
    return(
        <>
           <input className='BlueCheckBoxInput' type="checkbox" id={id} name="checkbox" onChange={onChange} defaultChecked={defaultChecked}/>
           <label className='BlueCheckBoxLabel' forhtml={id}>{text}</label> 
        </>
    )
}

export default BlueCheckBox;