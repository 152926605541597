import { useState, useRef } from "react";
import Container from "../../../common/Container";
import memeService from '../../../../services/MemeService';
import './Browse.css';
import notificationService from '../../../../services/NotificationService';
import BrowseTemplates from './BrowseTemplates';
import ImageSelectorRouter from "./ImageSelectorCardDisplay/ImageSelectorRouter";
import BlueProgressBar from "../../../common/BlueProgressBar";
import BlueScrollBackToTopButton from "../../../common/BlueScrollBackToTopButton";

const Browse = () => {

    const [userSearchInput, setUserSearchInput] = useState('');
    const [lastSearchInput, setLastSearchInput] = useState(null);
    const [searchButtonIsGray, setSearchButtonIsGray] = useState(false);
    const [templatePriceList, setTemplatePriceList] = useState(false);
    const [templates, setTemplates] = useState([]);
    const [hasMore, setHasMore] = useState(true);
    const [isLoading, setIsLoading] = useState(null);
    const [statusMessage, setStatusMessage] = useState('');
    const [buttonIsHidden, setButtonIsHidden] = useState(false);
    const [metaData, setMetaData] = useState(null);
    const [isBackgroundBlur, setIsBackgroundBlur] = useState('')
    const [userSettings, setUserSettings] = useState(null);
    const [selectedTemplatesMap, setSelectedTemplatesMap] = useState({});

    const markerRef = useRef(null);

    const onSearchInput = async (e) => {
        if (e) {
            setUserSearchInput(e.target.value)
        }
        if (!e || e.target.value === '') {
            if (lastSearchInput !== '') {
                setTemplates([])
            }
        }
    }
    
    const loadUserSettings = async () => {
        let settings = await memeService.getBroadcasterSettings();
        setUserSettings(settings);
        return;
    };

    //get selected memes
    const getSelectedTemplates = async () => {
        setSelectedTemplatesMap({});
        let templateAccumulationMap = {};
        let paginationToken = null;
        try {
    
            do {
            let responseObject = await memeService.listSelectedTemplates(paginationToken);
    
            responseObject.templates.forEach((template) => {
                return templateAccumulationMap[template.thumbnailUrl] = template;
            });
    
            setSelectedTemplatesMap(templateAccumulationMap);
            paginationToken = responseObject.paginationToken;
            } while (paginationToken);
            return selectedTemplatesMap;
        } catch (e) {
            notificationService.setMessage('I could not load your selected memes!')
            setSelectedTemplatesMap({});
        }
    
    };

    //add template to selection
    const addSelectedTemplate = async () => {
        setButtonIsHidden(true);
        setStatusMessage('Adding Meme');
        let templateToAdd = await memeService.addSelectedTemplates(metaData.newPrice ? metaData.newPrice : '0', metaData.templateId, metaData.title);
     
        if (templateToAdd.id) {

            setStatusMessage('Meme Added!');
            let currentMap = selectedTemplatesMap
            currentMap[metaData.thumbnailUrl] = metaData
            setSelectedTemplatesMap(currentMap);

        } else if (templateToAdd.message) {
            notificationService.setMessage('Max limit reached !');
            setStatusMessage('');
            setButtonIsHidden(false);
        } else {
            notificationService.setMessage('Adding Error!');
            setStatusMessage('');
            setButtonIsHidden(false);
        }
    };

    let content = <></>

    //renders the image with metaData and selection button
    if (metaData) {
        content = <ImageSelectorRouter 
                        metaData={metaData} 
                        setMetaData={setMetaData} 
                        addSelectedTemplate={addSelectedTemplate}
                        statusMessage={statusMessage}
                        buttonIsHidden={buttonIsHidden}
                        selectedTemplatesMap={selectedTemplatesMap}
                        templatePriceList={templatePriceList}
                    />
        
        //blur out background
        if (isBackgroundBlur !== 'ImageSelectorSearchLayerContainerBlur' ) {
            setIsBackgroundBlur('ImageSelectorSearchLayerContainerBlur')
        }
    } else {
        content = <></>
        //disable blur
        if (isBackgroundBlur) {
            setIsBackgroundBlur('');
        }
        if (statusMessage) {
            setStatusMessage('');
            setButtonIsHidden(false);
        }
    }
    
    return(
        <div className="scrollableContainer">
        {content}
        <BlueScrollBackToTopButton />
        <Container isNoBackground={true} noAnimation={true} content={
            <>
                

                <div>
                    <BlueProgressBar
                                title='Selected memes'
                                max={userSettings ? userSettings.storageLimit: 20}
                                selected={selectedTemplatesMap ? Object.getOwnPropertyNames(selectedTemplatesMap).length : 0}
                            />
                    <br/>
                    <br/>
                    <div className={isBackgroundBlur}>
                        <BrowseTemplates
                            userSearchInput={userSearchInput}
                            setUserSearchInput={setUserSearchInput}
                            lastSearchInput={lastSearchInput}
                            setLastSearchInput={setLastSearchInput}
                            searchButtonIsGray={searchButtonIsGray}
                            setSearchButtonIsGray={setSearchButtonIsGray}
                            templates={templates}
                            setTemplates={setTemplates}
                            setTemplatePriceList={setTemplatePriceList}
                            templatePriceList={templatePriceList}
                            hasMore={hasMore}
                            setHasMore={setHasMore}
                            isLoading={isLoading}
                            setIsLoading={setIsLoading}
                            setMetaData={setMetaData}
                            markerRef={markerRef}
                            onSearchInput={onSearchInput}
                            loadUserSettings={loadUserSettings}
                            getSelectedTemplates={getSelectedTemplates}
                            selectedTemplatesMap={selectedTemplatesMap}
                        />
                    </div>
                </div>
                
            </>
    }/>
    </div>)
}

export default Browse;