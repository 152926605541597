import './BlueScrollWindowPrompt.css';
import BlueButton from '../BlueButton/BlueButton.js';
import { useState, useEffect } from 'react';
import updateDisplayService from '../../../services/UpdateDisplayService';

const BlueScrollWindowPrompt = () => {

    const [clasState, setClassState] = useState('blueScrollWindowPromptDisplayNone');

    const [content, setContent] = useState(null); 
    const [title, setTitle] = useState(null);
    const [onCancel, setOnCancel] = useState(null);
    const [onAccept, setOnAccept] = useState(null);

    useEffect(() => {
        updateDisplayService._registerSetMessageFunction((newMessage) => {
            setContent(newMessage);
        });
        updateDisplayService._registerSetTitleFunction((newTitle) => {
            setTitle(newTitle);
        });
        updateDisplayService._registerSetOnClickFunction((newOnClickFunction) => {
            if(newOnClickFunction) setOnCancel(() => () => {newOnClickFunction()});
            else setOnCancel(null)
        });
        updateDisplayService._registerSetOnClickFunction2((newOnClickFunction) => {
            if (newOnClickFunction) setOnAccept(() => () => {newOnClickFunction()});
            else setOnAccept(null)
            
        });
    }, [])


    let cancelFun = () => setClassState('blueScrollWindowPromptDisplayNone');
    let acceptFun = () => setClassState('blueScrollWindowPromptDisplayNone');
    
    if (onCancel) cancelFun = () => {
        onCancel()
        setClassState('blueScrollWindowPromptDisplayNone')
    }

    if (onAccept) acceptFun = () => {
        onAccept()
        setClassState('blueScrollWindowPromptDisplayNone')
    }
    
    if (content && clasState !== 'blueScrollWindowPrompt') setClassState('blueScrollWindowPrompt');
    
    let displayButtons = <>
        <BlueButton 
            text='Accept' 
            isDark={false} 
            onClick={acceptFun} 
            isLarge={true}
        />
        <BlueButton 
            text='Cancel' 
            isDark={true} 
            onClick={cancelFun} 
            isLarge={true}
        />
    </>

    if (!onAccept) displayButtons = <BlueButton 
        text='Close' 
        isDark={false} 
        onClick={cancelFun} 
        isLarge={true}
    />

    return(
        <div className={clasState}>
            <div className='blueScrollWindowPromptbackground'></div>
            <div className='blueScrollWindowPromptContainer'>
                <h1 className='blueScrollWindowPromptTitle'>{title}</h1>
                <div className='blueScrollWindowPromptContent'>
                    <p className='blueScrollWindowPromptText'>{content}</p>
                </div>
                <div className='blueScrollWindowPromptbuttons'>
                    {displayButtons}
                </div>
            </div>
        </div>
    )
}

export default BlueScrollWindowPrompt;