import './memeApprovalCss.css';
import BlueToggle from '../../../common/BlueToggle';
import BlueButton from '../../../common/BlueButton/BlueButton';
import { useState } from 'react';

const ManualApprovalDisplayLogicSettings = ({
    manualApprovalState,
    approvalToggleLogic, 
    currentMeme,
    remotelyApprovedby,
    onButtonClick,
    isApiCallInProgress,
}) => {
    
    const [displayText, setDisplayText] = useState(false);

    let displayComponent = <></>

    const currentDisplayMemeUrl = currentMeme ? currentMeme.url : ''
    const currentDisplayMemeId = currentMeme ? currentMeme.id : 'none'
    const currentDisplayPrice = currentMeme ? currentMeme.price ? currentMeme.price + " Bits" : "" : ""
    const currentDisplayTopText = currentMeme ? currentMeme.topText ? currentMeme.topText : "" : ""
    const currentDisplayBottomText = currentMeme ? currentMeme.bottomText ? currentMeme.bottomText : "" : ""
    const currentDisplayName = currentMeme ? currentMeme.displayName ? currentMeme.displayName.toUpperCase() : "" : ""

    const toggleDisplay = <>
        <BlueToggle title="Manual Approval" onToggle={approvalToggleLogic} state={manualApprovalState}/>
        <hr className='manualApprovalLine'></hr>
    </>

    const toggleTextDisplay =  <div className='manualApprovalToggleForDisplayText'>
        <BlueToggle title={"Show text"} onToggle={()=> setDisplayText(!displayText)} state={displayText}/>
    </div>
    
    const imageDisplayMode = <div className="manualApprovalContainerImage">
        <img
            className='loadingDisplayMemeApproval'
            src={currentDisplayMemeUrl}
            alt={currentDisplayMemeId}
            onError={(e) => {
                e.target.onerror = null;
                e.target.src = '/imageNotFound.jpg';
                }
            }
        />
        
        <p className='manualApprovalDisplayName'>{currentDisplayName}</p>
        
    </div>

    const textDisplayMode = <div className='manualApprovalContainerText'>
        <p>{currentDisplayPrice} </p>
        <hr className='manualApprovalLine'></hr>
        <p>{currentDisplayTopText}</p>
        <p>{currentDisplayBottomText}</p>
        <hr className='manualApprovalLine'></hr>
        <h4>{currentDisplayName}</h4>
    
    </div>

    const approveDenyButtonDisplay = <>
        <div className='manualApprovalButtonContainer'>
            <BlueButton text='✓' onClick={() => onButtonClick('APPROVE')} isCenter={true} />
            <BlueButton text='X' onClick={() => onButtonClick('DENY')} isCenter={true} isDark={true} />
        </div>
    </>

    const messageDisplayForNoMeme = (text) => {
        return <>
            {toggleDisplay}
            <div className="manualApprovalContainerNoImage">
                <p className='manualApprovalTextDisplay'>{text}</p>
            </div>
        </>
    }

    // Display when meme is present 
    displayComponent = <>
        {toggleDisplay}
        
        {toggleTextDisplay}

        {displayText ? textDisplayMode : imageDisplayMode}
      
        {approveDenyButtonDisplay}
    </>

    // Display when no meme in queue 
    if (!currentMeme) displayComponent = messageDisplayForNoMeme("No memes currently awaiting approval");

    // Display when toggled off
    if (!manualApprovalState)  displayComponent = messageDisplayForNoMeme("Manual approval means that you get to check and decide whether you want a meme to be shown on your stream or not. When someone sends a meme through the extension you will be notified and can review it here before it is shown on stream. You can approve it if you like it or reject it if you don't. It's a way for you to have more control over the content on your stream.");

    if (isApiCallInProgress) displayComponent = messageDisplayForNoMeme("Processing meme...");

    if (remotelyApprovedby && !isApiCallInProgress) {
        const action = remotelyApprovedby.action ? remotelyApprovedby.action.toLowerCase() : 'confused';
        displayComponent = messageDisplayForNoMeme(`Meme got ${action} by another moderator`);
    }

    return displayComponent
}

export default ManualApprovalDisplayLogicSettings;