const Source = ({memeObject}) => {
  //console.log('MemeObject')
  //console.log(JSON.stringify(memeObject))
  if (memeObject) {
    return (
      <div className='sourceImageAndNameContainer'>
        <img className='sourceImage' src={memeObject.url} alt={memeObject.url}/>
        <div className='sourceImageDisplayNameContainer'>
          <p className='sourceImageDisplayName'>{memeObject.displayName ? memeObject.displayName : 'Unkown'}</p>
        </div>
      </div>
    )

  } else {
    return (<></>);
  }
}

export default Source;