import './BlueToggle.css';

const BlueToggle = ({
    title,
    onToggle,
    state = false
}) => {

    const onClickLogic = (e)=> {
        onToggle(e.target.checked ? true : false)
        state = (e.target.checked)
    }

    let displayComponent = <></>

    displayComponent = <div className='BlueToggleContainer'>
    <h3 className="BlueToggleTitle">{title}</h3>
        <div className="BlueToggleContainerToggle">
            <label className="BlueToggle">
                <input type="checkbox" checked={state} onChange={(e)=> onClickLogic(e)}/>
                <span className="BlueToggleSlider"></span>
            </label>
        </div>
    </div>
    return displayComponent
}

export default BlueToggle;