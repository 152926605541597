import './InputBarSmall.css'

const InputBarSmall = ({
    value,
    placeholder,
    onInput,
    defaultValue,
    isGray
}) => {
    let inputClass = 'InputBarBlueSmall';

    if (isGray) inputClass = 'InputBarBlueSmallIsGray';

    return (
        <>
            <input
                className={inputClass}
                type='text'
                value={value}
                placeholder={placeholder}
                onInput={onInput}
                defaultValue={defaultValue}
            />
        </>
    )
}

export default InputBarSmall;