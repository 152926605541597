import globalConstants from '../globals/GlobalConstants';
import errorHandler from './ErrorHandler';
import Cookies from 'universal-cookie';
const cookies = new Cookies();


class AdminService {
  constructor() {
    this.baseURL = globalConstants.backendBaseUrl;
  }

  checkAdminState() {
    return this.sendRequest('POST',
      '/broadcaster/admin/checkAdminState'
    ).then((response) => {
      return errorHandler.handleFetchWithoutParsing(response);
    });
  }

  getAllReportedImages(pageToken) {
    return this.sendRequest('POST',
      '/broadcaster/admin/getAllReportedImages',
      { body: JSON.stringify({ paginationToken: pageToken }) }
    ).then((response) => {
      return errorHandler.handleFetch(response);
    });
  }

  deleteReportedUploadedImages(state, templateId) {
    return this.sendRequest('POST',
      '/broadcaster/admin/deleteReportedImages',
      { body: JSON.stringify({ state: state, templateId:templateId }) }
    ).then((response) => {
      return errorHandler.handleFetchWithoutParsing(response);
    });
  }

  // Send the reques twith proper header.
  // This will be updated to handle POST requests eventually.
  sendRequest(method, route, data) {
    let headers = {
        'Content-Type': 'application/json;charset=UTF-8'
      };

    let jwt = cookies.get('jwt_token');

    if (jwt) {
      headers['Authorization'] = jwt;
    }

    if (data) {

      headers = {
        ...headers,
        ...data.headers,
      };
    }

    return fetch(`${this.baseURL}${route}`, {
      method: method,
      mode: 'cors',
      cache: 'default',
      headers: headers,
      body: data ? data.body : null
    });
  }
}

const singleton = new AdminService();
export default singleton;
