import BlueButton from "../../common/BlueButton/BlueButton";
import Container from "../../common/Container";

const TestDisplayElement = ({
    isButtonDisabled,
    checkStatus,
    callBroadcasterSettings,
    callProfinityFilters,
    callBrowserSource,
    callSearchAndAddAndSelectTemplates,
    callUploadedTemplates,
    callListPendingApprovalMemes,
    consoleOutput,
    setConsoleOutput,
    testAllTests,
    callSetupProgress,
    callMostRecentMemems,
    callGetAllReportedImages,
    callModeratorFunctions
}) => {

    let content =  <div className="scrollableContainer">
        <h1 className='testTitle'>TEST SECTION</h1>
        <Container content={
            <>
                <h2 className='testTitle'>Console</h2>
                <div className="testSectionContainerConsoleDisplay" id="testSectionContainerConsoleDisplay">
                    <p className="infoTextTestContent">{consoleOutput}</p>
                </div>
                <p>{checkStatus}</p>
                <BlueButton text='Clear console' isDark={true} isGray={isButtonDisabled} onClick={()=> setConsoleOutput('Console cleared ...')}/>
            </>
        } />
       <Container content={
        <>
            <h2 className='testTitle'>API CALLS</h2>
            <div className="buttonContainerConsoleDisplayTest">
                <BlueButton text='broadcasterSettings()' isGray={isButtonDisabled} onClick={callBroadcasterSettings}/>
                <BlueButton text='profinityFilters()' isGray={isButtonDisabled} onClick={callProfinityFilters}/>
                <BlueButton text='browserSource()' isGray={isButtonDisabled} onClick={callBrowserSource}/>
                <BlueButton text='searchAndAddAndSelectTemplates()' isGray={isButtonDisabled} onClick={callSearchAndAddAndSelectTemplates}/>
                <BlueButton text='uploadedTemplates()' isGray={isButtonDisabled} onClick={callUploadedTemplates}/>
                <BlueButton text='callListPendingApprovalMemes()' isGray={isButtonDisabled} onClick={callListPendingApprovalMemes}/>
                <BlueButton text='callSetupProgress()' isGray={isButtonDisabled} onClick={callSetupProgress}/>
                <BlueButton text='callMostRecentMemems()' isGray={isButtonDisabled} onClick={callMostRecentMemems}/>
                <BlueButton text='callGetAllReportedImages()' isGray={isButtonDisabled} onClick={callGetAllReportedImages}/>
                <BlueButton text='callModeratorFunctions()' isGray={isButtonDisabled} onClick={callModeratorFunctions}/>
                <BlueButton text='Test all' isGray={isButtonDisabled} onClick={testAllTests}/>
            </div>
        </>
       } />
    </div>

    return content
}

export default TestDisplayElement;