import memeService from '../../../../services/MemeService';
import errorHandler from '../../../../services/ErrorHandler';
import { useState, useEffect } from 'react';
import FilterManagement from './FilterManagement';
import LoadingDisplay from '../../../common/LoadingDisplay/LoadingDisplay';
import notificationService from '../../../../services/NotificationService';
import BlueScrollBackToTopButton from "../../../common/BlueScrollBackToTopButton";
import './FilterContent.css';

const FilterContent = () => {

  const [loading, setLoading] = useState(true);
  const [filters, setFilters] = useState([]);
  const [newFilterInput, setNewFilterInput] = useState('');
  const [createFilterMessage, setcreateFilterMessage] = useState('');
  const [filterAddButtonClass, setFilterAddButtonClass] = useState(true);
  const [isMounted, setIsMounted] = useState(false);

  //makes button visibal on input 
  if(newFilterInput.length > 0 && filterAddButtonClass !== false) {
    setFilterAddButtonClass(false)
  }

  useEffect(() => {
    setIsMounted(true);
    if (isMounted) {
      
      let filterAccumulationArray = []
      let paginationToken = null;
      let loadAllFilters = async () => {
        try {
          do {
            let result = await memeService.listAllProfanityFilters(paginationToken);
            filterAccumulationArray.push(...result.filters);
            setFilters(filterAccumulationArray);
            paginationToken = result.paginationToken;
          } while (paginationToken)
          setLoading(false);
        } catch (e) {
          setLoading(false);
          notificationService.setMessage('Error loading filters!');
        }
      }
      loadAllFilters();
    }

   //fixing memory leak
   return () => setIsMounted(false)
  }, [isMounted]);

  const createFilter = async () => {
    if(newFilterInput) {
      setcreateFilterMessage('Adding Filter ...');
      let filter = await memeService.createProfanityFilter(newFilterInput);
      if (filter.id && filter.filterText) {
        setcreateFilterMessage('New filter added!');
        setFilters([filter, ...filters]);
        setNewFilterInput('');
        setFilterAddButtonClass(true);
        setTimeout(() => setcreateFilterMessage(''), 2000);
      } else {
        notificationService.setMessage(errorHandler.handleErrorCode(filter));
      }
    }

  };

  const handelKeyPress = (event) => {
    if (event.key === 'Enter'){
      createFilter()
    }
  }

  if (loading) {

    return ( <LoadingDisplay /> );

  } else {
    
    return (
      <div className="scrollableContainer">
          <BlueScrollBackToTopButton buttonShowThreshold={1000} left={true}/>
          <FilterManagement
          filters={filters}
          createFilter={createFilter}
          setNewFilterInput={setNewFilterInput}
          loading={loading}
          createFilterMessage={createFilterMessage}
          filterAddButtonClass={filterAddButtonClass}
          handelKeyPress={handelKeyPress}
          newFilterInput={newFilterInput}
        />
      </div>
    );
  }

};

export default FilterContent;
