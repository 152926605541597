import './InputBar.css'

const InputBar = ({
    placeholder,
    value,
    onInput,
    state,
    onKeyPress
}) => {

    let inputClass = 'InputBarBlue';

    if (state === 'update') inputClass = 'InputBarBlueNoUpdate';
    if (state === 'error') inputClass = 'InputBarBlueError';
    if (state === 'succeded') inputClass = 'InputBarBlueSucceeded';
    if (state === 'colorSwitch') inputClass ='InputBarColorSwitch';

    return (
        <>
            <input
                className={inputClass}
                type='text'
                value={value}
                placeholder={placeholder}
                onInput={onInput}
                onKeyPress={onKeyPress}
            />
        </>
    )
}

export default InputBar;