import globalConstants from '../globals/GlobalConstants';
import ReactGA from 'react-ga4';
import Cookies from 'universal-cookie';

class GoogleService {
    constructor () {
        this.googleToken = globalConstants.googleToken;
        this.newLogin = true;
        this.username = false;
    }

    initialize() {
        const search = window.location.search;
        const params = new URLSearchParams(search);
        const twitchAuthCode = params.get('code');

        // Tries to set username when false on each page
        if (!this.username) {
            let cookies = new Cookies();
            this.username = cookies.get('twitch_user') ? cookies.get('twitch_user') : false
        }

        // sets and sends username on login
        if (this.newLogin && !twitchAuthCode && this.username) {
            this.set({ user: this.username })
            this.newLogin = false;
        }

        // only available on prod
        if (this.googleToken) {

            ReactGA.initialize([
                { 
                    trackingId: this.googleToken,
                    gaOptions: { user: this.username ? this.username : 'UNKOWN' }
                },
            ])

            // we don't want to pass the twitch token along 
            if (!twitchAuthCode) {
                ReactGA.send({ 
                    hitType: "pageview",
                    page: window.location.pathname + window.location.search 
                });
            }
            
        } 
    }

    set(obj) {
        if (this.googleToken) ReactGA.set(obj);
    }

    event({category, action, label}) {
        
        if (this.googleToken) {
            ReactGA.event({
                category: category,
                action: action,
                label: label
            })
        } 
    }
}

const singleton = new GoogleService();
export default singleton;