import globalConstants from '../globals/GlobalConstants';
import Cookies from 'universal-cookie';
import errorHandler from './ErrorHandler';
const cookies = new Cookies();

class MemeService {
  constructor() {
    this.baseURL = globalConstants.backendBaseUrl;
    this.twitchAuthCode = null;
  }

  //creates profanity filter from the backend
  createProfanityFilter(filterText) {
    return this.sendRequest('POST',
      '/broadcaster/createProfanityFilter',
      { body: JSON.stringify({ filterText: filterText }) }
    ).then((response) => {
      return errorHandler.handleFetch(response);
    });
  }

  //lists all profanity fitlers from the backend
  listAllProfanityFilters(pageToken) {
    return this.sendRequest('POST',
      '/broadcaster/listAllProfanityFilters',
      { body: JSON.stringify({ paginationToken: pageToken }) }
    ).then((response) => {
      return errorHandler.handleFetch(response);
    });
  }

  //updates a profanity fitlers from the backend
  updateProfanityFilter(id, filterText) {
    return this.sendRequest('POST',
      '/broadcaster/updateProfanityFilter',
      { body: JSON.stringify({ id: id, filterText: filterText }) }
    ).then((response) => {
      return errorHandler.handleFetch(response);
    });
  }

  //gets the broadcaster settings
  getBroadcasterSettings() {
    return this.sendRequest('POST', '/broadcaster/getBroadcasterSettings')
    .then((response) => {
      return errorHandler.handleFetch(response);
    });
  }

  //updates the broadcaster settings
  updateBroadcasterSettings(
    browserSourceInterval,
    browserSourceNotifySound,
    broadcasterThemeStyle,
    browserSourceTimeOut
    ) {
    return this.sendRequest('POST', '/broadcaster/updateBroadcasterSettings',
      { body: JSON.stringify({
          browserSourceInterval: browserSourceInterval,
          browserSourceNotifySound: browserSourceNotifySound,
          broadcasterThemeStyle: broadcasterThemeStyle,
          browserSourceTimeOut: browserSourceTimeOut
        })
      }
    ).then((response) => {
      return errorHandler.handleFetch(response);
    });
  }

  //updates the broadcaster settings manualApproval option
  updateBroadcasterSettingsManualApproval( manualApproval ) {
    return this.sendRequest('POST', '/broadcaster/updateBroadcasterSettings',
      { body: JSON.stringify({ manualApproval : manualApproval }) }
    ).then((response) => {
      return errorHandler.handleFetch(response);
    });
  }

  // lists all pending memes that need approval
  listPendingApprovalMemes(pageToken) {
    return this.sendRequest('POST',
      '/broadcaster/listPendingApprovalMemes',
      { body: JSON.stringify({ paginationToken: pageToken }) }
    ).then((response) => {
      return errorHandler.handleFetch(response);
    });
  }

  //deletes a profanity fitlers from the backend
  deleteProfanityFilter(id) {
    return this.sendRequest('POST',
      '/broadcaster/deleteProfanityFilter',
      { body: JSON.stringify({ id: id }) }
    ).then((response) => {
      return errorHandler.handleFetchWithoutParsing(response);
    });
  }

  //creates browser source in the backend
  createBrowserSource() {
    return this.sendRequest('POST', '/broadcaster/createBrowserSource')
    .then((response) => {
      return errorHandler.handleFetch(response);
    });
  }

  //gets browser source from backend
  getBrowserSource() {
    return this.sendRequest('POST', '/broadcaster/getBrowserSource')
    .then((response) => {
      return errorHandler.handleFetch(response);
    });
  }

  //creates a test meme
  createTestMeme(topText, bottomText, templateId) {
    return this.sendRequest('POST', '/broadcaster/createTestMeme',
      { body: JSON.stringify({ topText, bottomText, templateId }) }
    )
    .then((response) => {
      return errorHandler.handleFetch(response);
    });
  }

  //transistions meme from preview to viewed on the backend
  memeDisplayed(id) {
    return this.sendRequest('POST',
      '/browserSource/memeDisplayed',
      { body: JSON.stringify({ id: id }) }
    ).then((response) => {
      return response;
    });
  }

  getMemeToDisplay(uuid) {
    return this.sendRequest('POST',
      '/browserSource/getMemeToDisplay',
      { body: JSON.stringify({uniqueId: uuid})})
    .then((response) => {
      // We don't use the error handler here
      // Because we want to swallow any errors that occur
      // For now.
      return response;
    })
  }

  getSourceSettings(uuid) {
    return this.sendRequest('POST',
      '/browserSource/getSourceSettings',
      { body: JSON.stringify({uniqueId: uuid})})
    .then((response) => {
      // We don't use the error handler here
      // Because we want to swallow any errors that occur
      // For now.
      return response;
    })
  }

  searchImageLibrary(searchText, startIndex) {
    // We will add pagination later...
    return this.sendRequest('POST',
      '/broadcaster/searchImageLibrary',
      { body: JSON.stringify({searchText: searchText, startIndex: startIndex})})
      .then((response) => {
        return errorHandler.handleFetch(response);
      })
  }

  //lists all selected templates from backend
  listSelectedTemplates(pageToken) {
    return this.sendRequest('POST',
      '/broadcaster/listSelectedTemplates',
      { body: JSON.stringify({ paginationToken: pageToken }) }
    ).then((response) => {
      return errorHandler.handleFetch(response);
    });
  }

  //adds a template to the selected template
  addSelectedTemplates(price, templateId, name) {
    return this.sendRequest('POST',
      '/broadcaster/addSelectedTemplate',
      { body: JSON.stringify({ price: price, templateId: templateId, name: name }) }
    ).then((response) => {
      return errorHandler.handleFetch(response);
    });
  }

  //deletes a selected template from the backend
  deleteSelectedTemplate(id) {
    return this.sendRequest('POST',
      '/broadcaster/deleteSelectedTemplate',
      { body: JSON.stringify({ id: id }) }
    ).then((response) => {
      return errorHandler.handleFetchWithoutParsing(response);
    });
  }

  //updates a selected template from the backend
  updateSelectedTemplate(id, price, name) {
    return this.sendRequest('POST',
      '/broadcaster/updateSelectedTemplate',
      {
        body: JSON.stringify({
          id: id,
          price: price,
          name: name,
        }),
    }).then((response) => {
      return errorHandler.handleFetchWithoutParsing(response);
    });
  }

  //uploads a new template to the backend
  uploadTemplate(title, publicTemplate, encodedImage) {
    return this.sendRequest('POST',
      '/broadcaster/uploadTemplate',
      {
        body: JSON.stringify({
          title: title,
          publicTemplate: publicTemplate,
          encodedImage: encodedImage,
        }),
    }).then((response) => {
      return errorHandler.handleFetch(response);
    });
  }

  //lists all uploaded templates from backend
  listUploadedTemplates(pageToken) {
    return this.sendRequest('POST',
      '/broadcaster/listUploadedTemplates',
      { body: JSON.stringify({ paginationToken: pageToken }) }
    ).then((response) => {
      return errorHandler.handleFetch(response);
    });
  }

  //deletes a uploaded template from the backend
  deleteUploadedTemplate(id) {
    return this.sendRequest('POST',
      '/broadcaster/deleteUploadedTemplate',
      { body: JSON.stringify({ id: id }) }
    ).then((response) => {
      return errorHandler.handleFetchWithoutParsing(response);
    });
  }

  createOrUpdateReportImage(templateId, reason, creator, url) {
    return this.sendRequest('POST',
      '/broadcaster/createOrUpdateReportImage',
      { body: JSON.stringify({
         templateId: templateId,
         reason: reason,
         creator: creator,
         url: url,
        }) }
    ).then((response) => {
      return errorHandler.handleFetchWithoutParsing(response);
    });
  }

  // This is used to create a new moderator
  createModerator(moderatorUserName, approveDenyPermission) {
    const objectToSend = {
      moderatorUserName: moderatorUserName,
      approveDenyPermission: approveDenyPermission,
    }

    return this.sendRequest('POST', '/broadcaster/createModerator',
      { body: JSON.stringify(objectToSend) }
    ).then((response) => errorHandler.handleFetch(response));
  }

  // This is used to update a moderator
  updateModerator(id, approveDenyPermission) {
    const objectToSend = {
      id: id,
      approveDenyPermission: approveDenyPermission,
    }

    return this.sendRequest('POST', '/broadcaster/updateModerator',
      { body: JSON.stringify(objectToSend) }
    ).then((response) => errorHandler.handleFetch(response));
  }

   // This is used to delete a moderator
   deleteModerator(id) {
    const objectToSend = {
      id: id,
    }

    return this.sendRequest('POST', '/broadcaster/deleteModerator',
      { body: JSON.stringify(objectToSend) }
    ).then((response) => errorHandler.handleFetchWithoutParsing(response));
  }

  // This is used to list all channels the broadcaster is a moderator of
  listAllModeratorChannels(paginationToken) {
    const objectToSend = {
      paginationToken: paginationToken,
    }

    return this.sendRequest('POST', '/broadcaster/listAllModeratorChannels',
      { body: JSON.stringify(objectToSend) }
    ).then((response) => errorHandler.handleFetch(response));
  }

  // This is used to list all moderators for this broadcaster
  listAllBroadcasterModerators(paginationToken) {
    const objectToSend = {
      paginationToken: paginationToken,
    }

    return this.sendRequest('POST', '/broadcaster/listAllBroadcasterModerators',
      { body: JSON.stringify(objectToSend) }
    ).then((response) => errorHandler.handleFetch(response));
  }

  approveOrDenyMeme(state, memeId) {
    return this.sendRequest('POST',
      '/broadcaster/approveDenyMeme',
      { body: JSON.stringify({
         id: memeId,
         action: state, // DENY || APPROVE
        }) }
    ).then((response) => {
      return errorHandler.handleFetchWithoutParsing(response);
    });
  }

  isBrowserSourceActive() {
    return this.sendRequest('GET', '/broadcaster/isBrowserSourceActive'
    ).then((response) => {
      return errorHandler.handleFetch(response);
    });
  }


  getSetupProgress() {
    return this.sendRequest('GET', '/broadcaster/getSetupProgress'
    ).then((response) => {
      return errorHandler.handleFetch(response);
    });
  }

  //lists most recent memes
  listMostRecentMemes(pageToken) {
    return this.sendRequest('POST',
      '/broadcaster/listMostRecentMemes',
      { body: JSON.stringify({ paginationToken: pageToken }) }
    ).then((response) => {
      return errorHandler.handleFetch(response);
    });
  }

  // delete a recent meme
  deleteMostRecentMeme(id) {
    return this.sendRequest('POST',
      '/broadcaster/deleteMostRecentMeme',
      { body: JSON.stringify({ id: id }) }
    ).then((response) => {
      return errorHandler.handleFetchWithoutParsing(response);
    });
  }

  // Send the reques twith proper header.
  // This will be updated to handle POST requests eventually.
  sendRequest(method, route, data) {
    let headers = {
        'Content-Type': 'application/json;charset=UTF-8'
      };

    let jwt = cookies.get('jwt_token');

    if (jwt) {
      headers['Authorization'] = jwt;
    }

    if (data) {
      headers = {
        ...headers,
        ...data.headers,
      };
    }

    return fetch(`${this.baseURL}${route}`, {
      method: method,
      mode: 'cors',
      cache: 'default',
      headers: headers,
      body: data ? data.body : null
    }).catch((error) => {
      console.error('sendRequest : ' + error);
      return ({ status: 504 });
    });
  }

  //calling backend with header and passing user code
  logIn(twitchAuthCode) {
    return this.sendRequest('GET', '/authorization/logIn', { headers: { 'Twitch-Auth-Code': twitchAuthCode } }).then((response) => {

      if (response.status === 403) return ({ status: 403 });

      //set cookies
      try {
        response.headers.get('cookies').split('; ').forEach(cookiePair => {
          document.cookie = cookiePair;
        });
      } catch (e) {
        return ({ status: 403 });
      }

      return ({ status: 200 });
    });
  }
}

const singleton = new MemeService();
export default singleton;
