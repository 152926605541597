
const ImageLoader = ({ 
    loadedImages,
    setMetaData,
    selectedTemplatesMap
}) => {

    let arr = [];
    if (loadedImages.length) {
        loadedImages.forEach((item, i) => {
            item.index = i;

            let imageClass = 'ImageLoaderImageContainer'

            if (selectedTemplatesMap[item.thumbnailUrl]) imageClass = 'ImageLoaderImageContainerSelected';

            arr.push((
                <div className={imageClass} key={i}>
                    <img
                        className='ImageLoaderImageDisplayBrowse'
                        src={item.thumbnailUrl}
                        alt={item.title}
                        onLoad = {() => {
                            imageClass = 'ImageLoaderImageContainerDisplayNone'
                        }}
                        onClick={() => setMetaData(item)}
                        onError={(e) => {
                            e.target.onerror = null;
                            e.target.src = '/imageNotFound.jpg';
                            }
                        }
                    />
                </div>
            ))
            
        });
    }
    return (<div className='ImageLoaderImageContainerContainer'>{arr}</div>);
}

export default ImageLoader;