class GlobalConstants {
  constructor() {
    this.state = process.env.REACT_APP_ENV.trim();
    this.clientId = 'vry90hirenzsmkptzdqlb152l7n5mk'
    this.localHostRedirect = 'http://localhost:8080/loggedIn'
    this.localHostBackend = 'http://localhost:3000'

    this.constants = {
      prod: {
        clientId: this.clientId,
        backendBaseUrl: 'https://api.streammemes.gg',
        oauthRedirectUrl: 'https://www.streammemes.gg/loggedIn',
        webSocketUrl: 'wss://ws.streammemes.gg',
        googleToken:'G-4YJWDN6PG7'
      },
      dev: {
        clientId: this.clientId,
        backendBaseUrl: 'https://beta.api.streammemes.gg',
        webSocketUrl: 'wss://beta.ws.streammemes.gg',
        oauthRedirectUrl: 'https://development.d16mzxcsab00jc.amplifyapp.com/loggedIn',
        googleToken:'G-8SL9B5FR51'
      },
      ajLocal: {
        clientId: this.clientId,
        backendBaseUrl: this.localHostBackend,
        webSocketUrl: 'wss://3st6pvoto8.execute-api.us-east-1.amazonaws.com/ajdev',
        oauthRedirectUrl: this.localHostRedirect
      },
      jessyLocal: {
        clientId: this.clientId,
        backendBaseUrl: this.localHostBackend,
        webSocketUrl: 'wss://nukzpa1g52.execute-api.us-east-1.amazonaws.com/jessydev',
        oauthRedirectUrl: this.localHostRedirect
      },
      localDev: {
        clientId: this.clientId,
        backendBaseUrl: 'https://beta.api.streammemes.gg',
        webSocketUrl: 'wss://beta.ws.streammemes.gg',
        oauthRedirectUrl: this.localHostRedirect,
      },
      ajAlphaDev: {
        clientId: this.clientId,
        backendBaseUrl: 'https://kcg50vxile.execute-api.us-east-1.amazonaws.com/Prod',
        webSocketUrl: 'wss://3st6pvoto8.execute-api.us-east-1.amazonaws.com/ajdev',
        oauthRedirectUrl: this.localHostRedirect,
      },
      jessyAlphaDev: {
        clientId: this.clientId,
        backendBaseUrl: 'https://n5na6aabdj.execute-api.us-east-1.amazonaws.com/Prod',
        webSocketUrl: 'wss://nukzpa1g52.execute-api.us-east-1.amazonaws.com/jessydev',
        oauthRedirectUrl: this.localHostRedirect,
      }
    };
  }

  getGlobalConstants() {
    return this.constants[this.state];
  }
}
const singleton = new GlobalConstants();
export default singleton.getGlobalConstants();
