
import { useState } from "react";
import ReportImageFrame from "./ReportImageFrame";
import ImageSelectorFrame from "./ImageSelectorFrame";
import Container from "../../../../common/Container";

const ImageSelectorRouter = ({
    metaData,
    setMetaData,
    addSelectedTemplate,
    statusMessage,
    buttonIsHidden,
    selectedTemplatesMap,
    templatePriceList
}) => {
    const [isReporting, setIsReporting] = useState(false);
    const [isReportedButtonOff, setIsReportedButtonOff] = useState(false);
    const [reasonValue, setReasonValue] = useState('Inappropriate');

    let content = <ImageSelectorFrame 
        setMetaData={setMetaData}
        setIsReporting={setIsReporting}
        metaData={metaData}
        addSelectedTemplate={addSelectedTemplate}
        buttonIsHidden={buttonIsHidden}
        statusMessage={statusMessage}
        selectedTemplatesMap={selectedTemplatesMap}
        templatePriceList={templatePriceList}
    />
    
    if (isReporting) {
        content = <ReportImageFrame 
            setMetaData={setMetaData}
            setIsReporting={setIsReporting}
            metaData={metaData}
            setReasonValue={setReasonValue}
            setIsReportedButtonOff={setIsReportedButtonOff}
            isReportedButtonOff={isReportedButtonOff}
            reasonValue={reasonValue}
        />
          
    }

    let displayComponent = <>
        <div className='ImageSelectorOverLayeContainer'>
            <div className="ImageSelectorAnimationContainer" id="ImageSelectorAnimationContainer">
                <Container content={content} maxWidth='20em' width='20em'/>
            </div>
        </div>
    </>

    return displayComponent
}

export default ImageSelectorRouter;