import './BlueSlider.css';

const BlueSlider = ({
    id,
    name,
    min,
    max,
    text,
    defaultValue,
    onChange,
    step
}) => {
    return (
        <>
            <input className='BlueSlider' 
            type="range" id={id} 
            name={name} 
            min={min} max={max} 
            defaultValue={defaultValue} 
            onChange={onChange}
            step={step}
            />
            <label className='BlueSliderLabel' htmlFor={id}>{text}</label>
        </>
    )
}

export default BlueSlider;