import HomePage from '../Homepage/HomePage';
import LoggedIn from '../LoggedIn';
import Admin from '../Admin';
import Source from '../Source';
import Dashboard from '../Dashboard';
import DashboardHome from '../Dashboard/Home';
import DashboardHomeContent from '../Dashboard/Home/HomeContent'
import Templates from '../Dashboard/Templates';
import Filters from '../Dashboard/Filters';
import FiltersFilterContent from '../Dashboard/Filters/FilterContent';
import Settings from '../Dashboard/Settings';
import SettingsContent from '../Dashboard/Settings/SettingsContent';
import SettingsBrowserSourceContent from '../Dashboard/Settings/BrowserSourceContent';
import Browse from '../Dashboard/Templates/Browse';
import Selected from '../Dashboard/Templates/Selected';
import Help from '../Dashboard/Help';
import Upload from '../Dashboard/Templates/Upload';
import HelpContent from '../Dashboard/Help/HelpContent';
import ReportedImages from '../Admin/reportedImages';
import AdminDashboard from '../Admin/AdminDashboard';
import Moderation from '../Dashboard/Moderation';
import ModeratorList from '../Dashboard/Moderation/ModeratorList';

import Tests from '../Admin/Tests';

// Our route config is just an array of logical "routes"
// with `path` and `component` props, ordered the same
// way you'd do inside a `<Switch>`.
// Titles will be used in menu bars.

const routes = [
  {
    path: "/loggedIn",
    component: LoggedIn
  },
  {
    path: "/admin",
    component: Admin,
    routes: [
      {
        title: "Reports",
        path: "/admin/reports",
        component: ReportedImages
      },
      {
        title: "Tests",
        path: "/admin/tests",
        component: Tests
      },
      {
        title: "Dashboard",
        path: "/admin",
        component: AdminDashboard
      },
    ]
  },
  {
    path: "/source",
    component: Source
  },
  {
    path: "/dashboard",
    component: Dashboard,
    routes: [
      {
        title: "Meme Template Library",
        path: "/dashboard/templates",
        component: Templates,
        routes: [
          {
            title: "Selected",
            path: "/dashboard/templates/selected",
            component: Selected
          },
          {
            title: "Upload",
            path: "/dashboard/templates/upload",
            component: Upload
          },
          {
            title: "Templates",
            path: "/dashboard/templates",
            component: Browse
          },
        ]
      },
      {
        title: "Filters",
        path: "/dashboard/filters",
        component: Filters,
        routes: [
          {
            title: "",
            path: "/dashboard/filters",
            component: FiltersFilterContent
          }
        ]
      },
      {
        title: "Moderation",
        path: "/dashboard/moderation",
        component: Moderation,
        routes: [
          {
            title: "",
            path: "/dashboard/moderation",
            component: ModeratorList
          }
        ]
      },
      {
        title: "Settings",
        path: "/dashboard/settings",
        component: Settings,
        routes: [
          {
            title: "Browser Source",
            path: "/dashboard/settings/browsersource",
            component: SettingsBrowserSourceContent
          },
          {
            title: "General Settings",
            path: "/dashboard/settings",
            component: SettingsContent
          },
        ]
      },
      {
        title: "Help",
        path: "/dashboard/help",
        component: Help,
        routes: [
          {
            title: "",
            path: "/dashboard/help",
            component: HelpContent //() => { return (<p>General Help</p>) } 
          },
        ]
      },
      {
        title: "Dashboard",
        path: "/dashboard",
        component: DashboardHome,
        routes: [
          {
            title: "",
            path: "/dashboard",
            component: DashboardHomeContent
          }
        ]
      },
    ]
  },
  {
    path: "/",
    component: HomePage
  },
];

export default routes;
