const LoadingDisplay = ({invert, globalDivOff}) => {
    return (
        <div className={globalDivOff ? '': 'globalContentDiv'}>
          <img
          className='loadingDisplay'
          src={invert ? '/loading2.gif' : '/loading.gif'}
          alt='loading'
          />
        </div>
      );
}

export default LoadingDisplay;