import Cookies from 'universal-cookie';

class GlobalAppContext {
    constructor() {
        this.cookies = new Cookies();
        this.allowedUsersForNewContentList = { 
            jellymie: true, 
            streammemesgg: true, 
            suicidebeef: true,
            exception: 'qa_moderation' 
        };
    }

    isAllowedToViewNewContent() {
        const isException = this.cookies.get('twitch_user').includes(this.allowedUsersForNewContentList['exception'])
        const isAllowed = this.allowedUsersForNewContentList[this.cookies.get('twitch_user')] 

        return isAllowed || isException ? true : false;
    }
  
}

const singleton = new GlobalAppContext();
export default singleton;
  