import { useEffect, useState } from 'react';
import globalConstants from '../globals/GlobalConstants';
import useWebSocket from 'react-use-websocket';
import Cookies from 'universal-cookie';
const cookies = new Cookies();

export const useWebsocketService = ({
  queryStringParams, // 'broadcasterManagementConnection=true' for example
  messageHandler
}) => {
  const [keepAliveInterval, setKeepAliveInterval] = useState(null);
  const [hadSuccesfulConnection, setHadSuccesfulConnection] = useState(false)
  const [reconnectionAttemptsFirstConnection, setReconnectionAttemptsFirstConnection] = useState(0);
  const [retryOnErrorForWebSocket, setRetryOnErrorForWebSocket] = useState(true)
  let jwt_token = cookies.get('jwt_token');

  const onShouldReconnect = (closeEvent) => {
    const firstConnectionRetryThreshold = 5;

    // Tries to establish a first connection for about 1 min
    if (!hadSuccesfulConnection && reconnectionAttemptsFirstConnection <= firstConnectionRetryThreshold) {
      setReconnectionAttemptsFirstConnection(reconnectionAttemptsFirstConnection + 1);
      console.warn(`Attempting to connect ${reconnectionAttemptsFirstConnection}/${firstConnectionRetryThreshold}`);
    }

    // Will kill reconnection
    if (!hadSuccesfulConnection && reconnectionAttemptsFirstConnection >= firstConnectionRetryThreshold ) {
      setRetryOnErrorForWebSocket(false);
      clearInterval(keepAliveInterval);
      console.error(`Browser source could not establish a connection!`);

      return false
    }

    return true
  };

  const onOpenConnection = () => {
    console.log('connected');
    setHadSuccesfulConnection(true);
    setReconnectionAttemptsFirstConnection(0);
    setRetryOnErrorForWebSocket(true);
  };

  const webSocketOptions = {
    onOpen: onOpenConnection,
    onMessage: messageHandler,
    onError: console.error,
    onClose: console.warn,
    onReconnectStop: console.warn,
    //Will attempt to reconnect on all close events
    shouldReconnect: onShouldReconnect,
    retryOnError: retryOnErrorForWebSocket,
    // retries to connect every 10 seconds for up to 2 days
    // if the first connection was successful
    reconnectInterval: 10000,
    reconnectAttempts: 17280
  }

  let websocketUrl = `${globalConstants.webSocketUrl}?${queryStringParams}`
  if (jwt_token) {
    websocketUrl += `&jwt=${jwt_token}`
  }

  const { sendMessage } = useWebSocket(websocketUrl, webSocketOptions);

  useEffect(() => {
    if (!keepAliveInterval) {
      const interval = setInterval(() => sendMessage({message: "keep-alive"}), 60000 * 9);
      setKeepAliveInterval(interval);
    }

    return () => {
      clearInterval(keepAliveInterval);
    };
  }, [sendMessage, keepAliveInterval]);
  
  return { sendMessage } 
}

const websocketService = { useWebsocketService }
export default websocketService;