import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router';
import adminService from '../../services/AdminService';
import TopMenu from '../Dashboard/Menus/TopMenu';
import RouteWithSubRoutes from '../Routes/RouteWithSubRoutes';
import { Switch } from 'react-router-dom';
import BlueButton from '../common/BlueButton/BlueButton';

const Admin = ({routes, path}) => {

  const history = useHistory();

  const [isLoading, setIsLoading] = useState(true);

  const checkAdminState = async() => {
   let response = await adminService.checkAdminState()
   .catch(e => history.push('/dashboard'));
    
   if (response.status === 200) {
      setIsLoading(false);
    } else {
      history.push('/dashboard');
    }
  };

  useEffect(() => {
    setIsLoading(true);
    checkAdminState();
  },[]) // eslint-disable-line react-hooks/exhaustive-deps

  let displayComponent = (<></>);
 
  if (!isLoading) {
    displayComponent = (
    <>
        <div className='returnButtonContainerAdmin'><BlueButton text='return to user dashboard' isDark={true} onClick={() => history.push('/dashboard')}/></div>
        <TopMenu
        routes={routes}
        title='Administrator'
        baseRoute={path}
        />
        
        <Switch>
            {routes.map((route, i) => (
            <RouteWithSubRoutes key={i} {...route} />
            ))}
        </Switch>
    </>
   );
  }
  
  return displayComponent;
}

export default Admin;
