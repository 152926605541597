import BlueProgressBar from "../../../common/BlueProgressBar";
import ImageCardDisplayElement from './BuildingBlocks/ImageCardDisplayElement';
import ImageUploaderCardDisplay from './BuildingBlocks/ImageUploaderCardDisplay';
import './UploadMainContent.css'

const UploadMainContent = ({
    uploadedTemplates,
    userSettings,
    elementDeletedCount,
    setNameInput,
    nameInput,
    isButtonUploadGray,
    uploadTemplate,
    removeUploadedTemplateQuestion,
    isButtonGray,
    isPublicUpload,
    setIsPublicUpload,
    selectUploadedTemplate,
    convertFile,
    uploadMessage,
    uploadFileData,
    selectedTemplatesMap,
    removeSetImage
}) => {
    let displayUploadProgressBar = <></>

    if (uploadedTemplates.length >= 80) {
        displayUploadProgressBar = <>
            <BlueProgressBar 
                selected={uploadedTemplates.length - elementDeletedCount} 
                max={userSettings ? userSettings.uploadLimit : 1} 
                title='Uploaded Memes'
            />
            <br/> 
        </>
    }
    
    return <> 
            <ImageUploaderCardDisplay 
                convertFile={convertFile}
                nameInput={nameInput}
                isButtonUploadGray={isButtonUploadGray}
                uploadTemplate={uploadTemplate}
                setNameInput={setNameInput}
                uploadMessage={uploadMessage}
                uploadFileData={uploadFileData}
                removeSetImage={removeSetImage}
                isPublicUpload={isPublicUpload}
                setIsPublicUpload={setIsPublicUpload}
            />
            <br/>
            <br/>
            {displayUploadProgressBar}
            <BlueProgressBar 
                selected={Object.getOwnPropertyNames(selectedTemplatesMap).length} 
                max={userSettings ? userSettings.storageLimit : 1} 
                title='Selected Memes'
            />
            <br/>
            <br/>
            <ImageCardDisplayElement 
             uploadedTemplates={uploadedTemplates}
             removeUploadedTemplateQuestion={removeUploadedTemplateQuestion}
             isButtonGray={isButtonGray}
             selectUploadedTemplate={selectUploadedTemplate}
             selectedTemplatesMap={selectedTemplatesMap}
            />
        </>
}

export default UploadMainContent;