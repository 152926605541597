import './PriceSelector.css'
const PriceSelector = ({
    onChange,
    isGray,
    priceList,
    isValid,
    defaultValue
}) => {
   
    let priceSelectorClass = 'PriceSelector'
    let priceDisplay = <></>;
    
    if (priceList && priceList.length > 1) {
        let priceComponent = [];

        priceList.forEach(element => {
            let elementToPush = <option key={element} value={element}>Bits: {element} Bits</option>
    
            priceComponent.push(elementToPush)
        });

        priceDisplay = priceComponent;
    }
    else {
        priceDisplay = <option value='0'>Price : 0 Bits</option>
        isGray = true;

        if (!isValid) priceDisplay = <option value='0'>Invalid price</option>
    }

    if (isGray) priceSelectorClass = 'PriceSelectorClassIsGray'

    return(
        <>
            <select className={priceSelectorClass} name="price" defaultValue={defaultValue} onChange={onChange}>
                {priceDisplay}
            </select>
        </>
    )
}

export default PriceSelector;