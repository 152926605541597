import { Route } from 'react-router-dom';
// A special wrapper for <Route> that knows how to
// handle "sub"-routes by passing them in a `routes`
// prop to the component it renders.
function RouteWithSubRoutes(route) {
  return (
    <Route
      path={route.path}
      render={props => {
        // pass the sub-routes down to keep nesting
        return (
          <route.component {...props} title={route.title} routes={route.routes} />
        )
      }}
    />
  );
}

export default RouteWithSubRoutes;