import memeService from '../../../../services/MemeService';
import CardContainer from '../../../common/CardContainer';
import notificationService from '../../../../services/NotificationService';
import BlueButton from '../../../common/BlueButton/BlueButton';
import PriceSelector from '../../../common/PriceSelector';
import InputBarSmall from '../../../common/InputBar/small';

const SelectManager = ({
    selectedTemplates,
    updateTemplateState,
    deleteTemplateState
}) => {

    //remove selected templates
    const removeSelectedTemplate = async (item) => {
        item.loading = true;
        item.message = 'Removing ...'
        updateTemplateState(item);
        await memeService.deleteSelectedTemplate(item.id).catch(()=> {
            notificationService.setMessage('Removing failed!');
            item.loading = false;
            updateTemplateState(item);
        });
        deleteTemplateState(item)
    };

    const removeSelectedTemplateQuestion = (item) => {
        notificationService.setMessage(`Are you sure you want to remove ${item.name ? item.name : item.title} ?`)
        notificationService.setTitle('Delete')
        notificationService.onClick(() => {
            //reset the window
            notificationService.clearAll()
            removeSelectedTemplate(item)
        })
    }
    
    //update selected templates
    const updateSelectedTemplate = async (item) => {
        let price = item.newPrice ? item.newPrice : item.price;
        let name = item.newName ? item.newName : item.name;
        let updateFailed = false;
        if (!isNaN(price) && name && name.length <= 50) {
            item.loading = true;
            item.message = 'Updating ...';
            updateTemplateState(item)
            await memeService.updateSelectedTemplate(item.id, price, name).catch(() => {
                notificationService.setMessage('Update failed!');
                updateFailed = true;
            });
        } else {
            notificationService.setMessage('Invalid Name or Bits');
            updateFailed = true;
        }
        if (!updateFailed) {
            item.message = 'Successfully Updated!';
            item.price = price;
            item.name = name;
            item.newPrice = null;
            item.newName = null;

        }
        item.loading = false;
        item.changed = updateFailed; // If update failed, we still allow update button to be shown
        updateTemplateState(item);
        
    };
       
    //loop through each elemnt
    let arr = [];
    
    if (selectedTemplates.length) {
        selectedTemplates.forEach((item) => {
        
        let contentLeft =  <>
            <p>{`Uploader : ${item.uploader}`}</p>
            <p>Bits: {item.price ? item.price : '0'} </p>  
            {item.isPriceValid ? <p></p> : <p>Invalid price</p>}
        </>

        let contentRight = <>
            <p>{item.message ? item.message : "Name & Bits"}</p>
            <PriceSelector defaultValue={item.price ? item.price : '0'} 
                onChange={e =>  {
                    item.newPrice = e.target.value;
                    item.changed = true;
                    item.message = "Name & Bits";
                    updateTemplateState(item)
                }}
                priceList={item.priceMap}
                isValid={item.isPriceValid}
            />
            <br/>
            <br/>
            <InputBarSmall 
                placeholder={`Name: ${item.name ? item.name : item.title}`}
                onInput={e => {
                    item.newName = e.target.value;
                    item.changed = true;
                    item.message = "Name & Bits";
                    updateTemplateState(item)
                }}
            />
            <br/>
            <br/>
            <BlueButton text='update' 
                onClick={(e) => updateSelectedTemplate(item)} 
                Name={item.id}
                isGray={!item.changed}
            />   
        </>

        let content = <CardContainer 
            item={item}
            setLoading={item.loading}
            updateItem={updateSelectedTemplate}
            removeItem={removeSelectedTemplateQuestion}
            isButtonGray={false}
            contentLeft={contentLeft}
            contentRight={contentRight}
        />
            
            //here we sort out the deleted elements when they where deleted
            if(item.isHidden) content = <></>

            arr.push((<div key={item.id}>{content}</div>))        
        });
    }
    return (<>{arr}</>);
}

export default SelectManager;