import memeService from '../../../services/MemeService';
import notificationService from '../../../services/NotificationService';
import ManualApprovalDisplayLogicDashboard from './dashboard/manualApprovalDisplayLogicDashboard.js';
import ManualApprovalDisplayLogicSettings from './settings/manualApprovalDisplayLogicSettings.js';

const ManualApprovalLogic = ({
    manualApprovalState,
    setManualApprovalState,
    deleteMemesThatNeedApproval,
    currentMeme,
    browserSourceIsActive,
    remotelyApprovedby,
    applicationDisplayLocation,
    setIsApiCallInProgress,
    isApiCallInProgress,
    isBlueToggleClickable,
    setIsBlueToggleClickable 
}) => {

    // display user message on error and log error
    const userErrorReponseMessage = (funcName, error) => {
        notificationService.setMessage("Sorry! Something went wrong while approving or denying your meme!");
        console.error(funcName, error)
    }

    // deletes meme from array and calls backend to approve or deny a meme
    const denyApproveMemeButtonLogic = async (state, meme) => {
        const isValidState = (state === 'DENY' || state === 'APPROVE')
        
        // validate input
        if (!isValidState) return console.error('denyApproveMemeButtonLogic : no state set!')
        if (!meme) userErrorReponseMessage('denyApproveMemeButtonLogic : no meme set!', meme)
        
        try {

            if (meme.id) {
                if (state === 'DENY') await memeService.approveOrDenyMeme('DENY', meme.id)
                if (state === 'APPROVE') await memeService.approveOrDenyMeme('APPROVE', meme.id)
                
                // Remove meme from list
                deleteMemesThatNeedApproval(meme)
            } else userErrorReponseMessage('denyApproveMemeButtonLogic : no meme id', meme)

        } catch (error) {
            userErrorReponseMessage('denyApproveMemeButtonLogic CATCH : ', { error, state, meme})
        }
        
    }

    // disables or enables manual approval on settings
    const approvalToggleLogic = async (state) => {
        if (!isBlueToggleClickable) return;
       
        try {
            setIsBlueToggleClickable(false);
            setManualApprovalState(manualApprovalState ? false : true);
            
            if (state) await memeService.updateBroadcasterSettingsManualApproval(true)
            else await memeService.updateBroadcasterSettingsManualApproval(false)

            setIsBlueToggleClickable(true);
        } catch (error) {
            userErrorReponseMessage('approvalToggleLogic', error)
            setIsBlueToggleClickable(true);
        }
        
    }

    // Button logic that is passing along the current meme
    const onButtonClick =  async (state) => {
        if (!currentMeme) {
            console.error('approveAndDenyButton', state, currentMeme)
            notificationService.setMessage("There was no meme! :O ");
        }
        
        setIsApiCallInProgress(true);
        
        if (state === 'APPROVE') await denyApproveMemeButtonLogic('APPROVE', currentMeme)
        else await denyApproveMemeButtonLogic('DENY', currentMeme)
        
        setIsApiCallInProgress(false);
    }

    // Component switch based on page
    let displayComponent = <ManualApprovalDisplayLogicDashboard 
        manualApprovalState={manualApprovalState}
        denyApproveMemeButtonLogic={denyApproveMemeButtonLogic}
        currentMeme={currentMeme}
        browserSourceIsActive={browserSourceIsActive} 
        remotelyApprovedby={remotelyApprovedby}
        onButtonClick={onButtonClick} 
        isApiCallInProgress={isApiCallInProgress}
    />

    if (applicationDisplayLocation === "settings") {
        displayComponent = <ManualApprovalDisplayLogicSettings 
            manualApprovalState={manualApprovalState}
            denyApproveMemeButtonLogic={denyApproveMemeButtonLogic}
            currentMeme={currentMeme}
            browserSourceIsActive={browserSourceIsActive} 
            remotelyApprovedby={remotelyApprovedby} 
            onButtonClick={onButtonClick} 
            isApiCallInProgress={isApiCallInProgress}
            approvalToggleLogic={approvalToggleLogic}
        />
    }

    return displayComponent
    
}

export default ManualApprovalLogic;