const SmallSideBar = ({
    menuToggle,
    sideContainer,
    menuToggleOnClickMobile,
    history,
    location,
    isAllowedToViewNewContent,
}) => {

  // adjust this once available for all
  const displayComponentModerator =isAllowedToViewNewContent ? <>
    <li className={location.pathname.includes('/dashboard/moderation') ? 'iconSizeMenuActive' : ''}>
      <img src='/swordMod.svg' alt="moderation"
        onClick={(e) => { menuToggleOnClickMobile(e); history.push('/dashboard/moderation') }}
        className='iconSizeMenu'
      />
    </li>
    <br/>
  </>: <></>

    return (<>
    <div className="buttonContainerSidbar">
      <div className="menuButton" onClick={menuToggle}>
        <img src='/settingsIcon.svg' alt="settings" className='sideBarSettingsIcon' />
      </div>
    </div>
    <div style={sideContainer} className='sideContainerParentDesktopSmall'>
      <ul className="sideNavbarImages">
        
        <div className="sideNavbarTextContainerSmall">
            <li className={location.pathname.endsWith('/dashboard') ? 'iconSizeMenuActive' : ''}>
              <img src='/apps.svg' alt="apps"
                onClick={(e) => { menuToggleOnClickMobile(e); history.push('/dashboard') }}
                className='iconSizeMenu'
              />
            </li>
            <br/>
            <li className={location.pathname.includes('/dashboard/templates') ? 'iconSizeMenuActive' : ''}>
              <img src='/book-alt.svg' alt="book"
                  onClick={(e) => { menuToggleOnClickMobile(e); history.push('/dashboard/templates') }}
                  className='iconSizeMenu'
                />
            </li>
            <br/>
            <li className={location.pathname.includes('/dashboard/filters') ? 'iconSizeMenuActive' : ''}>
            <img src='/document.svg' alt="filter"
                  onClick={(e) => { menuToggleOnClickMobile(e); history.push('/dashboard/filters') }}
                  className='iconSizeMenu'
                />
            </li>
            <br/>
            {displayComponentModerator}
            <li className={location.pathname.includes('/dashboard/settings') ? 'iconSizeMenuActive' : ''}>
              <img src='/flower.svg' alt="book"
                    onClick={(e) => { menuToggleOnClickMobile(e); history.push('/dashboard/settings') }}
                    className='iconSizeMenu'
                  />
            </li>
            <br/>
            <li className={location.pathname.includes('/dashboard/help') ? 'iconSizeMenuActive' : ''}>
              <img src='/interrogation.svg' alt="help"
                    onClick={(e) => { menuToggleOnClickMobile(e); history.push('/dashboard/help') }}
                    className='iconSizeMenu'
                  />
            </li>
            <br/>
            <li className={location.pathname.includes('/dashboard/loggout') ? 'iconSizeMenuActive' : ''}>
              <img alt='logout' src='/logoutButton.png' id='logoutButtonSideMenu'
                    onClick={() => history.push('/')}
                    className='iconSizeMenu'
                  />
            </li>
            
          </div>
        </ul>
    </div>
  </>)
}

export default SmallSideBar;