import FilterHeader from './FilterHeader';
import FilterListItems from './FilterListItems';
import { useState } from 'react';
import InputContainer from '../../../common/InputContainer';
import Container from '../../../common/Container';
import BlueToggle from '../../../common/BlueToggle'

const FilterManagement = ({
  filters,
  createFilter,
  setNewFilterInput,
  loading,
  createFilterMessage,
  filterAddButtonClass,
  handelKeyPress,
  newFilterInput
}) => {

  const [numberOfDeletedItems, setNumberOfDeletedItems] = useState(0);
  const [toggleBlurState, setToggleBlurState] = useState(false);
  const [inputContainerFilterClass, setInputContainerFilterClass] = useState("inputContainerFilterBlur")
  
  const onFilterToggleClick = () => {
    setToggleBlurState(!toggleBlurState)
    if (inputContainerFilterClass === 'inputContainerFilterBlur') setInputContainerFilterClass('inputContainerFilterShow')
    else if (inputContainerFilterClass === 'inputContainerFilterShow') setInputContainerFilterClass('inputContainerFilterBlur')
  }

  let content = <>
    <>
      <FilterHeader />
      {loading ? <p>Loading your profanity filters...</p> : ""}
    </>
    <div className='filterContainerToggleBlur'>
      <BlueToggle  title="Show filters" onToggle={onFilterToggleClick} state={toggleBlurState}/>
    </div>
    
    <p className='filterInfoNumberDisplay'>Active Filters [ {filters.length - numberOfDeletedItems} ]</p>
    
    <div>
      <InputContainer 
        onInput={e => setNewFilterInput(e.target.value)}
        placeholder='Create a Filter!'
        buttonText='Add'
        buttonIsGray={filterAddButtonClass} 
        buttonOnClick={createFilter}
        messageText={createFilterMessage}
        arrayObject={filters}
        content={
          <FilterListItems 
            setNumberOfDeletedItems={setNumberOfDeletedItems}
            numberOfDeletedItems={numberOfDeletedItems}
            filters={filters}
            inputContainerFilterClass={inputContainerFilterClass}
          />   
        }
        onKeyPress={handelKeyPress}
        value={newFilterInput}
      />
    </div>
    
   </>

  return (<Container content={content} isNoBackground={true} noAnimation ={true}/>)
};

export default FilterManagement;
