const EulaText = `

END USER LICENSE AGREEMENT (EULA)

This End User License Agreement ("EULA") is a legal agreement between you ("User") and the owner of the "Stream Memes" extension ("Owner") for the use of the "Stream Memes" extension, which includes computer software, associated media, printed materials, and "online" or electronic documentation.
By installing, accessing, or using the "Stream Memes" extension, User agrees to be bound by the terms of this EULA. If User does not agree to the terms of this EULA, User may not install, access, or use the "Stream Memes" extension.


License Grant

The Owner grants User a personal, non-transferable, non-exclusive license to install, access, and use the "Stream Memes" extension for the purpose of creating and sharing memes on the Twitch.tv platform. User may not use the "Stream Memes" extension for any other purpose without the prior written consent of the Owner. The license granted in this section is subject to all the terms and conditions of this EULA.


Restrictions

User shall not, and shall not permit any third party to:  
  Reverse engineer, decompile, disassemble, or create derivative works based on the "Stream Memes" extension or any part thereof. This restriction is in place to protect the proprietary information and technology of the Owner and to prevent unauthorized access or use of the extension.
  Sell, license, sublicense, distribute, or otherwise transfer the "Stream Memes" extension to any third party. This restriction is in place to ensure that the User does not profit from the extension without the express consent of the Owner.
  Use the "Stream Memes" extension for any illegal or unauthorized purpose. This restriction is in place to ensure that the User does not use the extension in a manner that is illegal or that violates the rights of others.
  Use the "Stream Memes" extension in any manner that could damage, disable, overburden, or impair the Owner's servers or networks. This restriction is in place to protect the Owner's infrastructure and to ensure that the extension can be used by all users without interruption or degradation in performance.
  Use the "Stream Memes" extension in any manner that is in violation of any third-party terms of service or license agreements. This restriction is in place to ensure that the User does not use the extension in a manner that violates the terms of service or license agreements of any third parties.
  Use the "Stream Memes" extension to create or share content that is offensive, racist, sexist, pornographic, or otherwise inappropriate. This restriction is in place to ensure that the User does not use the extension to create or share content that is offensive, harmful, or that violates the rights of others.


Intellectual Property

The "Stream Memes" extension, including but not limited to the software, images, text, and documentation, is protected by copyright, trademark, and other intellectual property laws. User acknowledges that the Owner owns all rights, title, and interest in and to the "Stream Memes" extension, including all intellectual property rights. User shall not remove, alter, or obscure any proprietary notices (including copyright and trademark notices) on the "Stream Memes" extension. User shall not use the "Stream Memes" extension to create or share content that infringes on the intellectual property rights of any third party. This section is in place to protect the proprietary information and technology of the Owner and to ensure that the User does not use the extension in a manner that violates the rights of others.
User Representations and Warranties
User represents and warrants that:
  User is the rightful owner of any and all images uploaded to the "Stream Memes" extension or has obtained all necessary permissions and licenses to use such images. This representation and warranty is in place to ensure that the User does not use the extension to upload or share content that they do not have the right to use.
  User has the right to make such images available to other users of the "Stream Memes" extension.
  User will comply with all applicable laws and regulations in connection with the use of the “Stream Memes” extension.
  User will not upload or share any content that contains malware, viruses, or other harmful software. This representation and warranty is in place to protect the Owner's infrastructure and to ensure that the extension can be used by all users without interruption or degradation in performance.
  User will not use the "Stream Memes" extension to engage in any fraudulent or illegal activity. 
  User will not use the "Stream Memes" extension to impersonate any person or entity or falsely state or otherwise misrepresent User's affiliation with a person or entity. 


Indemnification

User shall indemnify and hold the Owner harmless from and against any and all claims, damages, and expenses (including reasonable attorneys' fees) that may arise from the User's use of the "Stream Memes" extension, including but not limited to any claims of infringement of intellectual property rights, defamation, invasion of privacy, or breach of any representation or warranty made by the User in this EULA. 


Termination

The Owner may terminate this EULA and the User's access to the "Stream Memes" extension at any time, with or without cause. Upon termination, User shall immediately cease all use of the "Stream Memes" extension and delete any copies of the "Stream Memes" extension in their possession. 


Dispute Resolution

Any dispute arising out of or in connection with this EULA shall be resolved through binding arbitration in accordance with the rules of the American Arbitration Association. The arbitration shall be conducted in the jurisdiction in which the Owner is located. 


Data Collection

The "Stream Memes" extension may collect certain data from the User, including but not limited to the User's IP address, usage data, and email address. The Owner may use this data to improve the "Stream Memes" extension, to provide customer support, and for other legitimate business purposes. The Owner shall not share or sell the User's data to any third party without the User's prior consent. The User has the right to access and control the data that the "Stream Memes" extension collects, and may request that the Owner delete such data at any time. 


Third-Party Services

The "Stream Memes" extension may use third-party services, such as hosting providers, to provide certain features and functionality. User acknowledges and agrees that the use of such third-party services is subject to the terms and conditions of those services, and that the User shall comply with such terms and conditions. This section is in place to ensure that the User is aware that the extension may use third-party services and that the User is responsible for complying with the terms and conditions of those services.


Updates

The Owner may update the "Stream Memes" extension from time to time to add new features, fix bugs, or improve performance.


No Legal Advice

User acknowledges that this EULA is not legal advice, and that the User should consult with a lawyer if they have any legal questions. 


Entire Agreement

This EULA constitutes the entire agreement between User and the Owner with respect to the "Stream Memes" extension and supersedes all prior or contemporaneous communications and proposals, whether oral or written. This EULA may be amended by the Owner from time to time by posting a new version of the EULA on the "Stream Memes" website. 


Limitation of Liability

The Owner shall not be liable to User or any third party for any indirect, incidental, consequential, special, or exemplary damages, including but not limited to damages for loss of bits, goodwill, use, data, or other intangible losses, arising out of or in connection with this EULA or the use or inability to use the "Stream Memes" extension, even if the Owner has been advised of the possibility of such damages.


Governing Law

This EULA and the rights and obligations of the parties hereunder shall be governed by and construed in accordance with the laws of the jurisdiction in which the Owner is located.
Severability


If any provision of this EULA is held to be invalid or unenforceable, such provision shall be struck and the remaining provisions shall be enforced.
By installing, accessing, or using the "Stream Memes" extension, User agrees to be bound by the terms of this EULA. If User does not agree to the terms of this EULA, User may not install, access, or use the "Stream Memes" extension.
`
// ATTENTION
// updating the eula means we also have to update the time stamp
const EulaTimeStamp = 1674072217573
const eula = {EulaText, EulaTimeStamp}
export default eula;