import memeService from '../../../../services/MemeService';
import './BrowserSourceContent.css';
import { useState, useEffect } from 'react';
import BlueButton from '../../../common/BlueButton/BlueButton';
import Container from '../../../common/Container';
import notificationService from '../../../../services/NotificationService';
import LoadingDisplay from '../../../common/LoadingDisplay/LoadingDisplay';

const BrowserSource = () => {
  const [fetchedData, setFetchedData] = useState('...');
  const [urlIdClass, setUrlIdClass] = useState('urlIDTextDisplayBlur')
  const [isButtonOff, setIsButtonOff] = useState(true);
  const [isMounted, setIsMounted] = useState(false);
  const [loading, setLoading] = useState(true);

  const getUrl = async () => {
    let browserSourceResponse = await memeService.getBrowserSource();

    if (browserSourceResponse.url) {
      setFetchedData(browserSourceResponse.url);
      setIsButtonOff(false);
    } else {
      let createdBS = await memeService.createBrowserSource();
      if (createdBS.url) {
        setFetchedData(createdBS.url);
        setIsButtonOff(false);
      } else {
        notificationService.setMessage("Something went wrong getting the browser source !");
      }
    }
  };

  const generateNewUrl = async() => {
    let createdBS = await memeService.createBrowserSource();
      if (createdBS.url) {
        setFetchedData(createdBS.url);
        notificationService.setTitle('Browser Source')
        notificationService.setMessage("New Browser Source URL has been created. Don't forget to copy and paste this URL into a browser source layer in OBS so memes can be shown on your stream.");
      } else {
        notificationService.setMessage("Something went wrong while getting the new browser source !");
      }
  };

  const generateNewUrlQuestion = () => {
    notificationService.setMessage(`Do you want to regenerate your browser source?`)
    notificationService.setTitle('Browser Source')

    //delete
    notificationService.setButtonText('DELETE')
    notificationService.onClick(async () => {
        //reset the window
        notificationService.clearAll()
        await generateNewUrl()
    })

     // download
    notificationService.setButtonText2('No')
    notificationService.onClick2(() => {
        //reset the window
        notificationService.clearAll()
    })
};
  const copyToClipboard = async() => {
    await navigator.clipboard.writeText(fetchedData);
    notificationService.setMessage(`Successfully copied to clipboard!`)
    notificationService.setTitle('Browser Source')
  };

  const changeUrlDisplay = () => {
    if(urlIdClass === 'urlIDTextDisplayBlur') setUrlIdClass('urlIDTextDisplay')
    else setUrlIdClass('urlIDTextDisplayBlur')
  };

  useEffect(()=> {
    setIsMounted(true)

    if(isMounted) {
      setLoading(true);
      getUrl().then(() => setLoading(false));
    }
    
    //fixing memory leak
    return () => setIsMounted(false)
  }, [isMounted]);
  
  let content1 = <div className='urlID'>
                  <p className="browserSourceDescription">To successfully configure stream memes, you must add a browser source layer to your streaming software with the proper URL. This will allow for memes to be displayed on your stream for your viewers to enjoy.</p>
                  <div className='browserSourceLinkAndButton'>
                    <BlueButton text={urlIdClass === 'urlIDTextDisplayBlur' ? 'Show' : 'Hide'} onClick={changeUrlDisplay} isDark={true} isGray={isButtonOff}/>
                    <p className={urlIdClass}>{fetchedData}</p>
                  </div>
                  <div className='CopyButtonBrowserSource'>
                    <BlueButton isLarge={true} text='Copy' onClick={copyToClipboard} isGray={isButtonOff}/>
                  </div>
                  <div className='warningContainerUrlId'>
                    <img className='warningSignImage' src='/warningSign.svg' alt='warning' />
                    <span className='warningSignText'> Browser Source URL contains sensitive information. Do not share it! </span >
                  </div>
                </div>
  
  let content2 = <>
    <p className="browserSourceDescription">You may wish to create a new browser source URL in the event it was accidentally exposed publicly. To do this click the 'Regenerate' button. If you do this, your old URL will stop working so be sure to remove the old browser source layer from OBS and create a new one with the new URL.</p>
    <div className='regenerateButtonContainer'>
      <BlueButton
        text={'REGENERATE URL'}
        onClick={generateNewUrlQuestion}
        isLarge={true}
        color='red'
      />
    </div>  
    <div className='warningContainerUrlId'>
      <img className='warningSignImage' src='/warningSign.svg' alt='warning' />
      <span className='warningSignText'>This will delete the browser source</span >
      <img className='warningSignImage' src='/warningSign.svg' alt='warning' />
    </div>
  </>

  let allContent = <LoadingDisplay />

  if (!loading) {
    allContent = <div className="scrollableContainer">
      <Container title={'Your OBS Browser Source URL'} content={content1}/>
      <Container title={'Delete existing Browser Source'} content={content2}/>
    </div>
  }
  return allContent;
};

export default BrowserSource;
