import ModeratorAddDisplay from './ModeratorAddDisplay';
import LoadModeratorDisplay from './LoadModeratorDisplay';
import LoadBroadcasterDisplay from './LoadBroadcasterDisplay';
import BlueToggle from '../../../../common/BlueToggle';
import './moderatorListDisplay.css'

const ModeratorListDisplay = ({
    isButtonDisabled,
    handelKeyPressAddingModerator,
    newModeratorInput,
    setNewModeratorInput,
    inputDisplayMessage,
    listOfAllModerators,
    listOfAllBroadcasters,
    addModerator,
    updateModerator,
    deleteModerator,
    manualApprovalState,
    approvalToggleLogic
}) => {

    return <div className='scrollableContainer'>
        <div className="globalContentDiv">
            <div className='moderatorListDisplayContainer'>

                <div className='moderatorListDisplayManualApprovalToggle'>
                    <BlueToggle title="Manual approval" onToggle={approvalToggleLogic} state={manualApprovalState}/>
                </div>

                <ModeratorAddDisplay 
                    handelKeyPressAddingModerator={handelKeyPressAddingModerator}
                    newModeratorInput={newModeratorInput}
                    setNewModeratorInput={setNewModeratorInput}
                    inputDisplayMessage={inputDisplayMessage}
                    isButtonDisabled={isButtonDisabled}
                    addModerator={addModerator}
                />

                <LoadModeratorDisplay 
                    listOfAllModerators={listOfAllModerators}
                    isButtonDisabled={isButtonDisabled}
                    addModerator={addModerator}
                    updateModerator={updateModerator}
                    deleteModerator={deleteModerator}
                    manualApprovalState={manualApprovalState}
                />
                
                <LoadBroadcasterDisplay listOfAllBroadcasters={listOfAllBroadcasters} />

            </div>
        </div>
    </div>
    
 }
 
 export default ModeratorListDisplay;