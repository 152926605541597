import BlueToggle from '../../../../../common/BlueToggle';
import React, { useState } from 'react';
import './moderatorCardDisplay.css'

const ModeratorCardDisplay = ({
    moderatorObject,
    updateModerator,
    deleteModerator,
    manualApprovalState
}) => {
    const [approveDenyPermissionStatus, setApproveDenyPremissionStatus] = useState(moderatorObject.approveDenyPermission)
    const [isLoadinApprovalState, setIsLoadingApprovalState] = useState(false);

    const onClickXButton = () => deleteModerator(moderatorObject.id);

    const onToggleApprovalState = async () => {
        if (!isLoadinApprovalState) {
            setIsLoadingApprovalState(true);

            // temporarily set the value that it should be for smooth user expericence
            setApproveDenyPremissionStatus(approveDenyPermissionStatus => !approveDenyPermissionStatus);

            const moderator = await updateModerator(moderatorObject, !approveDenyPermissionStatus);
            
            // set the actuall value once it completed loading
            setApproveDenyPremissionStatus(moderator.approveDenyPermission);
            setIsLoadingApprovalState(false);
        }
    }

    const moderatorImageUrl = moderatorObject.modImageURL ? moderatorObject.modImageURL : '/imageNotFound.jpg'
    const moderatorUsername = moderatorObject.modUsername ? moderatorObject.modUsername : 'Unkown'
    const manualApprovalStateClass = manualApprovalState ? 'moderatorCardDisplayContainerApproveDeny' : 'moderatorCardDisplayContainerApproveDenyDisabled'
    
    return <>
        <div className='moderatorCardDisplayContainer'>
            <div className='moderatorCardDisplayXButtonContainer'>
                <button className="moderatorCardDisplayXButton" onClick={onClickXButton} name='moderatorCardDisplayXButton'>X</button>
            </div>
            <div className='moderatorCardDisplayImageNameContainer'>
                <img src={moderatorImageUrl} className="moderatorCardDisplayImage" alt={moderatorImageUrl}/>
                <p className='moderatorCardDisplayName'>{moderatorUsername}</p>
            </div>
            
            <hr className='moderatorCardDisplayHr'></hr>
            <div className='moderatorCardDisplayPremissionsContainer'>
                <h2>Premissions</h2>
                <div className={manualApprovalStateClass}>
                    <BlueToggle title="Approve and deny memes" onToggle={onToggleApprovalState} state={approveDenyPermissionStatus}/>
                </div> 
            </div>
        </div>
      </>
    
 }
 
 export default ModeratorCardDisplay;