import './SettingsContent.css';
import memeService from '../../../../services/MemeService';
import { useEffect, useState } from 'react';
import notificationService from '../../../../services/NotificationService';
import pollingService from '../../../../services/PollingService';
import Cookies from 'universal-cookie';
import SettingsDisplay from './settingsDisplay';

const SettingsContent = () => {

  const cookies = new Cookies();
  const [loading, setLoading] = useState(true);
  const [isMounted, setIsMounted] = useState(false);

  const [browserSourceSettings, setBrowserSourceSettings] = useState({
    browserSourceInterval: 10000,
    browserSourceTimeOut: 60000,
    browserSourceNotifySound: 0,
    broadcasterThemeStyle: 0,
  })

  const [browserSourceIsActive, setBrowserSourceIsActive] = useState(true);
  
  const [manualApprovalState, setManualApprovalState] = useState(false);
  const [currentMeme, setCurrentMeme] = useState(null);
  const [memesThatNeedApproval, setMemesThatNeedApproval] = useState([])

  const isMonetized = cookies.get('isMonetized')

  pollingService.usePollingInterval(async () => {
    let result = await memeService.isBrowserSourceActive()
    setBrowserSourceIsActive(result.isBrowserSourceActive);
  }, 1000 * 30, isMounted);

  useEffect(()=> {
       // Get first meme of the array for meme approval display component
       // This will rerender the component every time there is a change on memesThatNeedApproval
       // passing along the first element of the array. 
       // This needs to be top level!
      setCurrentMeme(memesThatNeedApproval[0] ? memesThatNeedApproval[0] : null )
  }, [memesThatNeedApproval])

  useEffect(() => {

    setIsMounted(true)

    // Load user settings
    async function loadUserSettings () {
    
      let settings = await memeService.getBroadcasterSettings()
      
      try {

        setBrowserSourceSettings({
          browserSourceInterval: parseInt(settings.browserSourceInterval),
          browserSourceTimeOut: parseInt(settings.browserSourceTimeOut),
          browserSourceNotifySound: parseInt(settings.browserSourceNotifySound),
          broadcasterThemeStyle: parseInt(settings.broadcasterThemeStyle),
        })

        setManualApprovalState(settings.manualApproval)

      } catch {
        notificationService.setMessage("I can not load your settings!");
      }
    };

    // load pending memes that need approval
    async function loadListOfPendingApproval () {

      setMemesThatNeedApproval([])
      let templateAccumulationArray = [];
      let paginationToken = null;
  
      try {
   
        do {
          let responseObject = await memeService.listPendingApprovalMemes(paginationToken);
                  
          responseObject.memes.forEach((meme) => {
              return templateAccumulationArray.push(meme);
          });
         
          paginationToken = responseObject.paginationToken;
        } while (paginationToken);

        setMemesThatNeedApproval(templateAccumulationArray)
  
      } catch (error) {
        notificationService.setMessage("Error loading list of memes pending approval");
      }
    }

    async function loadPage () {
      await loadUserSettings()
      await loadListOfPendingApproval()
      setLoading(false)
    }

    if (isMounted) {
      setLoading(true);
      loadPage()
    }

    //fixing memory leak
    return () => setIsMounted(false)
  },[isMounted])

  return <SettingsDisplay
    browserSourceSettings={browserSourceSettings}
    setBrowserSourceSettings={setBrowserSourceSettings}
    browserSourceIsActive={browserSourceIsActive}
    manualApprovalState={manualApprovalState}
    currentMeme={currentMeme}
    setMemesThatNeedApproval={setMemesThatNeedApproval}
    memesThatNeedApproval={memesThatNeedApproval}
    setManualApprovalState={setManualApprovalState}
    isMonetized={isMonetized}
    loading={loading}
  />
};

export default SettingsContent;
