import LoadingDisplay from '../../../common/LoadingDisplay/LoadingDisplay';
import Container from '../../../common/Container';
import TestMemeContainer from '../../../common/TestMemeContainer';
import TimeDisplayComponent from './timeDisplayComponent/index';
import ManualApproval from '../../../Applications/memeApproval';
import BrowserSourceStateDisplayComponent from './browserSourceStateDisplayComponent/index';

const SettingsDisplay = ({ 
    browserSourceSettings,
    setBrowserSourceSettings,
    browserSourceIsActive,
    manualApprovalState,
    currentMeme,
    setMemesThatNeedApproval,
    memesThatNeedApproval,
    setManualApprovalState,
    isMonetized,
    loading
}) => {

    let content1 = <TestMemeContainer extended={true} />
    
    let content2 = <TimeDisplayComponent
        browserSourceSettings={browserSourceSettings}
        setBrowserSourceSettings={setBrowserSourceSettings}
    />

    let content3 = <BrowserSourceStateDisplayComponent browserSourceIsActive={browserSourceIsActive} />

    let content4 = <ManualApproval 
        manualApprovalState={manualApprovalState}  
        currentMeme={currentMeme}
        setMemesThatNeedApproval={setMemesThatNeedApproval}
        memesThatNeedApproval={memesThatNeedApproval}
        setManualApprovalState={setManualApprovalState}
        applicationDisplayLocation={"settings"}
    />

    let content5 = <p className='testMemeMessageText'>{isMonetized === 'true' ? 'Monetization status is Active' : 'Monetization status is inactive' }</p>
    let placeHolder = <p className='testMemeMessageText'>More Settings Coming Soon</p>

    let display = <div className="scrollableContainer">
        <Container content={
            <>
                <Container content={content1} dynamicContainer={true}/>
                <Container content={content2} dynamicContainer={true}/>
                <Container content={content3} dynamicContainer={true}/>
                <Container content={content4} dynamicContainer={true}/>
                <Container content={content5} dynamicContainer={true}/>
                <Container content={placeHolder} dynamicContainer={true}/>
            </>
        } dynamicContainerDiv={true} />
    </div>

    if (loading) return <LoadingDisplay />
    else return display;
}

export default SettingsDisplay;