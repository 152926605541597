import './Container.css';

const Container = ({
    content,
    title,
    width,
    maxWidth,
    height,
    isNoBackground,
    isInContainer,
    dynamicContainer,
    dynamicContainerDiv,
    noAnimation,
    animationLeft
}) => {
    let containerClass = 'ContainerDiv';
    let mainContainerClass = 'globalContentDiv'

    if (isNoBackground) containerClass = 'ContainerDivNoBackground';
    if (isInContainer && !isNoBackground) containerClass = 'ContainerDivIsInContainer';
    if (dynamicContainer) mainContainerClass = 'DynamicContainer'; 
    if (noAnimation) containerClass = 'ContainerDivNoBackgroundNoAnimation'
    if (animationLeft) containerClass = 'ContainerDivNoBackgroundAnimationLeft'

    // This is used to set the top level div for the dynamic grid
    if (dynamicContainerDiv) return <div className='DynamicContainerMain'> {content} </div>

    if (title) {
        return(
            <>  
                <div className={mainContainerClass}>
                    <div className={containerClass} style={{minWidth:width, minHeight:height, maxWidth:maxWidth}}>
                        <h4 className='ContainerDisplayTitle'>{title}</h4>
                        <hr className='ContainerHeadLine'/>
                            {content}
                    </div>
                </div>           
            </>
        )
    }

    if (!title) {
        return(
            <>           
                <div className={mainContainerClass}>
                    <div className={containerClass} style={{minWidth:width, minHeight:height, maxWidth:maxWidth}}>
                            {content}
                    </div>
                </div>   
            </>
        )
    }

    
}

export default Container;