
const BrowserSourceStateDisplayComponent = ({ 
    browserSourceIsActive
}) => {

    const display = <>
        <h3>Browser source state</h3>
        <p className="settingsDescription">This shows the current state of the browser source.</p>
        <h3 className={browserSourceIsActive ? 'ActiveTextDisplaySettingsContent' : 'testMemeMessageText'}>{browserSourceIsActive ? 'Active' : 'Inactive'}</h3>
        <p className="settingsDescription">You can find the browser source by clicking the "Browser Source" tab at the top of the window. Then, copy the provided link and paste it into the URL field</p>
    </>
    
    return display
}

export default BrowserSourceStateDisplayComponent;