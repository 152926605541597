import BlueButton from "../../../../common/BlueButton/BlueButton";
import BlueCheckBox from "../../../../common/BlueCheckBox";
import BlueInputFile from "../../../../common/BlueInputFile";
import Container from "../../../../common/Container";
import InputBar from "../../../../common/InputBar";
import { useCallback } from "react";

const ImageUploaderDisplay = ({
    convertFile,
    nameInput,
    isButtonUploadGray,
    uploadTemplate,
    setNameInput,
    uploadMessage,
    uploadFileData,
    removeSetImage,
    isPublicUpload,
    setIsPublicUpload
}) => {
    
    const onDragOverEvent = (e) => {
        e.preventDefault();  
        e.stopPropagation();
    };

    const onDropEvent = (e) => {
        e.preventDefault();
        convertFile(e.dataTransfer, 'dragEvent');
    };

    const setIsPublic = useCallback(() => {
        if (isPublicUpload) {
            setIsPublicUpload(false)
        } else {
            setIsPublicUpload(true)
        }
    }, [isPublicUpload, setIsPublicUpload])

    let moreOptions = <>
        <div className="UploadMainContentInputAndButton">
            <div className="uploadMainImageDisplayXButton">
                <BlueButton text='X' isDark={true} onClick={() => removeSetImage()}/>
            </div>
            <img className='uploadMainImageDisplay' alt='imageUploadPreview' src={'data:image/jpeg;base64,'+uploadFileData}></img>
            <p className="NoteTextUploadedimage">By uploading an image to Stream Memes, users confirm that they hold the rights to the image. If they opt to make their upload public, it will be added to the searchable library and they grant other streamers permission to use it.</p>
            <BlueCheckBox text='Add to public meme library' id='privateCheck' onChange={setIsPublic} defaultChecked={isPublicUpload}/>
            <br/>
            <br/>
            <InputBar 
                placeholder='Name' 
            
                onInput={e => setNameInput(e.target.value)} 
                value={nameInput}
            />
            <BlueButton text='Upload' isGray={isButtonUploadGray} onClick={() => uploadTemplate(isPublicUpload)}/>
        </div>
        <br/>
    </>

    let uploadOption = <>
        <div className="uploadMainContentDragAndDropContainer" onDragOver={(e) => onDragOverEvent(e)} onDrop={(e) => onDropEvent(e)}>
            <BlueInputFile text='Drag and drop image' onChange={e =>  convertFile(e)} />
        </div>
    </>

    let content = <Container content={
        <>
            {uploadFileData ? moreOptions : uploadOption}
        </>
    }/>
    
    if (uploadMessage) content = <Container content={<p>{uploadMessage}</p>}/>
    return content;
}

export default ImageUploaderDisplay;