import BlueButton from "../../../../common/BlueButton/BlueButton";
import CardContainer from "../../../../common/CardContainer";

const ImageCardDisplayElement = ({
    uploadedTemplates,
    removeUploadedTemplateQuestion,
    isButtonGray,
    selectUploadedTemplate,
    selectedTemplatesMap
}) => {
    //loop through each elemnt
    let arr = [];
       
    if (uploadedTemplates.length) {
        uploadedTemplates.forEach((item, i) => {
            let rightContent = <>
                <p>{item.message ? item.message : 'Select Your Meme.'}</p>
                <BlueButton 
                        isCenter={true} 
                        text='Select' 
                        onClick={() => selectUploadedTemplate(item)}
                    />
            </>

        if (selectedTemplatesMap[item.thumbnailUrl]) rightContent = <p>Selected.</p>

        let content = <CardContainer 
                            item={item}
                            isLoading={isButtonGray} 
                            removeItem={removeUploadedTemplateQuestion} 
                            isButtonGray={isButtonGray} 
                            contentRight={rightContent}
                            contentLeft={
                                <>
                                    <p>{`Publicly visible : ${item.publicTemplate ? 'yes': 'no'}`}</p>
                                    <p>{`Uploader : ${item.uploader}`}</p>
                                    <br/> 
                                </>
                            }
                        />

            //here we sort out the deleted elements
            if(item.isHidden) content = <></>

            arr.push((<div key={i}>{content}</div>))        
        });
    }
    return (<>{arr}</>);
}

export default ImageCardDisplayElement;