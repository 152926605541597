import { useEffect, useState, useCallback } from "react";
import InputContainer from "../../../common/InputContainer";
import memeService from '../../../../services/MemeService';
import './Browse.css';
import DisplaySearch from "./DisplaySearch";

const BrowseTemplates = ({
  userSearchInput,
  lastSearchInput,
  setLastSearchInput,
  searchButtonIsGray,
  setSearchButtonIsGray,
  templates,
  setTemplates,
  setTemplatePriceList,
  templatePriceList,
  hasMore,
  setHasMore,
  isLoading,
  setIsLoading,
  setMetaData,
  markerRef,
  onSearchInput,
  loadUserSettings,
  getSelectedTemplates,
  selectedTemplatesMap
}) => {

    const [isMounted, setIsMounted] = useState(false);

    const searchItem = useCallback(async () => {
      if (isLoading) {
          return;
      }

      setSearchButtonIsGray(true);
      setIsLoading(true)

      let searchResult = {}
      if (lastSearchInput === userSearchInput) {
          // If the search hasn't changed, get the next page.
          searchResult = await memeService.searchImageLibrary(userSearchInput, templates.length);
          if (searchResult.results) {
            setTemplates([...templates, ...searchResult.results]);
            if(searchResult.results.length && !(searchResult.results.length % 20)) {
                setHasMore(true);
            } else {
                setHasMore(false);
            }
          } else {
            setHasMore(false);
          }
      } else {
          // If the search input changed, get the first page
          searchResult = await memeService.searchImageLibrary(userSearchInput);
          if (searchResult.results) {
            setTemplates(searchResult.results);
            if(searchResult.results.length && !(searchResult.results.length % 20)) {
                setHasMore(true);
            } else {
                setHasMore(false);
            }
          } else {
            setHasMore(false);
          }
      }

      // load in priceList
      if (searchResult.priceList && !templatePriceList) {
        setTemplatePriceList(searchResult.priceList);
      } 
      else if (!searchResult.priceList) {
        console.warn("Couldn't load price list!");
      }

      setLastSearchInput(userSearchInput);
      setSearchButtonIsGray(false);
      setIsLoading(false)
  },[isLoading, userSearchInput, templates, lastSearchInput, setHasMore, setIsLoading, setLastSearchInput, setSearchButtonIsGray, setTemplates, templatePriceList, setTemplatePriceList])

    const intersectionCallback = useCallback(async (entries) => {
      const [ entry ] = entries;
      if (entry.isIntersecting) {
          await searchItem();
      }
    }, [searchItem])

    const handelKeyPress = (event) => {
      if (event.key === 'Enter'){
        setTemplates([]);
        searchItem()
      }
    }
 
    useEffect(() => {
        const current = markerRef.current;
        const intersectionCallbackOptions = {
          root: null,
          rootMargin: "0px",
          threshold: 1.0
        }
        const observer = new IntersectionObserver(intersectionCallback, intersectionCallbackOptions)
        if (current && hasMore) {
            observer.observe(current)
        }
        return () => {
            if (current) observer.unobserve(current)
        }
    }, [markerRef, intersectionCallback, hasMore])

    // This useEffect triggers calling search initially, and any time
    // The user search input returns to empty string.
    useEffect(() => {
      setIsMounted(true);
      
      if (isMounted && userSearchInput === '') {
          // load first page
          
          loadUserSettings()
          getSelectedTemplates()
          searchItem();
      }
      
      //fixing memory leak
      return () => setIsMounted(false)
  }, [isMounted, userSearchInput]) // eslint-disable-line react-hooks/exhaustive-deps


  
    return(<InputContainer 
      placeholder='Search ...'
      buttonText='Search'
      buttonOnClick={(() => searchItem())}
      onInput={onSearchInput}
      buttonIsGray={searchButtonIsGray}
      content={
          <DisplaySearch 
              templates={templates}
              setMetaData={setMetaData}
              isLoading={isLoading}
              markerRef={markerRef}
              selectedTemplatesMap={selectedTemplatesMap}
          />
      }
      onKeyPress={e => handelKeyPress(e)}
  />)
}

export default BrowseTemplates;