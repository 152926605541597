import Filter from "./Filter";

const FilterListItems = ({
    setNumberOfDeletedItems,
    numberOfDeletedItems,
    filters,
    inputContainerFilterClass
}) => {

    let arr = [];
    filters.forEach((item, i) => {
      
      let animationDisplay = i % 2 === 0 ? true : false

      arr.push((
        <Filter
          filterText={item.filterText}
          id={item.id}
          key={item.id}
          setNumberOfDeletedItems={setNumberOfDeletedItems}
          numberOfDeletedItems={numberOfDeletedItems}
          animationLeft={animationDisplay}
          animationRight={!animationDisplay}
          inputContainerFilterClass={inputContainerFilterClass}
        />
      ));
    })
    return (
        <>
          {arr}
        </>
    )

}

export default FilterListItems;