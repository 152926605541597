import React from 'react';
import ReactDOM from 'react-dom';
import './style/root.css';
import './style/components.css';
import './style/page.css';
import './style/introjs.css';
import './style/animations.css';
import App from './components/App';

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);
