import { Switch, Redirect } from 'react-router-dom';
import RouteWithSubRoutes from '../Routes/RouteWithSubRoutes';
import SideMenu from './Menus/SideMenu'
import './dashboard.css'
import React from 'react';
import Cookies from 'universal-cookie';
import CookieInfoBar from './CookieInfoBar';
import { useEffect } from 'react';
import { useLocation } from "react-router-dom";
import googleService from '../../services/GoogleService'

const cookies = new Cookies();

const Dashboard = ({ routes }) => {
  
  let location = useLocation()
  useEffect(() => {
    googleService.initialize();
  },[location])

  if (!cookies.get('twitch_user')) {
    return(<Redirect to ='/' />)
  }

  return (
    <>
      <SideMenu />
      <div className="middleSection">
        <Switch>
          {routes.map((route, i) => (
            <RouteWithSubRoutes key={i} {...route} />
          ))}
        </Switch>
      </div>
      <CookieInfoBar />
    </>
  )
}

export default Dashboard;
