import BlueScrollWindowPrompt from '../../common/BlueScrollWindowPrompt';
import ErrorHandler from '../../../services/ErrorHandler.js';
import TopDisplayLeft from './topDisplayLeft/index.js';
import TopDisplayRight from './topDisplayRight/index.js';
import MiddleDisplay from './middleDisplay/index.js';

import './displayComponentHomePage.css';

const DisplayComponentHomePage = ({
    showEula,
    params,
    loginLink, 
}) => {

  const appNotificationDisplays = <>
    {params ? <p className='displayComponentHomePageErrorMessage'>{ErrorHandler.displayMessage(params)}</p> : <></>}
        
    <div className='displayComponentHomePagetermsOfServcieDisplayContainer'>
      <BlueScrollWindowPrompt />
    </div>
    
    <p className='displayComponentHomePagetermsOfServcieDisplayText' onClick={() => {showEula()}}>Terms of service</p>
  </>

  const displayComponent = <>
    <div className='displayComponentHomePageContainerTop'>
      {appNotificationDisplays}
      <TopDisplayLeft loginLink={loginLink}/>
      <TopDisplayRight />
    </div>
    
    <div className='displayComponentHomePageWaveContainer'>
      <div className='displayComponentHomePageWaveDisplay'></div>
    </div>

    <MiddleDisplay />
    
  </>

  return displayComponent;
}

export default DisplayComponentHomePage;
