import './BlueProgressBar.css';

const BlueProgressBar = ({
    max,
    selected,
    title
}) => {
    const maxTemplates = max;

    const percentageCalc = (value, totalValue) => {
        return (100 * value) / totalValue;
    }

    let width = percentageCalc(selected, maxTemplates) + '%'
    return(
        <>
            <p className='ProgressBarText'> {title} {selected} / {maxTemplates} </p>
            <div className='ProgressBar' ><div style={{width:width}}></div></div>
        </>
    )
}

export default BlueProgressBar;