import Cookies from 'universal-cookie';
const cookies = new Cookies();

const TopContainer = ({
    username,
    title,
    getMenuOptions
}) => {
    let userLogo = cookies.get('userLogo');

    return (
        <>
            <div className="containterTop">
                <div className="imageOverlayNavBar">
                    <img src='/logo.svg' className="topNavbarImage" alt="logo" />
                    <img src='/logo.svg' className="topNavbarImage2" alt="logo" />
                </div>
                <div className="TopBar">
                    <div className='usernameContainer'>
                        <img src={userLogo} className="userLogoMenu" alt="logoUser" />
                        <h4 className="userNameDisplay">{username}</h4>
                    </div>
                    <h1 className="topTitleText">{title}</h1>
                    <div className='topNavBarContainer'>
                        <ul className="topNavbar">
                        {getMenuOptions()}
                        </ul>
                    </div>
                </div>
            </div>
        </>
    )
}

export default TopContainer;