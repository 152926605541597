import BlueButton from '../BlueButton/BlueButton.js';
import LoadingDisplay from '../LoadingDisplay/LoadingDisplay.js';

import './CardContainer.css';
import { useState } from 'react';

const CardContainer = ({
    item,
    removeItem,
    isButtonGray,
    contentRight,
    contentLeft,
    reportCount = 0,
    setLoading
}) => {
    const [containerClass, setContainerClass] = useState('ImageLoaderImageContainerContent')
    const [isRemoved, setIsRemoved] = useState(true)
    
    if (!contentRight) contentRight = <></>
    if (!contentLeft) contentLeft = <></>
    if (item.isGone) contentRight = <>
        <h3>This meme is no longer available.</h3>
        <BlueButton text='Remove' 
            isDark={false} 
            onClick={() => removeItem(item)} 
            isGray={isButtonGray}
        />
    </>
    if (!item) return <></>

    let displayComponent = <>
            <div className={containerClass}>
                <div className='ImageLoaderTitleAndBackButtonContainer'>
                    <div className='ImageLoaderTitle'>
                        <p>{item.name ? item.name : item.title}</p>
                    </div>
                    <div className='ImageLoaderImageBackButton'>
                        <BlueButton text='X' 
                            isDark={true} 
                            onClick={() => {
                                removeItem(item);
                            }} 
                            isGray={isButtonGray}
                        />
                    </div>
                </div>
            <hr className='ImageLoaderLineBreak'/>    
            <div className='ImageCardImageContainer'>
                <div className='ImageLoaderImageAndTitle'>
                    <img
                        className='ImageLoaderImageDisplay'
                        src={item.thumbnailUrl}
                        alt={item.title}
                        onError={(e) => {
                                e.target.onerror = null;
                                e.target.src = '/imageNotFound.jpg'
                                item.thumbnailUrl = '/imageNotFound.jpg';
                                item.isGone = true;
                                setIsRemoved(true)
                                setContainerClass('ImageLoaderImageContainerContentRemoved')
                            }
                        }
                    />
                </div>
                <div className='ImageLoaderTextAndButton'>
                    <div className='ImageLoaderLicenseText'>
                        {contentLeft} 
                    </div>
                    <div className='ImageLoaderInputValues'>
                        {contentRight}
                    </div>            
                </div>
            </div>
        </div>
        <br/>
    </>

    // sometimes react hooks are nested higher up so we provide 2 methods to set the display to loading
    // we also need to set a message this can be helpful for debugging
    if (setLoading || item.isLoading) {
        if (item.message) {
            displayComponent = <>
            <div className='ImageLoaderLoadingDisplay'>
                <LoadingDisplay />
            </div>
                 <div className='ImageLoaderImageContainerContentLoadingProgress'>
                        <div className='ImageLoaderTitleAndBackButtonContainer'>
                            <div className='ImageLoaderTitle'>
                                <p>{item.name ? item.name : item.title}</p>
                            </div>
                            <div className='ImageLoaderImageBackButton'>
                                <BlueButton text='X' 
                                    isDark={true} 
                                    isGray={true}
                                />
                            </div>
                        </div>
                    <hr className='ImageLoaderLineBreak'/>   
                    <div className='ImageCardImageContainer'>
                        <div className='ImageLoaderImageAndTitle'>
                            <img
                                className='ImageLoaderImageDisplay'
                                src={item.thumbnailUrl}
                                alt={item.title}
                                onError={(e) => {
                                        e.target.onerror = null;
                                        e.target.src = '/imageNotFound.jpg'
                                        item.thumbnailUrl = '/imageNotFound.jpg';
                                        item.isGone = true;
                                        setIsRemoved(true)
                                        setContainerClass('ImageLoaderImageContainerContentRemoved')
                                    }
                                }
                            />
                        </div>
                        <div className='ImageLoaderTextAndButton'>
                            <div className='ImageLoaderLicenseText'>
                                {contentLeft} 
                            </div>
                            <div className='ImageLoaderInputValues'>
                                { contentRight }
                            </div>            
                        </div>
                    </div>
                </div>
                <br/>
            </>
        }
    }

    // normal display
    if (!item.isGone && reportCount <= 5 && containerClass !== 'ImageLoaderImageContainerContent') {
        setContainerClass('ImageLoaderImageContainerContent')
    }

    // warning display
    if (!item.isGone && reportCount >= 5 && containerClass !== 'ImageLoaderImageContainerContentCountWarning') {
        setContainerClass('ImageLoaderImageContainerContentCountWarning')
    }

    // removed display
    if (isRemoved && item.isGone) {
        //console.log('aaa');
        if (containerClass !== 'ImageLoaderImageContainerContentRemoved') setContainerClass('ImageLoaderImageContainerContentRemoved')
    }

    return(displayComponent)
}

export default CardContainer;