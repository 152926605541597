import { useEffect, useState } from 'react';
import adminService from '../../../services/AdminService';
import notificationService from '../../../services/NotificationService';
import Container from '../../common/Container';
import LoadingDisplay from '../../common/LoadingDisplay/LoadingDisplay';
import BlueScrollBackToTopButton from "../../common/BlueScrollBackToTopButton";
import ContentCard from './ContentCard';

const ReportedImages = () => {
    
    const [reportedUploadedTemplates, setReportedUploadedTemplates] = useState([]);
    const [isMounted, setIsMounted] = useState(false);
    const [isButtonGray, setIsButtonGray] = useState(false);
    const [isLoading, setIsloading] = useState(false);

    const loadUserUploadedReportedTemplates = async () => {
        setIsloading(true)
    
        setReportedUploadedTemplates([]);

        let templateAccumulationArray = [];
        let paginationToken = null;
        try {
    
          do {
            let responseObject = await adminService.getAllReportedImages(paginationToken);
    
            responseObject.reportedImages.forEach((template) => {
                template.title = template.templateId
                template.thumbnailUrl = template.url
                return templateAccumulationArray.push(template);
            });
    
            setReportedUploadedTemplates(templateAccumulationArray);
            paginationToken = responseObject.paginationToken;
          } while (paginationToken);
          return reportedUploadedTemplates;
        } catch (e) {
            notificationService.setMessage('I could not load Reported Images!')
            setReportedUploadedTemplates([]);
        }
    };

    const deleteReportedImage = async (templateId, item) => {
        setIsButtonGray(true)
        let state = 'DELETE_COMPLETE' //DELETE_FROM_SEARCH //DELETE_FROM_REPORT
        await adminService.deleteReportedUploadedImages(state, templateId)
        item.searchable = 'true';
        item.imageRemoved = 'false';
        setIsButtonGray(false)
    };


    const deleteReported = async (templateId, item) => {
        setIsButtonGray(true)
        let state = 'DELETE_FROM_REPORT'
        await adminService.deleteReportedUploadedImages(state, templateId)
        item.isHidden = true;
        setIsButtonGray(false)
    };

    
    const deleteReportedImageFromSearch = async (templateId, item) => {
        setIsButtonGray(true)
        let state = 'DELETE_FROM_SEARCH'
        await adminService.deleteReportedUploadedImages(state, templateId)
        item.searchable = 'false';
        setIsButtonGray(false)
    };

    function timeConverter(timestamp){
        return new Date(parseInt(timestamp)).toLocaleString();
    }

    let displayComponent= <></>

    if (isLoading) {
        displayComponent = <LoadingDisplay/>
    }

    if (!isLoading) {
        displayComponent= 
        <div className="scrollableContainer">
            <BlueScrollBackToTopButton buttonShowThreshold={1000} left={true}/>
            <Container title='Reports' content={
                        <ContentCard
                            reportedUploadedTemplates={reportedUploadedTemplates}
                            deleteReported={deleteReported}
                            isButtonGray={isButtonGray}
                            deleteReportedImageFromSearch={deleteReportedImageFromSearch}
                            deleteReportedImage={deleteReportedImage}
                            timeConverter={timeConverter}
                        />
                    } isNoBackground={true}
                />
        </div>
    }

    if (reportedUploadedTemplates.length === 0 && !isLoading) {
        displayComponent = <Container title='NO REPORTED IMAGES'/>
    }

    useEffect(() => {
        setIsMounted(true);
        
        if (isMounted) {
            loadUserUploadedReportedTemplates().then(() => setIsloading(false))  
        }   


        //fixing memory leak
        return () => setIsMounted(false);
    },[isMounted])// eslint-disable-line react-hooks/exhaustive-deps

    


    return displayComponent;
}

export default ReportedImages;