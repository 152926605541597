import Container from "../../../../common/Container";
import Carousel from "../../../../common/Carousel";
import memeService from '../../../../../services/MemeService';
import notificationService from '../../../../../services/NotificationService';

const MostRecentMemeContainerElement = ({ 
    item,
    currentImageDisplay,
    setCurrentImageDisplay,
    mostRecentMemes,
    setMostRecentMemes,
    changeDisplayImage
}) => {
    // item.thumbnailUrl might be undefined on beta !

    //remove selected most recent meme
    const removeMostRecentMeme = async (item) => {
        if (!item.id) notificationService.setMessage('Removing failed!')
        
        await memeService.deleteMostRecentMeme(item.id).catch(()=> {
            notificationService.setMessage('Removing failed!')
        });
        
        let newArray = []
        await mostRecentMemes.forEach(element => {
            if (element !== item) newArray.push(element)
        });

        setMostRecentMemes(newArray)
        changeDisplayImage()
        notificationService.setTitle('Meme deleted')
        notificationService.setMessage((`${item.displayName ? item.displayName+"'s" : 'This'} meme was successfully deleted.`))
    };

    const mostRecentMemeQuestion = (item) => {
        notificationService.setMessage(`Do you want to remove or download ${item.displayName ? item.displayName+"'s" : 'this'} Meme?`)
        notificationService.setTitle('Meme')
        notificationService.setImage(item.thumbnailUrl ? item.thumbnailUrl : item.url)
    
        //delete
        notificationService.setButtonText('DELETE')
        notificationService.onClick(() => {
            //reset the window
            notificationService.clearAll()
            removeMostRecentMeme(item)
        })

        // download
        notificationService.setButtonText2('Download')
        notificationService.onClick2(() => {
            //reset the window
            notificationService.clearAll()
            window.open(item.url, 'Download')
        })
    };

    let currentDisplayName = item[currentImageDisplay] ? item[currentImageDisplay].displayName : null
    let currentItemPrice = item[currentImageDisplay] ? item[currentImageDisplay].price : null
    let currentImage = item[currentImageDisplay]

    let textToDisplayBelow = `From : ${currentDisplayName ? currentDisplayName.toUpperCase() : 'someone'} ${currentItemPrice !== '0' ? 'with : ' + currentItemPrice + ' Bits' : ''}`
    const onClickFocusImage = () => mostRecentMemeQuestion(currentImage)
    
    let content = <>
    <h2>Most Recent Memes</h2>
        <div className="mostRecentMemesContainerGlobal">
            <Container isNoBackground={true} maxWidth={'auto'}
                content={ 
                    <>
                        <Carousel 
                            item={item}
                            currentlyDisplayedIndex={currentImageDisplay}
                            noItemWasFoundText={'No memes to display yet!'}
                            noItemDescriptionText={'This will show recent memes viewers created on your stream.'}
                            setCurrentlyDisplayedIndex={setCurrentImageDisplay}
                            textBelowCarousel={textToDisplayBelow}
                            OnClickFocusImage={onClickFocusImage}
                        />
                    </>
                } 
            />
        </div>
    </>
 

    return content
}

export default MostRecentMemeContainerElement;