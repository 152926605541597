import globalConstants from '../../globals/GlobalConstants';
import { useEffect, useCallback } from 'react';
import './HomePage.css';
import { useLocation } from 'react-router';
import updateDisplayService from '../../services/UpdateDisplayService';
import eula from '../../eula/eula.js'
import DisplayComponentHomePage from './displayComponentHomePage';

const HomePage = () => {

  const clientId = globalConstants.clientId;
  const oauthRedirectUrl = globalConstants.oauthRedirectUrl;
  const claims = "{'id_token': {'email': null, 'preferred_username': null, 'picture': null, 'email_verified': null}}"
  const loginLink = `https://id.twitch.tv/oauth2/authorize?claims=${claims}&client_id=${clientId}&redirect_uri=${oauthRedirectUrl}&response_type=code&scope=user:read:email+openid+user_read`
  
  const location = useLocation();
  const paramsUrl = new URLSearchParams(location.search)
  const params = paramsUrl.get('params');
  const eulaParam = paramsUrl.get('eula');

  // reset cookies
  document.cookie = 'twitch_user= ;expires=Thu, 01 Jan 1970 00:00:01 GMT';
  document.cookie = 'jwt_token= ;expires=Thu, 01 Jan 1970 00:00:01 GMT';
  document.cookie = 'isMonetized=  ;expires=Thu, 01 Jan 1970 00:00:01 GMT'
  document.cookie = 'userLogo=  ;expires=Thu, 01 Jan 1970 00:00:01 GMT'
  
  const showEula = useCallback(() => {
    updateDisplayService.setMessage(eula.EulaText);
    updateDisplayService.setTitle('Terms of service');
    updateDisplayService.onCancel(()=> updateDisplayService.clearAll())
  },[]);
  
  
  useEffect(() => {
    if (eulaParam === 'show') showEula()
  },[eulaParam, showEula])


  return <DisplayComponentHomePage 
    showEula={showEula} 
    params={params} 
    loginLink={loginLink}
  />
}

export default HomePage;
