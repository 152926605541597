class NotificationService {
    constructor () {
        this.title = null;
        this.onClickFunction = null;
        this._registeredSetMessageFunction = null;
        this._registeredSetTitleFunction = null;
        this._registeredSetOnClickFunction = null;
        this._registeredSetOnClickFunction2 = null;
        this._registeredSetButtonText = null;
        this._registeredSetButtonText2 = null;
        this._registeredSetImageUrl = null;
        this._registeredSetButtonGray = null;
        this._registeredSetButtonGray2 = null;
    }
    _registerSetMessageFunction(setMessageFunction) {
        this._registeredSetMessageFunction = setMessageFunction;
    }

    _registerSetTitleFunction(setTitleFunction) {
        this._registeredSetTitleFunction = setTitleFunction;
    }

    _registerSetOnClickFunction(setOnClickFunction) {
        this._registeredSetOnClickFunction = setOnClickFunction;
    }

    _registerSetOnClickFunction2(setOnClickFunction2) {
        this._registeredSetOnClickFunction2 = setOnClickFunction2;
    }

    _registerSetButtonText(buttonText) {
        this._registeredSetButtonText = buttonText;
    }

    _registerSetButtonText2(buttonText2) {
        this._registeredSetButtonText2 = buttonText2;
    }

    _registerSetImageUrl(url) {
        this._registeredSetImageUrl = url;
    }

    _registerSetButtonGray(value) {
        this._registeredSetButtonGray = value;
    }

    _registerSetButtonGray2(value) {
        this._registeredSetButtonGray2 = value;
    }

    clearMessage() {
        this._registeredSetMessageFunction(null);
    }

    clearAll() {
        this._registeredSetMessageFunction(null);
        this._registeredSetOnClickFunction(null);
        this._registeredSetOnClickFunction2(null);
        this._registeredSetTitleFunction(null);
        this._registeredSetButtonText('okay');
        this._registeredSetButtonText2('okay');
        this._registeredSetImageUrl(null);
        this._registeredSetButtonGray(null);
        this._registeredSetButtonGray2(null);
    }
    
    setMessage(message) {
        this._registeredSetMessageFunction(message);
    }
    
    setTitle(title) {
        this._registeredSetTitleFunction(title);
    }
    
    setButtonText(text) {
        this._registeredSetButtonText(text);
    }

    setButtonText2(text) {
        this._registeredSetButtonText2(text);
    }

    setButtonGray(value) {
        this._registeredSetButtonGray(value)
    }
    
    setButtonGray2(value) {
        this._registeredSetButtonGray2(value)
    }

    setImage(url) {
        this._registeredSetImageUrl(url);
    }

    onClick(fun) {
        this._registeredSetOnClickFunction(fun);
    }

    onClick2(fun) {
        this._registeredSetOnClickFunction2(fun);
    }

}

const singleton = new NotificationService();
export default singleton;