import { BrowserRouter as Router, Switch } from 'react-router-dom';
import routes from '../Routes/routes.js';
import RouteWithSubRoutes from '../Routes/RouteWithSubRoutes';
import NotificationModal from '../common/NotificationModal';
import BlueScrollWindowPrompt from '../common/BlueScrollWindowPrompt';

const App = () => {
  
  return (
    <>
    <NotificationModal /> 
    <BlueScrollWindowPrompt />
    <Router>
      <Switch>
        {routes.map((route, i) => (
          <RouteWithSubRoutes key={i} {...route} />
        ))}
      </Switch>
    </Router>
    </>
  )
}

export default App;
