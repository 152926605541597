import { useEffect, useState } from "react";
import LoadingDisplay from "../../../common/LoadingDisplay/LoadingDisplay";
import memeService from '../../../../services/MemeService';
import DisplayLogic from "./DisplayLogic";
import notificationService from '../../../../services/NotificationService';

const Upload = () => {
    
    const [isLoading, setIsLoading] = useState(true);
    const [uploadedTemplates, setUploadedTemplates] = useState([]);
    const [elementDeletedCount, setElementDeletedCount] = useState(0);
    const [userSettings, setUserSettings] = useState(null);
    const [isMounted, setIsMounted] = useState(false);
    const [selectedTemplatesMap, setSelectedTemplatesMap] = useState({});

    let displayComponent = (<></>);

    if (isLoading) displayComponent = <LoadingDisplay />
    if (!isLoading) displayComponent = <DisplayLogic 
                                            uploadedTemplates={uploadedTemplates}
                                            setUploadedTemplates={setUploadedTemplates}
                                            userSettings={userSettings}
                                            elementDeletedCount={elementDeletedCount}
                                            setElementDeletedCount={setElementDeletedCount}
                                            selectedTemplatesMap={selectedTemplatesMap}
                                            setSelectedTemplatesMap={setSelectedTemplatesMap}
                                        />

    const loadUserSettings = async () => {
        let settings = await memeService.getBroadcasterSettings();
        setUserSettings(settings);
        return;
    };

    const loadUserUploadedTemplates = async () => {
        
        setUploadedTemplates([]);

        let templateAccumulationArray = [];
        let paginationToken = null;
        try {
    
          do {
            let responseObject = await memeService.listUploadedTemplates(paginationToken);
    
            responseObject.templates.forEach((template) => {
              return templateAccumulationArray.push(template);
            });
    
            setUploadedTemplates(templateAccumulationArray);
            paginationToken = responseObject.paginationToken;
          } while (paginationToken);
          return uploadedTemplates;
        } catch (e) {
            notificationService.setMessage('I could not load your Memes!')
            setUploadedTemplates([]);
        }
    };

        //get selected memes
        const getSelectedTemplates = async () => {
            setSelectedTemplatesMap({});
            let templateAccumulationMap = {};
            let paginationToken = null;
            try {
        
                do {
                let responseObject = await memeService.listSelectedTemplates(paginationToken);
        
                responseObject.templates.forEach((template) => {
                    return templateAccumulationMap[template.thumbnailUrl] = template;
                });
        
                setSelectedTemplatesMap(templateAccumulationMap);
                paginationToken = responseObject.paginationToken;
                } while (paginationToken);
                return selectedTemplatesMap;
            } catch (e) {
                notificationService.setMessage('I could not load your selected memes!')
                setSelectedTemplatesMap({});
            }
        
        };

    const loadPage = async() => {
        await loadUserUploadedTemplates();
        await getSelectedTemplates();
        await loadUserSettings();    
    };

    useEffect(() => {  
            
        setIsMounted(true)
        if (isMounted) {
            //page loading logic
            setIsLoading(true);
            loadPage().then(() => setIsLoading(false))
        }

        //fixing memory leak
        return () => setIsMounted(false)
    },[isMounted]) // eslint-disable-line react-hooks/exhaustive-deps

    return displayComponent;
}

export default Upload;