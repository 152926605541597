import { useState } from "react";
import BlueButton from "../../common/BlueButton/BlueButton";
import './CookieInfoBar.css';
import Cookies from 'universal-cookie';
const cookies = new Cookies();

const CookieInfoBar = () => {
    
    const [cookieBarDisplayClass, setCookieBarDisplayClass] = useState('DisplayCookieBar');
 
    let displayElement = (<></>);

    if (!cookies.get('CookieInfoBarChecked')) {
        displayElement = <> 
                            <div className={cookieBarDisplayClass}>
                                <p className ='DisplayCookieBarText'>This website uses cookies to ensure you get the best experience on our website.</p>
                                <div className='DisplayCookieBarButtonContainer'>
                                    <BlueButton text='Got it' onClick={() => {
                                        document.cookie = "CookieInfoBarChecked=" + true + ";expires="+ new Date(new Date().getTime()+60*60*500000*24).toGMTString()+";path=/";                                       
                                        setCookieBarDisplayClass('DisplayCookieBarHidden')
                                    }}/>
                                </div>
                                
                            </div>
                        </>
    }

    return(<> {displayElement} </>)
}

export default CookieInfoBar;