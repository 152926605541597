import Container from "../../../../common/Container";
import ContentTextContainer from "../../../../common/ContentTextContainer";
import TestMemeContainer from "../../../../common/TestMemeContainer";
import EmbedYoutubeLogic from "./../EmbedYoutubeLogic";
import BrowserSourceLogic from "./../BrowserSourceLogic";
import './tutorial.css'

const Tutorial = ({ 
    profanityFilterExist,
    selectedMemeExists,
    testMemeExists,
    fetchedBrowserSource,
    browserSourceIsActive,
    manualApprovalState
}) => {
    let content = <></>

    // This returns user instructions 
    const whatToDoContent = () => {
        
        let profanitiFilterTextContent = <>
            <span>Click on </span> <img src='/document.svg' alt='#' className='iconSizeMenu'/>
            <span>This will take you to the profanity filter section. Here you can add words and phrases you don't want to be used in memes. You decide on which words to censor, including swear words, words associated with hate speech, harassment, etc. Simply type a new word or sentence that you want to be filtered and add it to your collection by clicking the add button.</span>
        </>

        let selectMemeTextContent = <>
            <span>You need to select a couple images if you want viewers to be able to use the extension on your stream. Click on </span><img src='/book-alt.svg' alt='#' className='iconSizeMenu'/>
            <span>and add a few images from the library to your collection or upload your own and select it!</span>
        </>

        let testMemeTextContent = <>
            <span>
                Copy & Paste the above browser source URL into a new 'browser source' layer in OBS.
                <br/>Then click the Test button to create a test meme and verify memes will show up on your stream.
                <br/>Confused? Watch the video below!
            </span>
            
        </>

        let contentToReturn = <></>
        let headerTitle = <h2>Setup Progress</h2>
      
        if (!profanityFilterExist || !selectedMemeExists || !testMemeExists) {
            
            try {
                let testMemeText = <ContentTextContainer title={testMemeExists ? "- Set up your OBS Browser Source layer & create a test meme (Completed)" : "- Set up your OBS Browser Source layer & create a test meme. (Not Complete)"} text={testMemeTextContent} alignLeft={true} green={testMemeExists}/>
                let selecteMemeText = <ContentTextContainer title={selectedMemeExists ? "- Select at least one image (Completed)" : "- Select at least one image you like. (Not Complete)"} text={selectMemeTextContent} alignLeft={true} green={selectedMemeExists}/>
                let profanityFilterText = <ContentTextContainer title={profanityFilterExist ? "- Optional: Add profanity filters (Completed)" : "- Optional: Add profanity filters (Not Complete)"} text={profanitiFilterTextContent} alignLeft={true} green={profanityFilterExist}/>
                

                contentToReturn = <>
                    <Container content= {
                        <div className="TutorialContainer">
                            {headerTitle}
                            <BrowserSourceLogic fetchedBrowserSource={fetchedBrowserSource} />
                            <div className="tutorialContentContainerSetup">
                                <div className="tutorialTestMemeContainerTest">
                                    <TestMemeContainer extended={true} />
                                </div>
                                <div className="tutorialContainerContentSide">
                                    {testMemeText}
                                    {selecteMemeText}
                                    {profanityFilterText}
                                </div>
                                
                            </div>
                            
                        </div>
                    } isNoBackground={true}/>  
                     <EmbedYoutubeLogic embedId='0p-uou_ZCts' title='Step-by-Step Video guide' /> 
                </>
            } catch (e) {
                console.error('user set up information was not loaded correctly')
            }
        }

        else  {
            let browserSourceStatusText = <>
                <div className="tutorialStatusTextOnline">
                    Your Stream Memes browser source is online
                <br/>
                    <p className="tutorialStatusUrl">You're all good to go!</p>
                </div>
            </>
        
            if (!browserSourceIsActive) browserSourceStatusText = <>
                <div className="tutorialStatusTextOffline">
                        Your Stream Memes browser source is offline
                    <br/>
                    <p className="tutorialStatusUrl" onClick={() => window.location = '/dashboard/settings/browsersource'}>Go to settings to retrieve your browser source. </p>
                </div>
                
            </>

            let manualApprovalStautsText = manualApprovalState ? <>
                <div className="tutorialStatusTextOnline">
                    Manual meme approval is enabled.
                    <br/>
                    <p className="tutorialStatusUrl" onClick={() => window.location = '/dashboard/settings/browsersource'}>You can disable manual approval in settings.</p>
                </div>
            </> : <></>

            contentToReturn = <>
            <Container content= {
                <div className="TutorialContainer">
                    <h2>Status</h2>
                    {manualApprovalStautsText}
                    {browserSourceStatusText}
                </div>
            } isNoBackground={false}/>   
        </>
        }
        
        return contentToReturn
    };

    content = <>
        {whatToDoContent()}
    </>

    return content
}

export default Tutorial;