import { useState } from 'react';
import memeService from '../../../services/MemeService';
import adminService from '../../../services/AdminService';
import Socket from '../../../services/WebsocketService';
import './test.css';
import notificationService from '../../../services/NotificationService';
import TestDisplayElement from './TestDisplayElement';
import Cookies from 'universal-cookie';
const cookies = new Cookies();

const Tests = () => {
    const [checkStatus, setCheckStatus] = useState('No test running')
    const [isButtonDisabled, setIsButtonDisabled] = useState(false)
    const [testStartTime, setTestStartTime] = useState(null)
    const [consoleOutput, setConsoleOutput] = useState('STREMMEMES CONSOLE v1')
    const [errorCounter, setErrorCounter] = useState(0)
    
    const testMode = (message, entering, error) => {
        if (entering) {
            setTestStartTime(performance.now())
            console.warn(`${message} START TEST MODE`)
            addToConsoleDisplay(`${message} START TEST MODE`)
            setErrorCounter(0)
            setCheckStatus('Testing...');
            setIsButtonDisabled(true);
        } else {
            let timeResult = performance.now() - testStartTime
            
            console.warn(timeResult)
            setCheckStatus(`No test running`);
            addToConsoleDisplay(`${message} finished in ${timeResult} ms`)
            addToConsoleDisplay(`END OF TEST MODE ${errorCounter} ERRORS`)
            setIsButtonDisabled(false);
            
            if (error) {
                setErrorCounter(errorCounter => errorCounter += 1)
                notificationService.setMessage(message);
                notificationService.setTitle('Test Result');
            }
            
        }
    };

    const callBroadcasterSettings = async () => {
        testMode('callBroadcasterSettings', true)

        try {
            let response = await memeService.getBroadcasterSettings()
            logAndDisplayTest(`--- GetBroadcasterSettings ---`, response)
        
            if (
                !typeof Number(response.storageLimit) && 
                !typeof Number(response.broadcasterThemeStyle) && 
                !typeof Number(response.browserSourceInterval) && 
                !typeof Number(response.browserSourceNotifySound) &&
                !typeof Number(response.browserSourceTimeOut) &&
                !typeof Number(response.uploadLimit) &&
                !typeof Boolean(response.manualApproval)
            ) return testMode('getBroadcasterSettings() is not working correctly', false , 'error')      
        
            let settings = await memeService.updateBroadcasterSettings(
                parseInt(response.browserSourceInterval),
                parseInt(response.browserSourceNotifySound),
                parseInt(response.broadcasterThemeStyle),
                parseInt(response.browserSourceTimeOut)
            )
            
            logAndDisplayTest(`--- UpdateBroadcasterSettings ---`, settings)

            if(
                !typeof Number(settings.storageLimit) && 
                !typeof Number(settings.broadcasterThemeStyle) && 
                !typeof Number(settings.browserSourceInterval) && 
                !typeof Number(settings.browserSourceNotifySound) &&
                !typeof Number(settings.browserSourceTimeOut) &&
                !typeof Number(settings.uploadLimit) &&
                !typeof Boolean(response.manualApproval)
            ) return testMode('updateBroadcasterSettings() is not working correctly', false , 'error')  
            
            if (settings.errorCode === 'INVALID_INPUT') return testMode('updateBroadcasterSettings() returned INVALID_INPUT', false , 'error') 
            
            testMode('BroadcasterSettings() is working correctly')
        } catch (e) {
            testMode('BroadcasterSettings() is not working correctly !', false , 'error') 
            logAndDisplayTest('BroadcasterSettings', e, 'error')
        }
        
    };

    const callProfinityFilters = async () => {
        testMode('callProfinityFilters',true)
        try {
            let response = await memeService.createProfanityFilter('API_TEST_CALL')
            logAndDisplayTest(`--- CreateProfinityFilter ---`, response)
            if (!response.id && !response.filterText) return testMode('createProfinity() has no ID or filterText!', false , 'error')
        
            let response2 = await memeService.updateProfanityFilter(response.id, 'API_TEST_CALL_UPDATE');
            logAndDisplayTest(`--- UpdateProfinityFilter ---`, response2)
            if (!response2.id && !response2.filterText) return testMode('updateProfanityFilter() has no ID or filterText!', false , 'error')
            

            let response3 = await memeService.listAllProfanityFilters(null);
            logAndDisplayTest(`--- ListAllProfinityFilters ---`, response3)
            if (!response3.filters[0].id) return testMode('listAllProfanityFilters() has no ID!', false , 'error')
            
            logAndDisplayTest(`--- Deleting ProfinityFilter ---`)
            await memeService.deleteProfanityFilter(response2.id)
            testMode('profinityFilters() is working correctly')
        } catch (e) {
            testMode('profinityFilters() is not working correctly !', false , 'error') 
            logAndDisplayTest('profinityFilters', e, 'error')
        }
        
    };

    const callBrowserSource = async () => {
        testMode('callBrowserSource', true)

        try {
            let response = await memeService.getBrowserSource();
            logAndDisplayTest(`--- getBrowserSource ---`, response)
        
            if (!response.url) {
                let createdBS = await memeService.createBrowserSource();

                logAndDisplayTest(`Are you a new user ? --- createBrowserSource`, createdBS)
                if (!createdBS.url) return testMode('getBrowserSource() and createBrowserSource() is not working correctly', false , 'error');
            }
            
            testMode('browserSource() is working correctly');
        } catch (e) {
            testMode('browserSource() is not working correctly !', false , 'error') 
            logAndDisplayTest('browserSource', e, 'error')
        }
        
    };

    const callSearchAndAddAndSelectTemplates = async () => {
        testMode('callSearchAndAddAndSelectTemplates',true)

        try {
            let searchResult = await memeService.searchImageLibrary('', 0);
            let testMemeId = searchResult.results ? searchResult.results[0].templateId : null

            logAndDisplayTest(`--- searchImageLibrary ---`, searchResult)

            if (searchResult.priceList !== null && !searchResult.priceList) {
                return testMode('searchImageLibrary() is not working correctly !', false , 'error')
            }

            if (!testMemeId) return testMode('searchImageLibrary() is not working correctly !', false , 'error')
            
            let addedResult = await memeService.addSelectedTemplates('0', testMemeId, 'API_TEST')

            logAndDisplayTest(`--- addSelectedTemplates ---`, addedResult)

            if (!addedResult.id || !addedResult.name || !addedResult.type) return testMode('addSelectedTemplates() is not working correctly !', false , 'error')

            let updatedTemplate = await memeService.updateSelectedTemplate(addedResult.id,'0','API_TEST_UPDATED')
            
            logAndDisplayTest(`--- updateSelectedTemplate ---`, updatedTemplate)
            if (updatedTemplate.status !== 200) return testMode('updateSelectedTemplate() is not working correctly !', false , 'error')
            if (!updatedTemplate) return testMode('updateSelectedTemplate() is not working correctly !', false , 'error')

            let listedTemplate = await memeService.listSelectedTemplates(null)
            
            logAndDisplayTest(`--- listSelectedTemplates ---`, listedTemplate)
    
            if (!listedTemplate) return testMode('listSelectedTemplates() is not working correctly !', false , 'error')

            logAndDisplayTest(`--- Deleting Template  ---`)
    
            await memeService.deleteSelectedTemplate(addedResult.id)
            testMode('callSearchAndAddAndSelectTemplates() is working correctly !') 

        } catch (e) {
            testMode('callSearchAndAddAndSelectTemplates() is not working correctly !', false , 'error') 
            logAndDisplayTest('callSearchAndAddAndSelectTemplates', e, 'error')
        }
    };

    const callListPendingApprovalMemes = async () => {
        testMode('callListPendingApprovalMemes', true)

        try {
            let listedApprovalMemes = await memeService.listPendingApprovalMemes()
            logAndDisplayTest(`--- listPendingApprovalMemes ---`, listedApprovalMemes)
    
            if (listedApprovalMemes.memes) {
                let firstMeme = listedApprovalMemes.memes[0]
                
                if (firstMeme) {
                    if (!firstMeme.price) return testMode('listPendingApprovalMemes() is not working correctly !', false , 'error')
                    if (!firstMeme.id) return testMode('listPendingApprovalMemes() is not working correctly !', false , 'error')
                } 
            } else return testMode('listPendingApprovalMemes() has no pending memes !')
    
            testMode('callListPendingApprovalMemes() is working correctly !') 

        } catch (e) {
            testMode('callListPendingApprovalMemes() is not working correctly !', false , 'error') 
            logAndDisplayTest('callListPendingApprovalMemes', e, 'error')
        }
       
    }

    const callUploadedTemplates = async () => {
        testMode('callUploadedTemplates',true)

        try {
            let response = await memeService.uploadTemplate(
                'API_TEST_UPLOAD', 
                'false', 
                'iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVQYV2NgYAAAAAMAAWgmWQ0AAAAASUVORK5CYII='
            )
            
            logAndDisplayTest(`--- uploadTemplate ---`, response)
            if (!response.template) return testMode('uploadTemplate() is not working correctly !', false , 'error')
            if (!response.template.id) return testMode('uploadTemplate() is not working correctly !', false , 'error')
    
            let responseList = await memeService.listUploadedTemplates(null);
            
            logAndDisplayTest(`--- listUploadedTemplates ---`, responseList)
            if (!responseList.templates[0]) return testMode('listUploadedTemplates() is not working correctly !', false , 'error')
            if (!responseList.templates[0].id) {
                return testMode('listUploadedTemplates() is not working correctly!', false , 'error')
            }
    
            logAndDisplayTest(`--- deleteUploadedTemplate ---`)
            await memeService.deleteUploadedTemplate(response.template.id)
            
            testMode('UploadedTemplates() is working correctly!')
        } catch (e) {
            testMode('callUploadedTemplates is not working correctly !', false , 'error') 
            logAndDisplayTest('callUploadedTemplates', e, 'error')
        }
       
    };

    const callSetupProgress = async () => {
       testMode('callSetupProgress', true)

       let response = await memeService.getSetupProgress()
       logAndDisplayTest(`--- getSetupProgress ---`, response)

       let isValidResponse = true
       if (response.hasSelectedTemplates !== true && response.hasSelectedTemplates !== false) isValidResponse = false
       if (response.hasProfanityFilters !== true && response.hasProfanityFilters !== false) isValidResponse = false
       if (response.hasCreatedTestMeme !== true && response.hasCreatedTestMeme !== false) isValidResponse = false
       if (response.hasActiveConnection !== true && response.hasActiveConnection !== false) isValidResponse = false

       if (!isValidResponse) return testMode('getSetupProgress() is not working correctly!', false , 'error')

       testMode('UploadedTemplates() is working correctly!')
    }

    const callMostRecentMemems = async () => {
        testMode('callMostRecentMemems', true)

        try {
            let response = await memeService.listMostRecentMemes()
            logAndDisplayTest(`--- listMostRecentMemes ---`, response)

            if (response.memes) {
                let firstMeme = response.memes[0]
                let isValidResponse = true

                if (!firstMeme.price) isValidResponse = false
                if (!firstMeme.id) isValidResponse = false
                if (!firstMeme.url) isValidResponse = false
                if (!firstMeme.displayName) isValidResponse = false

                if (!isValidResponse) return testMode('listMostRecentMemes() is not working correctly!', false , 'error')
                
                testMode('listMostRecentMemes() is working correctly!')
            } else return testMode('listMostRecentMemes() has no memes!', false , 'error')
        } catch (e) {
            testMode('listMostRecentMemes is not working correctly !', false , 'error') 
            logAndDisplayTest('callUploadedTemplates', e, 'error')
        }
        
    }

    const callGetAllReportedImages = async () => {
        testMode('callGetAllReportedImages', true)

        try {
            let response = await adminService.getAllReportedImages();
            logAndDisplayTest(`--- getAllReportedImages ---`, response)
    
            if (response.reportedImages) {
                let reportedImage = response.reportedImages[0]
                let isValidResponse = true
    
                if (!reportedImage.count) isValidResponse = false
                if (!reportedImage.creator) isValidResponse = false
                if (!reportedImage.gsi) isValidResponse = false
                if (!reportedImage.imageRemoved) isValidResponse = false
                if (!reportedImage.pk) isValidResponse = false
                if (!reportedImage.reporterList) isValidResponse = false
                if (!reportedImage.reporters) isValidResponse = false
                if (!reportedImage.searchable) isValidResponse = false
                if (!reportedImage.sk) isValidResponse = false
                if (!reportedImage.templateId) isValidResponse = false
                if (!reportedImage.time) isValidResponse = false
                if (!reportedImage.url) isValidResponse = false
    
                if (!isValidResponse) return testMode('getAllReportedImages() is not working correctly!', false , 'error')
    
                testMode('getAllReportedImages() is working correctly!')
            } else return testMode('getAllReportedImages() has no reported memes!', false , 'error')
            
        } catch (e) {
            testMode('getAllReportedImages is not working correctly !', false , 'error') 
            logAndDisplayTest('getAllReportedImages', e, 'error')
        }
       
    }

    const callModeratorFunctions = async () => {
        testMode('callModeratorFunctions', true)

        try {
            let isValidResponse = true
            let cleanUpId = null;

            const created = await memeService.createModerator('streammemesgg', false);
            logAndDisplayTest(`--- creating a moderator ---`, created);
            if (created.errorCode !== 'MODERATOR_ALREADY_EXISTS') {
                if (!created.moderator.modUsername) isValidResponse = false
                if (!created.moderator.modImageURL) isValidResponse = false
                if (!created.moderator.broadcasterUsername) isValidResponse = false
                if (!created.moderator.broadcasterImageURL) isValidResponse = false
                if (!created.moderator.id) isValidResponse = false
                if (created.moderator.approveDenyPermission) isValidResponse = false
            } else logAndDisplayTest(` --> moderator already exists! SKIP .....`);
            
            if (!isValidResponse) return testMode('createModerator() is not working correctly!', false , 'error')
            else  logAndDisplayTest(`--- creating (done) ---`);
            
            const listAll = await memeService.listAllBroadcasterModerators();
            logAndDisplayTest(`--- list all moderators ---`, listAll);
            
            if (!listAll.moderators[0].modUsername) isValidResponse = false
            if (!listAll.moderators[0].modImageURL) isValidResponse = false
            if (!listAll.moderators[0].broadcasterUsername) isValidResponse = false
            if (!listAll.moderators[0].broadcasterImageURL) isValidResponse = false
            if (!listAll.moderators[0].id) isValidResponse = false
            if (!listAll.moderators[0].approveDenyPermission && listAll.moderators[0].approveDenyPermission !== false ) isValidResponse = false

            if (listAll.moderators[0].id) cleanUpId = listAll.moderators[0].id;

            if (!isValidResponse) return testMode('listAllBroadcasterModerators() is not working correctly!', false , 'error')
            else  logAndDisplayTest(`--- listAllBroadcasterModerators (done) ---`);

            const updated = await memeService.updateModerator(listAll.moderators[0].id, true);
            logAndDisplayTest(`--- update moderator ---`, updated);
       
            if (!updated.moderator.modUsername) isValidResponse = false
            if (!updated.moderator.modImageURL) isValidResponse = false
            if (!updated.moderator.broadcasterUsername) isValidResponse = false
            if (!updated.moderator.broadcasterImageURL) isValidResponse = false
            if (!updated.moderator.id) isValidResponse = false
            if (updated.moderator.approveDenyPermission === false) isValidResponse = false
            
            if (!isValidResponse) return testMode('updateModerator() is not working correctly!', false , 'error')
            else  logAndDisplayTest(`--- updating (done) ---`);

            const getUserName = cookies.get('twitch_user') ? cookies.get('twitch_user').toUpperCase() : 'NO USER';
            const createModerator = await memeService.createModerator(getUserName, true);
            
            if (createModerator.errorCode !== 'MODERATOR_ALREADY_EXISTS') logAndDisplayTest(`--- Moderation channel created for ${getUserName} ---`);

            const listAllChannels = await memeService.listAllModeratorChannels();
            logAndDisplayTest(`--- list all broadcasters for moderation ---`, listAllChannels);
       
            if (!listAllChannels.broadcasters[0].broadcasterUsername) isValidResponse = false
            if (!listAllChannels.broadcasters[0].broadcasterImageURL) isValidResponse = false
            if (!listAllChannels.broadcasters[0].approveDenyPermission) isValidResponse = false

            if (!isValidResponse) return testMode('listAllModeratorChannels() is not working correctly!', false , 'error')
            else  logAndDisplayTest(`--- listAllModeratorChannels (done) ---`);

            // cleanup
            logAndDisplayTest(`--- deleteing moderatos ---`);
            if (cleanUpId) await memeService.deleteModerator(cleanUpId)
            logAndDisplayTest(`--- deleteing moderatos (done) ---`);
            
            testMode('callModeratorFunctions() work correctly!')
        } catch (e) {
            console.log(e)
            testMode('callModeratorFunctions are not working correctly !', false , 'error')
        }
        
    }

    const testAllTests = async () => {
        logAndDisplayTest(`--- Testing all APIS ---`)

        if (!errorCounter) await callBroadcasterSettings()
        if (!errorCounter) await callProfinityFilters()
        if (!errorCounter) await callBrowserSource()
        if (!errorCounter) await callSearchAndAddAndSelectTemplates()
        if (!errorCounter) await callUploadedTemplates()
        if (!errorCounter) await callListPendingApprovalMemes()
        if (!errorCounter) await callSetupProgress()
        if (!errorCounter) await callMostRecentMemems()
        if (!errorCounter) await callGetAllReportedImages()
        if (!errorCounter) await callModeratorFunctions()

        if (errorCounter) logAndDisplayTest(`--- ERROR ABORDING ALL TESTs ---`)
        logAndDisplayTest(`--- Testing of all APIS complete ---`)
    }

    const logAndDisplayTest = (text, obj, error) => {
        if (error) {
            setCheckStatus(text);
            console.error(text)
            console.error(obj)
            addToConsoleDisplay(text)
            addToConsoleDisplay(JSON.stringify(obj))
        } else {
            setCheckStatus(text);
            console.log(text)
            addToConsoleDisplay(text)
            
            if (obj) {
                console.log(obj)
                addToConsoleDisplay(JSON.stringify(obj))
            }
        }
        
    }

    const addToConsoleDisplay = (text) => {
        setConsoleOutput(consoleOutput => consoleOutput += `\n${text}`) 
        const console = document.getElementById("testSectionContainerConsoleDisplay");
        console.scrollTop = console.scrollHeight;
    }

    // logic on incoming msg
    const messageHandler = async (event) => {
        addToConsoleDisplay(`WebSocket : ${event.data}`)
    }
    
    // Establish websocket connection
    const queryStringParams = 'broadcasterManagementConnection=true'
    Socket.useWebsocketService({ queryStringParams, messageHandler })

    let content = <TestDisplayElement
        isButtonDisabled={isButtonDisabled}
        checkStatus={checkStatus}
        callBroadcasterSettings={callBroadcasterSettings}
        callProfinityFilters={callProfinityFilters}
        callBrowserSource={callBrowserSource}
        callSearchAndAddAndSelectTemplates={callSearchAndAddAndSelectTemplates}
        callUploadedTemplates={callUploadedTemplates}
        callListPendingApprovalMemes={callListPendingApprovalMemes}
        consoleOutput={consoleOutput}
        setConsoleOutput={setConsoleOutput}
        testAllTests={testAllTests}
        callSetupProgress={callSetupProgress}
        callMostRecentMemems={callMostRecentMemems}
        callGetAllReportedImages={callGetAllReportedImages}
        callModeratorFunctions={callModeratorFunctions}
    />

    return content
}

export default Tests;