import LoadingDisplay from '../common/LoadingDisplay/LoadingDisplay';
import memeService from '../../services/MemeService';
import React, { useEffect, useState } from 'react';
import { Redirect } from "react-router-dom";
import './login.css';

const LoggedIn = (props) => {

  const [statusLoading, setStatusLoading] = useState(null);
  useEffect(() => {
    const search = window.location.search;
    const params = new URLSearchParams(search);
    const twitchAuthCode = params.get('code');
    // Checks if it exists, othwerwise redirect back home.
    if (twitchAuthCode) {
      // Sets & Validates twitchAuthCode
      memeService.logIn(twitchAuthCode.trim()).then(async (res) => {
        // await sleep(1500)
        setStatusLoading(res.status);
      });
    } else {
      // If they don't have a twitchAuthCode, just send them back to the home page so
      // they can press the log in button.
      return (<Redirect to = '/' />);
    }
  }, []);

  if (statusLoading === 200) return (<Redirect to = '/dashboard' />);
  if (statusLoading && statusLoading !== 200) return (<Redirect to = '/?params=SERVER_NOT_REACHABLE' />);


  return (
    <div className="AppContainerLogin">
      <header className="App-header2">
        <div className='logInBackground'>  
          <div className='loginCenterBackground'>
            <h2 className="AppSTitleName">STREAM  <span className="AppSTitleName2">MEMES</span></h2>
            <LoadingDisplay />
          </div>
        </div>

      </header>
    </div>
  );
}

export default LoggedIn;