import InputBar from '../InputBar';
import BlueButton from '../BlueButton/BlueButton';
import './InputContainer.css'

const InputContainer = ({
    value,
    onInput,
    placeholder,
    buttonText,
    buttonOnClick,
    buttonIsGray,
    messageText,
    content,
    onKeyPress
}) => {


    return(
        <>
            <div className='InputContainerContentAndTopWrapper'>
                <div className='InputContainerTopWrapper'>
                    <InputBar 
                        value={value} 
                        placeholder={placeholder} 
                        onInput={onInput}
                        onKeyPress={onKeyPress}
                    />
        
                    <BlueButton text={buttonText} onClick={buttonOnClick} isGray={buttonIsGray}/>
                    <label className='InputContainerTopMessage'>{messageText}</label>
                </div> 
                <ul className='InputContainerContentWrapper'>
                    {content}
                </ul>
            </div>
           
        </>
    )
}

export default InputContainer;