import './BlueButton.css';
import googleService from '../../../services/GoogleService';

const BlueButton = ({
    text,
    onClick,
    isGray,
    isLarge,
    isCenter,
    isDark,
    isHidden,
    Name,
    color,
    logoIcon
}) => {

    let buttonClass = 'BlueButtonComponent';

    //buttons
    if (isLarge) buttonClass = 'BlueButtonComponent';
    if (isLarge && color === 'red') buttonClass = 'BlueButtonComponentRed';
    if (!isLarge && !isDark) buttonClass ='BlueButtonComponentSmall'
    if (isDark && !isLarge) buttonClass = 'DarkerBlueButtonComponent';
    if (isHidden) buttonClass ='BlueButtonComponentHidden';

    //gray display
    if (isGray && isLarge && !isHidden) buttonClass = 'BlueButtonComponentGray';
    if (isGray && !isLarge && !isHidden) buttonClass = 'BlueButtonComponentSmallGray';
    if (isGray && isDark && !isLarge && !isHidden) buttonClass = 'DarkerBlueButtonComponentGray';

    //is centered
    if (isCenter && !isLarge && !isHidden) buttonClass = 'BlueButtonComponentSmallIsBlock';
    if (isCenter && isDark && !isLarge && !isHidden) buttonClass = 'DarkerBlueButtonComponentIsBlock';
    
    //is centered and gray
    if (isCenter && isGray && !isLarge && !isHidden) buttonClass = 'BlueButtonComponentSmallGrayIsBlock';
    if (isCenter && isGray && isDark && !isLarge && !isHidden) buttonClass = 'DarkerBlueButtonComponentGrayIsBlock';
    
    const onClickAction = () => {
        onClick()
        googleService.event({
            category: 'ButtonClick',
            action: text,
            label: window.location.pathname + window.location.search
        })
    }

    if(isGray && !logoIcon) {
        return(
            <>
                <button className={buttonClass} name={Name}>{text}</button>
            </>
        )
    }
    if(!isGray && !logoIcon) {
        return(
            <>
                <button className={buttonClass} onClick={onClickAction} name={Name}>{text}</button>
            </>
        )
    }

    if(isGray && logoIcon) {
        return(
            <>
                <button className={buttonClass} name={Name}>{
                    <img src={logoIcon} className="BlueButtonComponentIcon" alt={'BlueButtonComponentIcon'+logoIcon} />
                }</button>
            </>
        )
    }
    if(!isGray && logoIcon) {
        return(
            <>
                <button className={buttonClass} onClick={onClickAction} name={Name}>{
                    <img src={logoIcon} className="BlueButtonComponentIcon" alt={'BlueButtonComponentIcon'+logoIcon} />
                }</button>
            </>
        )
    }
    
}

export default BlueButton;