import React, { useEffect, useState } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import BigSidebar from './displayComponent/BigSidebar.js';
import SmallSideBar from './displayComponent/SmallSideBar.js';
import GlobalAppContext from '../../../../globals/GlobalAppContext';
import './sideMenu.css';
import './sideMenuMobile.css';

const SideMenu = () => {

  // remove this once available for all
  const isAllowedToViewNewContent = GlobalAppContext.isAllowedToViewNewContent();
  
  let location = useLocation();
  let history = useHistory();

  const [sideContainer, setSideContainer] = useState({display: "block"});
  const [showSmallPanel, setShowSmallPanel] = useState(true)

  // detect mobile
  let isMobileDevice = window.innerWidth <= 878

  const menuToggle = (e) => {
    e.preventDefault();

    // show the sidebar
    if (sideContainer.display === 'none') setSideContainer({ display: 'block'});
    
    // hide the sidebar commpletly on mobile
    if (sideContainer.display === 'block' && isMobileDevice) setSideContainer({ display: 'none'});

    if (showSmallPanel && !isMobileDevice) setShowSmallPanel(false)
    if (!showSmallPanel && !isMobileDevice) setShowSmallPanel(true)
  };

  const menuToggleOnClickMobile = (e) => {
    e.preventDefault();

    // show the sidebar
    if (sideContainer.display === 'none') setSideContainer({ display: 'block'});
    
    // hide the sidebar commpletly on mobile
    if (sideContainer.display === 'block' && isMobileDevice) setSideContainer({ display: 'none'});
  }
  
  if (isMobileDevice && showSmallPanel === true) {
    setSideContainer({ display: 'none'})
    setShowSmallPanel(false)
  }

  useEffect(() => {
    
    // this allowes the sidebar to adjust on resizing 
    function handleResize() {
      
      if (window.innerWidth <= 878) {
        setSideContainer({ display: 'none'})
        setShowSmallPanel(false)
      }
      
      if (window.innerWidth >= 878){
        setSideContainer({ display: 'block'})
        setShowSmallPanel(true)
      }
    }
   
    window.addEventListener('resize', handleResize)

    return () => window.removeEventListener('resize', handleResize)
  })
 
  
  // big sideBar
  let displayCompontent = <BigSidebar
    menuToggle={menuToggle}
    sideContainer={sideContainer}
    menuToggleOnClickMobile={menuToggleOnClickMobile}
    history={history}
    location={location}
    isAllowedToViewNewContent={isAllowedToViewNewContent}
  />
  
  if(showSmallPanel && !isMobileDevice) {
    displayCompontent = <SmallSideBar 
      menuToggle={menuToggle}
      sideContainer={sideContainer}
      menuToggleOnClickMobile={menuToggleOnClickMobile}
      history={history}
      location={location}
      isAllowedToViewNewContent={isAllowedToViewNewContent}
    />
  }

  return displayCompontent
}

export default SideMenu;
