import googleService from '../../../services/GoogleService';
import './BlueButtonBackToTop.css';
import { useState, useEffect } from 'react';

const BlueButtonBackToTop = ({ 
    windowClass = 'scrollableContainer', 
    buttonShowThreshold = 500,
    left = false
}) => {
    
    const [isShowen, setIsShowen] = useState(false);
    const [container, setContainer] = useState(false);

    const onClickAction = () => {

        container.scrollTo({
            top: 0,
            left: 0,
            behavior: 'smooth'
        });

        googleService.event({
            category: 'ButtonClick',
            action: 'BACK TO TOP',
            label: window.location.pathname + window.location.search
        })
    }

    let buttonClass = 'BlueButtonBackToTop';
    let displayElement = <></>

    if (left) buttonClass = 'BlueButtonBackToTopLeft'

    useEffect(() => {

        setContainer(document.getElementsByClassName(windowClass)[0])
        
        if (container) {
   
            container.onscroll = () => {
             
                if (container.scrollTop > buttonShowThreshold) setIsShowen(true);
                else setIsShowen(false);
            };
        
        }
    },[container, buttonShowThreshold, windowClass])
    
    if (isShowen) {
        displayElement = <>
            <button className={buttonClass} onClick={onClickAction} name='BackToTopButton'>
                <img className='BlueButtonBackToTopArrow' src={'/settingsArrow.svg'} alt={'back'} />
            </button>
        </>
    }

    return displayElement
}

export default BlueButtonBackToTop;