import './broadcasterCardDisplay.css'

const BroadcasterCardDisplay = ({
    broadcasterObject
}) => {
    
    const broadcasterUrl = broadcasterObject ? broadcasterObject.broadcasterImageURL : '/imageNotFound.jpg';
    const broadcastername = broadcasterObject ? broadcasterObject.broadcasterUsername : 'Unkown';
    const mayApproveMemes = broadcasterObject ? broadcasterObject.approveDenyPermission ? 'Yes': 'No' : 'No'
    
    return <>
        <div className='broadcasterCardDisplayContainer'>
            <div className='broadcasterCardDisplayImageNameContainer'>
                <img src={broadcasterUrl} className="broadcasterCardDisplayImage" alt={broadcasterUrl}/>
                <p className='broadcasterCardDisplayName'>{broadcastername}</p>
            </div>
            
            <hr className='broadcasterCardDisplayHr'></hr>
            <div className='broadcasterCardDisplayPremissionsContainer'>
                <h2>Premissions</h2>
                <div className='broadcasterCardDisplayContainerApproveDeny'>
                    <h4>{"Approve and deny memes : " + mayApproveMemes}</h4>
                </div> 
            </div>
        </div>
    </>
 }
 
 export default BroadcasterCardDisplay;