import memeService from '../../services/MemeService';
import globalConstants from '../../globals/GlobalConstants';
import useWebSocket from 'react-use-websocket';
import Source from './Source';
import './source.css';
import { useEffect, useState } from 'react';
let audio = new Audio('/memeNotify.mp3')

const SourceContainer = () => {
  document.body.style = 'background: none;';
  const [isDisplaying, setIsDisplaying] = useState(false)
  const [keepAliveInterval, setKeepAliveInterval] = useState(null);
  const [hadSuccesfulConnection, setHadSuccesfulConnection] = useState(false)
  const [reconnectionAttemptsFirstConnection, setReconnectionAttemptsFirstConnection] = useState(0);
  const [retryOnErrorForWebSocket, setRetryOnErrorForWebSocket] = useState(true)
  const [memeObject, setMemeObject] = useState(null);
  const [memequeue, setMemequeue] = useState([]);
  const [userSettings, setUserSettings] = useState({
    browserSourceInterval: 10000,
    browserSourceNotifySound: 0,
    browserSourceTimeOut: 10000
  });

  const onShouldReconnect = (closeEvent) => {
    const firstConnectionRetryThreshold = 5;

    // Tries to establish a first connection for about 1 min
    if (!hadSuccesfulConnection && reconnectionAttemptsFirstConnection <= firstConnectionRetryThreshold) {
      setReconnectionAttemptsFirstConnection(reconnectionAttemptsFirstConnection + 1);
      console.warn(`Attempting to connect ${reconnectionAttemptsFirstConnection}/${firstConnectionRetryThreshold}`);
    }

    // Will kill reconnection
    if (!hadSuccesfulConnection && reconnectionAttemptsFirstConnection >= firstConnectionRetryThreshold ) {
      setRetryOnErrorForWebSocket(false);
      // clearing intervals to avoid unnecessary calls 
      clearInterval(keepAliveInterval);
      console.error(`Browser source could not establish a connection!`);

      return false
    }

    return true
  };

  const onOpenConnection = () => {
    console.log('connected');
    setHadSuccesfulConnection(true);
    setReconnectionAttemptsFirstConnection(0);
    setRetryOnErrorForWebSocket(true);
  };

  const onMsg = async (event) => {
    let eventData = JSON.parse(event.data);

    if (eventData.message === "DISPLAY_MEME") {
      setMemequeue([...memequeue, eventData.data])
    }
  }

  const webSocketOptions = {
    onOpen: onOpenConnection,
    onMessage: onMsg,
    onError: console.error,
    //Will attempt to reconnect on all close events
    shouldReconnect: onShouldReconnect,
    retryOnError: retryOnErrorForWebSocket,
    // retries to connect every 10 seconds for up to 2 days
    // if the first connection was successful
    reconnectInterval: 10000,
    reconnectAttempts: 17280
  }

  const { sendMessage } = useWebSocket(`${globalConstants.webSocketUrl}?source=${window.location.pathname.replace(/\/source\//g, '')}`, webSocketOptions);

  const sleep = (ms) => {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  useEffect(() => {
    const handleQueueUpdate = async () => {
      if (!memequeue.length || memeObject || isDisplaying) return 

      let playPromise = audio.play();

      if (playPromise !== undefined) {
        playPromise.then(() => audio.play());
        playPromise.catch(() => console.error('USER DID NOT INTERACT WITH PAGE!'));
      }
  
      // Shift pops the left element out of the queue in FIFO fashion
      // We dont set the memequeue again until the bottom of this method.
      let meme = memequeue.shift();
      setMemequeue(memequeue)
      setIsDisplaying(true)
      setMemeObject(meme);
      
      //display the image
      await sleep(userSettings.browserSourceInterval);
  
      await memeService.memeDisplayed(meme.id).catch(() => {
        console.error('Meme transition error!');
      });

      if (meme.settings) {
        setUserSettings(userSettings => userSettings !== meme.settings ? meme.settings : userSettings);
      } else console.error(meme,'settings missing!')

      setMemeObject(null);

      // Before we set the queue again, we sleep a couple of seconds
      await sleep(1500)
      setIsDisplaying(false)
    }
    handleQueueUpdate()
  }, [memequeue, memeObject, userSettings, isDisplaying])

  // This loads only on first render
  useEffect(()=> {
    const loadUserSettings = async () => {
      console.log('Getting settings')
      let response = await memeService.getSourceSettings(window.location.pathname.replace('/source/',''))
      .catch(() => console.error("Error loading settings"));
      
      if (response.ok) {
        let settings = await response.json();
        setUserSettings(settings);
      }    
    };

    loadUserSettings(); 
  }, [])

  useEffect(() => {
    if (!keepAliveInterval) {
      const interval = setInterval(() => sendMessage({message: "keep-alive"}), 60000 * 9);
      setKeepAliveInterval(interval);
    }

    return () => {
      clearInterval(keepAliveInterval);
    };
  }, [sendMessage, keepAliveInterval]);
  

  return(
    <div className='sourceContainer'>
      <Source
        memeObject={memeObject}
      />
    </div>
  )
}

export default SourceContainer;
