import './BlueInputFile.css'
const BlueInputFile = ({
    id,
    name,
    onChange,
    text,
    value
}) => {
    return(
        <div className='BlueInputFileWrap'>
            <div className="BlueInputFileContainer">
                <p className='BlueInputFileLabel'>{text}</p>
                <input className='BlueInputFile' type="file" id={id} name={name} accept="image/png, image/jpeg" onChange={onChange} value={value}/>
            </div>
        </div>
    )
}

export default BlueInputFile;