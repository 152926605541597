import memeService from '../../../../../services/MemeService';
import BlueButton from "../../../../common/BlueButton/BlueButton";

const ReportImageFrame = ({
    setMetaData,
    setIsReporting,
    metaData,
    setReasonValue,
    setIsReportedButtonOff,
    isReportedButtonOff,
    reasonValue
}) => {
    let content = <>
        <div className='ImageSelectorBackButtonContainer'>
            <button className='ImageSelectorBackButton' onClick={() => setMetaData(null)}>X</button>
        </div>
        <div className='reportFlagContainer' >
            <p className='reportFlag' onClick={() => setIsReporting(false)} >Back</p>
        </div>
        <h3 className='ImageSelectorTitle'>REPORT IMAGE</h3>
        <hr className='ImageSelectorLineBreak' />
        <br/>
        <img
            className='imageGridDisplay'
            src={metaData.thumbnailUrl}
            alt={metaData.title}
            onError={(e) => {
                e.target.onerror = null;
                e.target.src = '/imageNotFound.jpg';
                }
            }
        />
        <br/>
        <p className='reportText'>Do you want to report this image ?</p>
        <div className='reporterSpanAndSelectorContainer'>
            <span className='reportSpanText'>Reason : </span>
            <select className='reportSelector' name="reason" defaultValue='Inappropriate' onChange={e => setReasonValue(e.target.value)} >
                <option value="Inappropriate">Inappropriate</option>
                <option value="Racism">Racism</option>
                <option value="Pornography">Pornography</option>
            </select>
        </div>
        <br/>
        {isReportedButtonOff ? <p className='reportText'>Thanks for your report!</p> : <br/>}
        <BlueButton text="Report image" isCenter={true} isDark={true} isGray={isReportedButtonOff} onClick={async () => {
            setIsReportedButtonOff(true)
            await memeService.createOrUpdateReportImage(metaData.templateId, reasonValue, metaData.uploader, metaData.url)
        }
            
        }/>
    </>

    return content;
}

export default ReportImageFrame;