import ModeratorCardDisplay from './../ModerationCardDisplay';

const LoadModeratorDisplay = ({
    listOfAllModerators,
    isButtonDisabled,
    addModerator,
    updateModerator,
    deleteModerator,
    manualApprovalState
}) => {
    
    let arr = [];

    if (listOfAllModerators.length) listOfAllModerators.forEach((item, i) => {
        // A unique key must be provided for the react -
        // component to render each element correctly
        // do not use index since the value will change on removal

        arr.push((
            <ModeratorCardDisplay 
                moderatorObject={item}
                isButtonDisabled={isButtonDisabled}
                addModerator={addModerator}
                key={'moderatorDisplay' + item.id}
                updateModerator={updateModerator}
                deleteModerator={deleteModerator}
                manualApprovalState={manualApprovalState}
            />
        ));

    })

    const displayModerationLength = listOfAllModerators.length ? <p>{`Moderators [ ${listOfAllModerators.length} ]`}</p> : <></>
    const displayModeratorArray = <div className='moderationBroadcasterDisplayGlobal'>
        {arr}
    </div>

    return <>
        {displayModerationLength}
        {displayModeratorArray}
    </>
    
}
export default LoadModeratorDisplay;