import memeService from '../../../../../services/MemeService';
import errorHandler from '../../../../../services/ErrorHandler';
import BlueButton from '../../../../common/BlueButton/BlueButton';
import { useState } from 'react';
import InputBar from '../../../../common/InputBar';
import notificationService from '../../../../../services/NotificationService';
const Filter = (
  {
    id,
    filterText,
    setNumberOfDeletedItems,
    numberOfDeletedItems,
    animationLeft,
    animationRight,
    inputContainerFilterClass
  }
) => {
  const [filterInput, setFilterInput] = useState(filterText);
  const [filterStatusMessage, setFilterStatusMessage] = useState('');
  const [filterListItemClass, setfilterListItemClass] = useState('filterListItemVisible');
  const [onUserInputChange, setOnUserInputChange] = useState("");
  const [filterMessageClassName, setFilterMessageClassName] = useState('infoTextFilterList');
  const [deleteButtonIsGray, setDeleteButtonIsGray] = useState(false);
  const [updateButtonIsGray, setUpdateButtonIsGray] = useState(true);
  const [isWorking, setIsWorking] = useState(false);

  if(animationLeft && filterListItemClass === 'filterListItemVisible') setfilterListItemClass('filterListItemVisibleLeftAnimation')
  if(animationRight && filterListItemClass === 'filterListItemVisible') setfilterListItemClass('filterListItemVisibleRightAnimation')
  
  //detects changes and highlights the input feld
  const onUserInput = (value) => {
    setOnUserInputChange("update");
    setFilterInput(value);
    if (filterInput && updateButtonIsGray){
      setUpdateButtonIsGray(false);
    }
  
  };

  //error behavior
  const onErrorResponse = (object) => {
    notificationService.setMessage(` ${errorHandler.handleErrorCode(object)}`);
    setOnUserInputChange("error");
    setFilterMessageClassName('infoTextFilterListError');
    
    //reset UI
    setTimeout(()=> {
      setIsWorking(false);
      setFilterStatusMessage('');
    }, 4000);
  };
  
  const handelKeyPress = (event) => {
    if (!isWorking && filterInput) {
      if (event.key === 'Enter'){
        updateFilter()
      }
    }
  }

  const updateFilter = async () => {
    if(!isWorking && filterInput){
      
      //set button off
      setUpdateButtonIsGray(false);
      setIsWorking(true);
      setFilterMessageClassName('infoTextFilterListUpdate');
      setFilterStatusMessage(' Updating...');
      
      //call api
      let filterUpdateResponse = await memeService.updateProfanityFilter(id, filterInput);
      
      if (!filterUpdateResponse.id) onErrorResponse(filterUpdateResponse);
      
      if (filterUpdateResponse.id && filterUpdateResponse.filterText) {
        
        //show updated state to user
        setFilterStatusMessage('Filter Updated!');
        setFilterMessageClassName('infoTextFilterListSucceeded');
        setOnUserInputChange("succeded");
        
        //reset button & UI
        setTimeout(() => {
          setFilterStatusMessage('');
          setIsWorking(false);
          setUpdateButtonIsGray(false);
          setFilterMessageClassName('infoTextFilterList');
          setOnUserInputChange("");
        }, 4000);

      } else { 
        
        //reset button
        setUpdateButtonIsGray(false);
        onErrorResponse(filterUpdateResponse); 
      }
    } 
  
  };

  const deleteFilter = async () => {
    
    if(!isWorking) {
      
      //set button off
      setDeleteButtonIsGray(true);
      setIsWorking(true);
      setFilterMessageClassName('infoTextFilterListDelete');
      setFilterStatusMessage(' Deleting...');
      setNumberOfDeletedItems(numberOfDeletedItems + 1);
      //call api
      let filterDeleteResponse = await memeService.deleteProfanityFilter(id);
      if (filterDeleteResponse.status === 200) {
        setIsWorking(false);
        
        //hide the element 
        setfilterListItemClass('filterListItemNotVisible');
      } else { 
        
        //reset button
        setDeleteButtonIsGray(false);
        setNumberOfDeletedItems(numberOfDeletedItems - 1);
        onErrorResponse(filterDeleteResponse); 
      }
    } 
   
  };

  return (
    <li className={ filterListItemClass }>
      <div className='filterContentContainer'>
        <div className={inputContainerFilterClass}>
          <InputBar value={filterInput} onInput={e => onUserInput(e.target.value)} state={onUserInputChange} onKeyPress={handelKeyPress}/>
        </div>
        <div className='FilterContentButtonContainer'>
          <div className='FilterContentButtonContainerBlueButtonSmall'>
            <BlueButton text='Update' onClick={updateFilter} isGray={updateButtonIsGray}/>
          </div>
          <div className='FilterContentButtonContainerDarkerBlueButtonSmall'>
            <BlueButton text='Delete' onClick={deleteFilter} isDark={true} isGray={deleteButtonIsGray} />
          </div>
        </div> 
        <label className={filterMessageClassName}>{filterStatusMessage}</label>
      </div>
    </li>
  )
};

export default Filter;
