import Socket from '../../../services/WebsocketService';
import ManualApprovalLogic from './manualApprovalLogic';
import { useState } from 'react';

const MemeApproval = ({ 
    manualApprovalState,  
    setMemesThatNeedApproval, 
    setManualApprovalState,
    currentMeme,
    browserSourceIsActive,
    applicationDisplayLocation,
}) => {
    const [remotelyApprovedby, setRemotelyApprovedby] = useState(null);
    const [isApiCallInProgress, setIsApiCallInProgress] = useState(false);
    const [isBlueToggleClickable, setIsBlueToggleClickable] = useState(true);

    const deleteMemesThatNeedApproval = (meme, removeBy = 'id') => {
        if (meme && meme.id && removeBy === 'id') {
            setMemesThatNeedApproval(currentArr => currentArr.filter((f) => f.id !== meme.id))
        }

        if (meme && meme.url && removeBy === 'url') {
            setMemesThatNeedApproval(currentArr => currentArr.filter((f) => f.url !== meme.url))
        }
    }

    const pushMemesThatNeedApproval = (meme) => {
        if(meme && meme.id) {
            setMemesThatNeedApproval((currentArr) => {
                if (!currentArr.includes(meme)) {
                    return [...currentArr, meme]
                } else {
                    return currentArr
                }
            })
        }
    }

    
    // logic on incoming msg
    const messageHandler = async (event) => {
        const eventData = JSON.parse(event.data);
        const memeElement = eventData.data;

        if (eventData.message === 'PENDING_APPROVAL') pushMemesThatNeedApproval(memeElement);
     
        // We want to make sure we dont see memes rapidly disappear if
        // they are being approved quickly because they all had a setTimeout
        // Instead if we dont have a timer running, we will remove them immediately.
        if (eventData.message === 'REMOVE_PENDING_APPROVAL_MEME') {
            
            if (!remotelyApprovedby && !isApiCallInProgress) {
                deleteMemesThatNeedApproval(memeElement, 'url')
                setRemotelyApprovedby(memeElement)
        
                setTimeout(() => setRemotelyApprovedby(null), 3000)
        
            } else {
                deleteMemesThatNeedApproval(memeElement, 'url')
            }
        }
    }
    
    // Establish websocket connection
    const queryStringParams = 'broadcasterManagementConnection=true'
    Socket.useWebsocketService({ queryStringParams, messageHandler })

    return <ManualApprovalLogic 
        setManualApprovalState={setManualApprovalState}
        manualApprovalState={manualApprovalState}
        deleteMemesThatNeedApproval={deleteMemesThatNeedApproval}
        currentMeme={currentMeme}
        browserSourceIsActive={browserSourceIsActive}
        remotelyApprovedby={remotelyApprovedby}
        applicationDisplayLocation={applicationDisplayLocation}
        setIsApiCallInProgress={setIsApiCallInProgress}
        isApiCallInProgress={isApiCallInProgress}
        isBlueToggleClickable={isBlueToggleClickable}
        setIsBlueToggleClickable={setIsBlueToggleClickable} 
    />;
}

export default MemeApproval;
