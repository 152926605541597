import { Switch } from 'react-router-dom';
import TopMenu from '../Menus/TopMenu';
import RouteWithSubRoutes from '../../Routes/RouteWithSubRoutes';
import GlobalAppContext from '../../../globals/GlobalAppContext';

const Moderation = ({ routes, title, path }) => {

    // remove this once available for all
    const isAllowedToViewNewContent = GlobalAppContext.isAllowedToViewNewContent();

    let displayComponent = <>
        <TopMenu
            routes={routes}
            title={title}
            baseRoute={path}
        />

        <Switch>
            {routes.map((route, i) => (
            <RouteWithSubRoutes key={i} {...route} />
            ))}
        </Switch>
    </>

    // remove this once available for all
    if (!isAllowedToViewNewContent) displayComponent = <h1>Access denied!</h1>

    return displayComponent;
};

export default Moderation;