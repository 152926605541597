const BigSidebar = ({
    menuToggle,
    sideContainer,
    menuToggleOnClickMobile,
    history,
    location,
    isAllowedToViewNewContent,
}) => {

    // adjust this once available for all
    const displayComponentModerator = isAllowedToViewNewContent ? <>
      <li 
        className={location.pathname.includes('/dashboard/moderation') ? 'MenuActive' : 'MenuActiveNo'}
        onClick={(e) => { menuToggleOnClickMobile(e); history.push('/dashboard/moderation') }}
      >
        <img src='/swordMod.svg' alt="moderation"
              onClick={(e) => { menuToggleOnClickMobile(e); history.push('/dashboard/moderation') }}
              className='iconSizeMenu'
            />
        <span
          className={location.pathname.includes('/dashboard/moderation') ? 'activeLiSide' : 'notActiveSide'}
        >
          Moderation
        </span>
      </li>
    </> : <></>

    return (<>
    <div className="buttonContainerSidbar">
      <div className="menuButton" onClick={menuToggle}>
        <img src='/settingsIcon.svg' alt="settings" className='sideBarSettingsIcon' />
      </div>
    </div>
    <div style={sideContainer} className='sideContainerParent'>
      <ul className="sideNavbarText">

        <h3 className="sideNavbarBranding">STREAM  <span className="App-domain2">MEMES</span></h3>
        <img src='/logo.svg' className="sideNavbarImage" alt="logo" />
        <div className="sideNavbarTextContainer">
          <li 
            className={location.pathname.endsWith('/dashboard') ? 'MenuActive' : 'MenuActiveNo'}
            onClick={(e) => { menuToggleOnClickMobile(e); history.push('/dashboard') }}
          >
              <img src='/apps.svg' alt="apps"
                  onClick={(e) => { menuToggleOnClickMobile(e); history.push('/dashboard') }}
                  className='iconSizeMenu'
                />
              <span
                className={location.pathname.endsWith('/dashboard') ? 'activeLiSide' : 'notActiveSide'}
              >
                Dashboard
              </span>
            </li>
            <li 
              className={location.pathname.includes('/dashboard/templates') ? 'MenuActive' : 'MenuActiveNo'}
              onClick={(e) => { menuToggleOnClickMobile(e); history.push('/dashboard/templates') }}
            >
              <img src='/book-alt.svg' alt="book"
                    onClick={(e) => { menuToggleOnClickMobile(e); history.push('/dashboard/templates') }}
                    className='iconSizeMenu'
                  />
              <span
                className={location.pathname.includes('/dashboard/templates') ? 'activeLiSide' : 'notActiveSide'}
              >
                Meme Template Library
              </span>
            </li>
            <li 
              className={location.pathname.includes('/dashboard/filters') ? 'MenuActive' : 'MenuActiveNo'}
              onClick={(e) => { menuToggleOnClickMobile(e); history.push('/dashboard/filters') }}
            >
              <img src='/document.svg' alt="filter"
                    onClick={(e) => { menuToggleOnClickMobile(e); history.push('/dashboard/filters') }}
                    className='iconSizeMenu'
                  />
              <span
                className={location.pathname.includes('/dashboard/filters') ? 'activeLiSide' : 'notActiveSide'}
              >
                Profanity Filters
              </span>
            </li>
            {displayComponentModerator}
            <li 
              className={location.pathname.includes('/dashboard/settings') ? 'MenuActive' : 'MenuActiveNo'}
              onClick={(e) => { menuToggleOnClickMobile(e); history.push('/dashboard/settings') }}
            >
              <img src='/flower.svg' alt="book"
                    onClick={(e) => { menuToggleOnClickMobile(e); history.push('/dashboard/settings') }}
                    className='iconSizeMenu'
                  />
              <span
                className={location.pathname.includes('/dashboard/settings') ? 'activeLiSide' : 'notActiveSide'}
              >
                Settings
              </span>
            </li>
            <li 
              className={location.pathname.includes('/dashboard/help') ? 'MenuActive' : 'MenuActiveNo'}
              onClick={(e) => { menuToggleOnClickMobile(e); history.push('/dashboard/help') }}
            >
              <img src='/interrogation.svg' alt="help"
                    onClick={(e) => { menuToggleOnClickMobile(e); history.push('/dashboard/help') }}
                    className='iconSizeMenu'
                  />
              <span
                className={location.pathname.includes('/dashboard/help') ? 'activeLiSide' : 'notActiveSide'}
              >
                Help
              </span>
            </li>
            <li 
              className={location.pathname.includes('/dashboard/Logout') ? 'MenuActive' : 'MenuActiveNo'}
              onClick={() => { history.push('/') }}
            >
              <img alt='logout' src='/logoutButton.png' id='logoutButtonSideMenu'
                    onClick={() => history.push('/')}
                    className='iconSizeMenu'
                  />
              <span
                className={location.pathname.includes('/dashboard/loggout') ? 'activeLiSide' : 'notActiveSide'}
              >
                Logout
              </span>
            </li>
          </div>
        </ul>
    </div>
  </>)
}

export default BigSidebar