import './topDisplayLeft.css';

const TopDisplayLeft = ({
    loginLink, 
}) => {
    return <>
        <div className='displayComponentHomePageLeftContainerTop'>

            <div className='displayComponentHomePageLeftimageContainer'>
                <img src="logo.svg" className="displayComponentHomePageLogo" alt="logo" />
            </div>

            <div className='displayComponentHomePageBadningContainer'>
                <span className="displayComponentHomePageBranding1">STREAM  <span className="displayComponentHomePageBranding2">MEMES</span></span>
            </div>

            <div className='displayComponentHomePageTextTopContainer'>
                <p className='displayComponentHomePageTextTop' >
                    Say goodbye to repetitive content and hello to fresh, timely memes generated by your community.
                </p>
            </div>

            <div className='displayComponentHomePageButtonContainerTop'>
                <a className='displayComponentHomePageButtonLogin' href={loginLink}>
                <button className="displayComponentHomePageButton" >Streamer login</button>
                </a>

                {/* <a href='https://' target='_blank' rel="noreferrer">
                <button className="displayComponentHomePageButtonIcon" >
                    <img src="discord.svg" className="displayComponentHomePageIconDisplay" alt="discord"/>
                </button>
                </a> */}

                <a href='https://twitter.com/StreamMemesApp' target='_blank' rel="noreferrer">
                <button className="displayComponentHomePageButtonIcon" >
                    <img src="twitter.svg" className="displayComponentHomePageIconDisplay" alt="twitter"/>
                </button>
                </a>
            </div>

        </div>
    </>
}

export default TopDisplayLeft;