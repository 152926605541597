import BlueButton from '../BlueButton/BlueButton';
import notificationService from '../../../services/NotificationService';
import Container from '../Container';
import './notificationModal.css';
import { useEffect, useState } from 'react';

const NotificationModal = () => {
    const [message, setMessage] = useState(null); 
    const [title, setTitle] = useState(null);
    const [onClickFunction, setOnClickFunction] = useState(null);
    const [buttonText, setButtonText] = useState("Okay");
    const [imageUrl, setImageUrl] = useState(null);
    const [onClickFunction2, setOnClickFunction2] = useState(null);
    const [buttonText2, setButtonText2] = useState("Okay");
    const [buttonGray, setButtonGray] = useState(false);
    const [buttonGray2, setButtonGray2] = useState(false);

    useEffect(() => {
        notificationService._registerSetMessageFunction((newMessage) => {
            setMessage(newMessage);
        });
        notificationService._registerSetTitleFunction((newTitle) => {
            setTitle(newTitle);
        });
        notificationService._registerSetButtonText((newText) => {
            setButtonText(newText);
        });
        notificationService._registerSetButtonText2((newText) => {
            setButtonText2(newText);
        });
        notificationService._registerSetImageUrl((url) => {
            setImageUrl(url);
        });
        notificationService._registerSetButtonGray((value) => {
            setButtonGray(value);
        });
        notificationService._registerSetButtonGray2((value) => {
            setButtonGray2(value);
        });
        notificationService._registerSetOnClickFunction((newOnClickFunction) => {
            if(newOnClickFunction) setOnClickFunction(() => () => {newOnClickFunction()});
            else setOnClickFunction(null)
        });
        notificationService._registerSetOnClickFunction2((newOnClickFunction) => {
            if (newOnClickFunction) setOnClickFunction2(() => () => {newOnClickFunction()});
            else setOnClickFunction2(null)
            
        });
    }, [])

    let buttonTriggerDarkButton = false
    let buttonTriggerDarkButton2 = false
    let buttonIsCenterDisplay = true
    let imageDisplay = <></>
    
    // change color based on button text
    if (buttonText) {
        if (buttonText.toLocaleLowerCase() === 'delete') buttonTriggerDarkButton = true
        if (buttonText.toLocaleLowerCase() === 'remove') buttonTriggerDarkButton = true
    }

    if (buttonText2) {
        if (buttonText2.toLocaleLowerCase() === 'delete') buttonTriggerDarkButton2 = true
        if (buttonText2.toLocaleLowerCase() === 'remove') buttonTriggerDarkButton2 = true
    }
    
    // sets button self centering off when both are active
    if (buttonText && buttonText2) buttonIsCenterDisplay = false

    // if image url is provided we display the image 
    if (imageUrl) imageDisplay = <img
        className='notificationImageLoaderImageDisplay'
        src={imageUrl}
        alt={'Notification'}
        onError={(e) => {
                e.target.onerror = null;
                e.target.src = '/imageNotFound.jpg';
            }
        }
    />

    let displayComponent = <></>;
    let contentDisplay = <></>;
   
    const clearXButton = () => {
       
        const animationduration = 100
        const notifcationContainerAnimation = document.getElementById('notifcationContainerAnimation');
        if (notifcationContainerAnimation) notifcationContainerAnimation.animate(
            [
                { transform: 'scale(1)' },
                { transform: 'scale(0,1)' }
            ], {
                duration: animationduration,
                once: true
            }
        )

        setTimeout(() => {
            notificationService.clearMessage()
            setMessage(null);
            setTitle(null);
            setOnClickFunction(null);
            setButtonText("Okay");
            setOnClickFunction2(null);
            setButtonText2("Okay");
            setButtonGray(null);
            setButtonGray2(null)
            setImageUrl(null);
        }, animationduration)
    };
    
    if (message) {

        displayComponent = <>
            <div className='notificationModalBackButtonContainer'>
                <button className='notificationModalBackButton' onClick={clearXButton}>X</button>
            </div>
            <img className='notificationModalLogoDude' src='/logo.svg' alt='logo' />
            <h3 className='notificationModalTitle'>{title ? title : 'Oh no!'}</h3>
            <hr className='notificationModalLineBreak' />
            <br/>
            <div className='notificationModalImageContainer'>
                {imageDisplay}
            </div>
            <div className='notificationModalMessageContent'>
                <p>{message}</p>
            </div>
            <br/>
            <div className='notificationModalButtonContainer'>
                <div className='notificationModalLeftButton'>
                    {onClickFunction ? <BlueButton text={buttonText} onClick={onClickFunction} isCenter={buttonIsCenterDisplay} isDark={buttonTriggerDarkButton} isGray={buttonGray}/> : <></>}
                </div>
                <div className='notificationModalRightButton'>
                    {onClickFunction2 ? <BlueButton text={buttonText2} onClick={onClickFunction2} isCenter={buttonIsCenterDisplay} isDark={buttonTriggerDarkButton2} isGray={buttonGray2}/> : <></>}
                </div>
                   
            </div>
        </>

        contentDisplay = <>
            <div className='notificationModalOverLayContainerWrapper' id='notificationModalOverLayContainerWrapper'>
                <div className='notificationContainerContent' id='notificationContainerContent'>
                    <div className='notifcationContainerAnimation' id='notifcationContainerAnimation'>
                        <Container content={displayComponent}  maxWidth='20em' width='20em' />
                    </div>
                </div>
            </div>
        </>

    }

    return contentDisplay
}

export default NotificationModal;