import BroadcasterCardDisplay from './../BroadcasterCardDisplay';
import Cookies from 'universal-cookie';

const LoadBroadcasterDisplay = ({
    listOfAllBroadcasters
}) => {
    const cookies = new Cookies();
    let hasCurrentUser = false;
    let arr = [];

    if (listOfAllBroadcasters.length) {
        
        listOfAllBroadcasters.forEach((item, i) => {
            // A unique key must be provided for the react -
            // component to render each element correctly
            // do not use index since the value will change on removal
            const broadcasterUsername = item.broadcasterUsername ? item.broadcasterUsername : 'unknown'
            const currentUser = cookies.get('twitch_user') ? cookies.get('twitch_user').toUpperCase() : 'unknown'
            
            if (currentUser.toUpperCase() !== broadcasterUsername.toUpperCase() ) arr.push((
                <BroadcasterCardDisplay 
                    key={'broadcasterDisplayModeration ' + item.broadcasterUsername} 
                    broadcasterObject={item}
                />
            ));
            else hasCurrentUser = true

        })
    }

    const displayBroadcasterLength = listOfAllBroadcasters.length ? <p>{`Channels you moderate [ ${hasCurrentUser ? listOfAllBroadcasters.length - 1 : listOfAllBroadcasters.length} ]`}</p> : <></>

    return <>
        {displayBroadcasterLength}
        <div className='broadcasterModeratorDisplayGlobal'>
            {arr}
        </div>
    </>
    
}
export default LoadBroadcasterDisplay;