
import './youtubeEmbed.css'
import Container from "../../../../common/Container";
import googleService from '../../../../../services/GoogleService';

const EmbededYoutubeLogic = ({ 
    embedId,
    title
}) => {
    
    const onHover = (e) => {
        // Iframes do not support click events
        // Doesn't work on touch devices

        // Before we go and import tons of libraries that allow to caputre the
        // iframe click event we just listen for mouse over events instead.
        // This doesn't mean the user has watched given content but we know they saw it

        googleService.event({
            category: `title_MouseOver`,
            action: `videoID: ${embedId}`,
            label: window.location.pathname + window.location.search
        })
    }

    let content = <div className="videoContainer" onMouseEnter={onHover}>
        <iframe className='youtubeVideo' 
        src={`https://www.youtube.com/embed/${embedId}`}
        frameBorder="0"
        allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
        title="Embedded youtube"
        />
    </div>

    return <Container content={content} isNoBackground={true} title={title}/>   
}

export default EmbededYoutubeLogic;