module.exports = {
  DEFAULT_ERROR: 'BAD ERROR',
  CATCH_ERROR: 'SORRY!',
  NO_STATUS_CODE: 'No ErrorCode found!',
  NO_BODY_CODE: 'Server timeout!',
  BROADCASTER_NOT_FOUND: 'USER NOT FOUND',
  INTERNAL_SERVER_ERROR: 'Oohps!',
  FILTER_NOT_FOUND: "Couldn't find filter text",
  INVALID_TEMPLATE: "The selected Template is invalid!",
  INVALID_FILTER_TEXT: 'Invalid filter text',
  MEME_TEXT_NOT_FOUND: 'No Meme text was found!',
  VIEWER_ID_NOT_FOUND: 'No viewer ID was found!',
  TEMPLATE_ID_NOT_FOUND: "No template ID was found!",
  PRICE_NOT_FOUND: "Couldn't find a price!",
  BAD_REQUEST: 'Malformed request!',
  HEARTBEAT_DOES_NOT_EXIST: 'Your Browser source is not Active!',
  BROWSER_SOURCE_DOES_NOT_EXIST: "The Browser source dosen't exist!",
  BROWSER_SOURCE_NOT_ACTIVE: 'Your Browser source is not Active!',
  SESSION_EXPIRED: "You've been signed out please sign in again to continue",
  SERVER_NOT_REACHABLE: "Sorry! Something went wrong",
  INVALID_INPUT: "Your Input is invalid!",
};
