import ModeratorListDisplay from "./ModerationListDisplay";
import memeService from '../../../../services/MemeService';
import notificationService from '../../../../services/NotificationService';
import LoadingDisplay from '../../../common/LoadingDisplay/LoadingDisplay';
import errorHandler from '../../../../services/ErrorHandler';
import React, { useState, useEffect, useCallback } from 'react';

const ModeratorList = () => {

    const [isMounted, setIsMounted] = useState(false);
    const [isLoading, setIsloading] = useState(false);
    
    const [listOfAllModerators, setListOfAllModerators] = useState([]);
    const [listOfAllBroadcasters, setListOfAllBroadcasters] = useState([]);
    const [isButtonDisabled, setIsButtonDisabled] = useState(false);

    const [newModeratorInput, setNewModeratorInput] = useState('');
    const [inputDisplayMessage, setInputDisplayMessage] = useState('');

    const [manualApprovalState, setManualApprovalState] = useState(false);

    const loadBroadcasterModerators = async (state) => {

      let templateAccumulationArray = [];
      let paginationToken = null;
  
      try {
   
        do {
            let responseObject = state === 'moderator' 
            ? await memeService.listAllBroadcasterModerators(paginationToken) 
            : await memeService.listAllModeratorChannels(paginationToken)
                
            if (state === 'moderator' ) responseObject.moderators
            .forEach((moderator) => templateAccumulationArray.push(moderator));
            
            if (state === 'broadcaster' ) responseObject.broadcasters
            .forEach((broadcaster) => templateAccumulationArray.push(broadcaster));
            
            paginationToken = responseObject.paginationToken;
        } while (paginationToken);
        //console.log('--- getting list ---', state,templateAccumulationArray)
        return templateAccumulationArray;
  
      } catch (error) {
        notificationService.setMessage(`Error loading list of ${state}`);
        console.log(error)
      }
    }

    const loadUserSettings = async () => {
      
        try {
            let settings = await memeService.getBroadcasterSettings()
            return settings.manualApproval
        } catch (e) {
          notificationService.setMessage("I can not load your settings!");
          console.log(e);
        }
    }

    const loadPage = useCallback(async () => {
        setIsloading(true);

        const finishedApiCalls = await Promise.all([
            loadBroadcasterModerators('moderator'),
            loadBroadcasterModerators('broadcaster'),
            loadUserSettings()
        ]);

        if (finishedApiCalls) {
            setListOfAllModerators(finishedApiCalls[0])
            setListOfAllBroadcasters(finishedApiCalls[1])
            setManualApprovalState(finishedApiCalls[2])
        }

    },[]);

    // add moderator
    const addModerator = async () => {
        if (!newModeratorInput) return
        
        if (newModeratorInput.length > 25) {
            setInputDisplayMessage('Moderator name too long!');
            setNewModeratorInput('')
            setTimeout(() => setInputDisplayMessage(''), 2000);

            return
        }

        setIsButtonDisabled(true);
        setInputDisplayMessage('Adding new moderator ...');
        let created = null 

        try {
            created = await memeService.createModerator(newModeratorInput, true);
            
            if (created.errorCode === 'MODERATOR_ALREADY_EXISTS') {
                setInputDisplayMessage('Moderator already added!');
                setNewModeratorInput('')
                setTimeout(() => setInputDisplayMessage(''), 2000);
            }
            
            if (created.moderator.id) {
                setInputDisplayMessage('New moderator added!');
                setListOfAllModerators(listOfAllModerators => [created.moderator, ...listOfAllModerators]);
                setNewModeratorInput('')
                setTimeout(() => setInputDisplayMessage(''), 2000);
            } 

        } catch (error){
            
            if (created) {
                if (created.errorCode === 'USER_NAME_NOT_FOUND') {
                    setInputDisplayMessage('Moderator does not exist!');
                    setNewModeratorInput('')
                    setTimeout(() => setInputDisplayMessage(''), 2000);
                }
            } else {
                notificationService.setMessage(errorHandler.handleErrorCode(error));
                setNewModeratorInput('');
                setInputDisplayMessage('');
                console.log(error);
            }
        }

        setIsButtonDisabled(false);

    }

    const handelKeyPressAddingModerator = (event) => {
        if (event.key === 'Enter') {
            addModerator()
        }
    }

    // update moderator
    const updateModerator = async (moderator, state) => {
        if (!moderator) notificationService.setMessage("Unable to update moderator!");

        try {
            const updated = await memeService.updateModerator(moderator.id, state);
            if (!updated.moderator.id) notificationService.setMessage("Unable to update moderator!");
            return updated.moderator;
        } catch (error) {
            notificationService.setMessage(errorHandler.handleErrorCode(error));
            console.log(error)
        }

    }

    // delete moderator
    const deleteModerator = async (id) => {
        if (!id) notificationService.setMessage("Unable to delete moderator!");

        try {

            setListOfAllModerators(listOfAllModerators => listOfAllModerators
                .filter((f) => f.id !== id)
            );

            await memeService.deleteModerator(id);

        } catch (error) {
            notificationService.setMessage(errorHandler.handleErrorCode(error));
            console.log(error)
        }
        
    }

    // manual approval toggle
    const approvalToggleLogic = async (state) => {
        if (isButtonDisabled) return;
        
        try {
            setIsButtonDisabled(true);
            setManualApprovalState(manualApprovalState ? false : true);
            
            if (state) await memeService.updateBroadcasterSettingsManualApproval(true)
            else await memeService.updateBroadcasterSettingsManualApproval(false)

            setIsButtonDisabled(false);
        } catch (error) {
            notificationService.setMessage(errorHandler.handleErrorCode(error));
            console.log(error)
            setIsButtonDisabled(false);
        }
        
    }

    useEffect(() => {
        setIsMounted(true);
        
        if (isMounted) {
            loadPage().then(() => setIsloading(false))  
        }   

        //fixing memory leak
        return () => setIsMounted(false);
    }, [isMounted, loadPage])

    return isLoading ? <LoadingDisplay/> : <ModeratorListDisplay 
        isButtonDisabled={isButtonDisabled}
        handelKeyPressAddingModerator={handelKeyPressAddingModerator}
        newModeratorInput={newModeratorInput}
        setNewModeratorInput={setNewModeratorInput}
        inputDisplayMessage={inputDisplayMessage}
        listOfAllModerators={listOfAllModerators}
        listOfAllBroadcasters={listOfAllBroadcasters}
        addModerator={addModerator}
        updateModerator={updateModerator}
        deleteModerator={deleteModerator}
        manualApprovalState={manualApprovalState}
        approvalToggleLogic={approvalToggleLogic}
    />
}

export default ModeratorList;