import './carousel.css';
import ContentTextContainer from '../ContentTextContainer';

const Carousel = ({
    item,
    currentlyDisplayedIndex,
    noItemWasFoundText,
    noMemeDescriptionText,
    setCurrentlyDisplayedIndex,
    textBelowCarousel,
    OnClickFocusImage,
    OnClickFirstImageNotInFocuseLeft,
    OnClickFirstImageNotInFocuseRight,
    OnClickFirstImageNotInFocuseLeftSecond,
    OnClickFirstImageNotInFocuseRightSecond
}) => {
   
    // if there are no functions set we disable them
    if (!OnClickFocusImage) OnClickFocusImage = () => { return }
    if (!OnClickFirstImageNotInFocuseLeft) OnClickFirstImageNotInFocuseLeft = () => { setCurrentlyDisplayedIndex(currentlyDisplayedIndex - 1) }
    if (!OnClickFirstImageNotInFocuseRight) OnClickFirstImageNotInFocuseRight = () => { setCurrentlyDisplayedIndex(currentlyDisplayedIndex + 1) }
    if (!OnClickFirstImageNotInFocuseLeftSecond) OnClickFirstImageNotInFocuseLeftSecond = () => { setCurrentlyDisplayedIndex(currentlyDisplayedIndex - 2) }
    if (!OnClickFirstImageNotInFocuseRightSecond) OnClickFirstImageNotInFocuseRightSecond = () => { setCurrentlyDisplayedIndex(currentlyDisplayedIndex + 2) }

    let noImageFoundResponse = <>
        <ContentTextContainer title={noItemWasFoundText} text={<span>{noMemeDescriptionText}</span>} alignLeft={true}/>
    </>

    let image = item[currentlyDisplayedIndex]
    let imageBefore = item[currentlyDisplayedIndex - 1]
    let imageAfter = item[currentlyDisplayedIndex + 1]
    let imageBefore2 = item[currentlyDisplayedIndex - 2]
    let imageAfter2 = item[currentlyDisplayedIndex + 2]

    if (!image) return noImageFoundResponse

    const lookUpAndChangeArrayIndex = (image, position) => {
        let imageBack = image[currentlyDisplayedIndex - 1]
        let imageForward = image[currentlyDisplayedIndex + 1]
        if (imageBack && position === 'back') setCurrentlyDisplayedIndex(currentlyDisplayedIndex - 1)
        if (imageForward && position === 'forward') setCurrentlyDisplayedIndex(currentlyDisplayedIndex + 1)
    } 

    const imageToDisplay = (image, position) => {
        if (!image) return <></>
        let onclickFunction = OnClickFocusImage

        let classStyle = 'middleSectionCarouselImageLoaderImageDisplay'

        if (position === 'left') {
            classStyle = 'middleSectionCarouselImageLoaderImageDisplayLeft'
            onclickFunction = OnClickFirstImageNotInFocuseLeft
        }
        if (position === 'right') {
            classStyle = 'middleSectionCarouselImageLoaderImageDisplayRight'
            onclickFunction = OnClickFirstImageNotInFocuseRight
        }
        if (position === 'right2') {
            classStyle = 'middleSectionCarouselImageLoaderImageDisplayRight2'
            onclickFunction = OnClickFirstImageNotInFocuseRightSecond
        }
        if (position === 'left2') {
            classStyle = 'middleSectionCarouselImageLoaderImageDisplayLeft2'
            onclickFunction = OnClickFirstImageNotInFocuseLeftSecond
        }
       
        return <img
            className={classStyle}
            src={image.thumbnailUrl ? image.thumbnailUrl : image.url ? image.url : '/imageNotFound.jpg'}
            alt={image.price ? image.price : 'carouselImage'}
            onLoad = {() => {
                classStyle = 'middleSectionCarouselImageLoaderImageDisplayNone'
            }}
            onClick={onclickFunction}
            onError={(e) => {
                    e.target.onerror = null;
                    e.target.src = '/imageNotFound.jpg';
                }
            }
        /> 
    };

    let carouselDisplay = (image, imageBefore, imageAfter, imageBefore2, imageAfter2, item, indexButtons) => {
        return <>
            <div className='middleSectionGlobalCaruselContainer'>
                <div className="middleSectionCarouselContainer">
                    <img className="sideArrowLeftCarousel" src={'settingsArrow.svg'} alt={'back'} onClick={()=> {lookUpAndChangeArrayIndex(item, 'back')}} />
                    <div className="middleSectionCarouselLeftContainer">
                        <div className="middleSectionCarouselsLeftContainer2">
                            {imageToDisplay(imageBefore2, 'left2')}
                        </div>
                        {imageToDisplay(imageBefore, 'left')}
                    </div>
                    <div className="middleSectionCarousel">
                        {imageToDisplay(image)}
                    </div>
                    <div className="middleSectionCarouselRightContainer">
                        {imageToDisplay(imageAfter, 'right')}
                        <div className="middleSectionCarouselRightContainer2">
                            {imageToDisplay(imageAfter2, 'right2')}
                        </div>
                    </div>
                    <img className="sideArrowRightCarousel" src={'settingsArrow.svg'} alt={'forward'} onClick={()=> {lookUpAndChangeArrayIndex(item, 'forward')}} />
                </div>

                {<div className="buttonMiddleSectionCarouselContainer">{indexButtons}</div>}
                <div className="middleSectionCarouselText">
                    <p>{textBelowCarousel}</p>
                </div>
            </div>
            
        </> 
    }

    const createButtonDots = (index) => {
        let indexButtons = []

        for (let i = 0; i < index; i++) {
            const element = i;
            if (currentlyDisplayedIndex === i) indexButtons.push(<button key={i} className="buttonMiddleSectionCarouselColor"></button>)
            if (currentlyDisplayedIndex !== i) indexButtons.push(<button key={i} className="buttonMiddleSectionCarousel" onClick={()=> setCurrentlyDisplayedIndex(element)}></button>)    
        }
        return indexButtons
    }

    const createButtonDotsAndCaruselDisplay = (image, imageBefore, imageAfter, imageBefore2, imageAfter2, item) => {
        // creating clickable dots based on item length
        let indexButtons = createButtonDots(item.length)
        
        // returne the build carousel
        return carouselDisplay(image, imageBefore, imageAfter, imageBefore2, imageAfter2, item, indexButtons)
    }

    let displayComponent = createButtonDotsAndCaruselDisplay(image, imageBefore, imageAfter, imageBefore2, imageAfter2, item, item)
    
    return displayComponent;
}

export default Carousel;