const ImageLoader = ({ 
    loadedImages,
    setMetaData
}) => {

    let arr = [];
    if (Object.keys(loadedImages).length) {
        Object.keys(loadedImages).forEach(i => {
            let item = loadedImages[i]
            item.index = i;

            let imageClass = 'testMemeImageLoaderImageContainer'

            arr.push((
                <div className='testMemeImageLoaderImageContainer' key={i}>
                    <img
                        className='testMemeImageLoaderImageDisplay'
                        src={item.thumbnailUrl}
                        alt={item.title}
                        onLoad = {() => {
                            imageClass = 'ImageLoaderImageContainerDisplayNone'
                        }}
                        onClick={() => setMetaData(item)}
                        onError={(e) => {
                            e.target.onerror = null;
                            e.target.src = '/imageNotFound.jpg';
                            }
                        }
                    />
                </div>
            ))
            
        });
    } else {
        
        let imageClass = 'testMemeImageLoaderImageContainer'
        arr.push((
            <div className={imageClass} key={"testDefaultTemplate"}>
                <img
                    className='testMemeImageLoaderImageDisplay'
                    src="https://images.streammemes.gg/uploadedTemplates/testDefaultTemplate.jpg"
                    alt="testDefaultTemplate"
                    onLoad = {() => {
                        imageClass = 'ImageLoaderImageContainerDisplayNone'
                    }}
                    onClick={() => setMetaData({
                        url:"https://images.streammemes.gg/uploadedTemplates/testDefaultTemplate.jpg",
                        thumbnailUrl: "https://images.streammemes.gg/uploadedTemplates/testDefaultTemplate.jpg",
                        title: "testDefaultTemplate"
                    })}
                    onError={(e) => {
                        e.target.onerror = null;
                        e.target.src = '/imageNotFound.jpg';
                        }
                    }
                />
            </div>
        ))
    }
    return (<div className='testMemeImageLoaderImageContainerContainer'>{arr}</div>);
}

export default ImageLoader;