import './HomeContent.css'
import notificationService from '../../../../services/NotificationService';
import { useState, useEffect } from 'react';
import Tutorial from './Tutorial';
import memeService from '../../../../services/MemeService';
import pollingService from '../../../../services/PollingService';
import MostRecentMemesLogic from "./MostRecentMemesLogic";
import eula from '../../../../eula/eula.js'
import updateDisplayService from '../../../../services/UpdateDisplayService';
import LoadingDisplay from '../../../common/LoadingDisplay/LoadingDisplay';
import ManualApproval from '../../../Applications/memeApproval';
import Cookies from 'universal-cookie';

const cookies = new Cookies();

const HomeContent = () => {
  const [isMounted, setIsMounted] = useState(false);
  const [profanityFilterExist, setProfanityFilterExists] = useState(null);
  const [browserSourceIsActive, setBrowserSourceIsActive] = useState(null);
  const [selectedMemeExists, setSelectedMemeExists] = useState(null);
  const [testMemeExists, setTestMemeExists] = useState(null);
  const [fetchedBrowserSource, setFetchedBrowserSource] = useState("...")
  const [mostRecentMemes, setMostRecentMemes] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [currentImageDisplay, setCurrentImageDisplay] = useState(0);
  
  // dependencies for meme approval
  const [manualApprovalState, setManualApprovalState] = useState(false);
  const [currentMeme, setCurrentMeme] = useState(null);
  const [memesThatNeedApproval, setMemesThatNeedApproval] = useState([])

  useEffect(() => {

    setIsMounted(true);

    if (isMounted) {
      const displayEula = () => {
        updateDisplayService.setMessage(eula.EulaText);
          updateDisplayService.setTitle('Terms of service');
          updateDisplayService.onCancel(()=> {
            updateDisplayService.clearAll();
            window.location.href = window.location.origin
          })
          updateDisplayService.onAccept(()=> {
            // saves a cookie if the user has accepted the terms with value of timestamp
            document.cookie = `termsOfService=${Date.now()};expires=${new Date(new Date().getTime()+60*60*5000000*24).toGMTString()};path=/`;
            updateDisplayService.clearAll();
          })
      }

      const checkIfTermsOfServcieIsAccepted = () => {
        const timestampOfTermsOfService = cookies.get('termsOfService');
        if (timestampOfTermsOfService) {
          // if the cookie is older than the set timestamp in the eula we show the eula again
          // this means we updated the eula and want to display it again
          if (parseInt(timestampOfTermsOfService) <= eula.EulaTimeStamp) displayEula();
        }
        // if no cookie is found we display eula
        if (!timestampOfTermsOfService) displayEula();
      }

      const loadMostRecentMemes = (responseObject) => {
        setMostRecentMemes([]);
        let templateAccumulationArray = [];
    
          
        try {
    
          responseObject.memes.forEach((meme) => {
            return templateAccumulationArray.push(meme);
          });

          setMostRecentMemes(templateAccumulationArray);
        } catch (e) {
            notificationService.setMessage('There was a problem loading your most recent memes!')
            setMostRecentMemes([]);
        }
      
      };

      const getUrl = async (browserSourceResponse) => {
  
    
        if (browserSourceResponse.url) setFetchedBrowserSource(browserSourceResponse.url);
        else {
          let createdBS = await memeService.createBrowserSource();
          if (createdBS.url) setFetchedBrowserSource(createdBS.url);
          else notificationService.setMessage("Something went wrong getting the browser source !");
        }
      };

      async function loadListOfPendingApproval () {

        setMemesThatNeedApproval([])
        let templateAccumulationArray = [];
        let paginationToken = null;
    
        try {
     
          do {
            let responseObject = await memeService.listPendingApprovalMemes(paginationToken);
                    
            responseObject.memes.forEach((meme) => {
                return templateAccumulationArray.push(meme);
            });
           
            paginationToken = responseObject.paginationToken;
          } while (paginationToken);
  
          setMemesThatNeedApproval(templateAccumulationArray)
          setCurrentMeme(templateAccumulationArray[0] ? templateAccumulationArray[0] : null )
    
        } catch (error) {
          notificationService.setMessage("Error loading list of memes pending approval");
        }
      }

      function loadUserSettings (settings) {  
        try {
          setManualApprovalState(settings.manualApproval)
        } catch {
          notificationService.setMessage("Error loading manual approval status on Dashboard!");
        }
      };
      
      const loadSetupProgress = (result) => {
        setSelectedMemeExists(result.hasSelectedTemplates ? result.hasSelectedTemplates : false);
        setProfanityFilterExists(result.hasProfanityFilters ? result.hasProfanityFilters : false);
        setTestMemeExists(result.hasCreatedTestMeme ? result.hasCreatedTestMeme : false);
        setBrowserSourceIsActive(result.hasActiveConnection ? result.hasActiveConnection : false);
      };
      
      const loadPage = async () => {
        checkIfTermsOfServcieIsAccepted();

        const finishedApiCalls = await Promise.all([
          memeService.getBroadcasterSettings(),
          memeService.listMostRecentMemes(null),
          memeService.getSetupProgress(),
          memeService.getBrowserSource(),
        ]);
        
        loadUserSettings(finishedApiCalls[0]);
        loadMostRecentMemes(finishedApiCalls[1]);        
        loadSetupProgress(finishedApiCalls[2])
        await getUrl(finishedApiCalls[3]);

        await loadListOfPendingApproval();
    
        setIsLoading(false);
      };

      loadPage();
    }
    return () => {
      notificationService.setMessage(''); 
      notificationService.setTitle('')
      updateDisplayService.clearAll()
      setIsMounted(false)
    }
  }, [isMounted])

  useEffect(()=> {
    // Get first meme of the array for meme approval display component
    // This will rerender the component every time there is a change on memesThatNeedApproval
    // passing along the first element of the array. 
    // This needs to be top level!
    setCurrentMeme(memesThatNeedApproval[0] ? memesThatNeedApproval[0] : null )
  }, [memesThatNeedApproval])

  pollingService.usePollingInterval(async () => {
    async function fetchCurrentStateOfSetup() {
      let result = await memeService.getSetupProgress()
      setSelectedMemeExists(result.hasSelectedTemplates ? result.hasSelectedTemplates : false);
      setProfanityFilterExists(result.hasProfanityFilters ? result.hasProfanityFilters : false);
      setTestMemeExists(result.hasCreatedTestMeme ? result.hasCreatedTestMeme : false);
      setBrowserSourceIsActive(result.hasActiveConnection ? result.hasActiveConnection : false);
    }
    if (!isLoading) {
      // We only want to call this if we have
      // already loaded the page
      fetchCurrentStateOfSetup();
    }
  }, 1000 * 10, isLoading);

  let content = <LoadingDisplay />

  if (!isLoading) content = <div className="scrollableContainer">
    <div className="globalContentDiv">
      <Tutorial
        profanityFilterExist={profanityFilterExist}
        selectedMemeExists={selectedMemeExists}
        testMemeExists={testMemeExists}
        fetchedBrowserSource={fetchedBrowserSource}
        browserSourceIsActive={browserSourceIsActive}
        manualApprovalState={manualApprovalState}  
      />
      <ManualApproval 
        manualApprovalState={manualApprovalState}  
        currentMeme={currentMeme}
        setMemesThatNeedApproval={setMemesThatNeedApproval}
        setManualApprovalState={setManualApprovalState}
        browserSourceIsActive={browserSourceIsActive}
      />
      
      <MostRecentMemesLogic
        isLoading={isLoading}
        mostRecentMemes={mostRecentMemes}
        currentImageDisplay={currentImageDisplay}
        isMounted={isMounted}
        setCurrentImageDisplay={setCurrentImageDisplay}
        setMostRecentMemes={setMostRecentMemes}
      />
    </div>
  </div>

  return (content)
};

export default HomeContent;
