import './memeApprovalCss.css';
import BlueButton from '../../../common/BlueButton/BlueButton';
import BlueToggle from '../../../common/BlueToggle';
import { useState } from 'react';

const ManualApprovalDisplayLogicDashboard = ({
    manualApprovalState,
    currentMeme,
    browserSourceIsActive,
    remotelyApprovedby,
    onButtonClick,
    isApiCallInProgress
}) => {
    
    const [displayText, setDisplayText] = useState(false);

    let displayComponent = <></>

    
    const currentDisplayMemeUrl = currentMeme ? currentMeme.url : ''
    const currentDisplayMemeId = currentMeme ? currentMeme.id : 'none'
    const currentDisplayPrice = currentMeme ? currentMeme.price ? currentMeme.price + " Bits" : "" : ""
    const currentDisplayTopText = currentMeme ? currentMeme.topText ? currentMeme.topText : "" : ""
    const currentDisplayBottomText = currentMeme ? currentMeme.bottomText ? currentMeme.bottomText : "" : ""
    const currentDisplayName = currentMeme ? currentMeme.displayName ? currentMeme.displayName.toUpperCase() : "" : ""

    const textdisplayOfMeme = <div className='manualApprovalDashboardDisplayText'>
        <p>{currentDisplayPrice} </p>
        <hr className='manualApprovalLineDashboard'></hr>
        <p>{currentDisplayTopText}</p>
        <p>{currentDisplayBottomText}</p>
        <hr className='manualApprovalLineDashboard'></hr>
        <h4>{currentDisplayName}</h4>
    </div>

    const toggleTextDisplay = <div className='manualApprovalDashboardToggleForDisplayText'>
        <BlueToggle title={"Show text"} onToggle={()=> setDisplayText(!displayText)} state={displayText}/>
    </div>

    const imageDisplayMode = <div className="manualApprovalContainerImageDashboard">
        <img
            className='loadingDisplayMemeApprovalDashboard'
            src={currentDisplayMemeUrl}
            alt={currentDisplayMemeId}
            onError={(e) => {
                e.target.onerror = null;
                e.target.src = '/imageNotFound.jpg';
                }
            }
        />
        
        <p className='manualApprovalDisplayNameDashboard'>{currentDisplayName}</p>
        
    </div>

    const approveDenyButtonDisplay = <>
        <div className='manualApprovalButtonContainerDashboard'>
            <BlueButton text='✓' onClick={() => onButtonClick('APPROVE')} isCenter={true} />
            <BlueButton text='X' onClick={() => onButtonClick('DENY')} isCenter={true} isDark={true} />
        </div>
    </>

    const messageDisplayForNoMeme = (text) => {
        return <>
            <h2 className='manualApprovalTitleDashboard'>Pending Memes</h2>
            <div className='manualApprovalContainerDashboard'>
                <div className="manualApprovalContainerNoImageDashboard">
                    <p className='manualApprovalTextDisplayDashboard'>{text}</p>
                </div>
            </div>
        </>
    }

    // Display when meme is present 
    displayComponent = <>
        <h2 className='manualApprovalTitleDashboard'>Pending Memes</h2>
        <div className='manualApprovalContainerDashboard'>
            {toggleTextDisplay}
            {displayText ? textdisplayOfMeme : imageDisplayMode}
            {approveDenyButtonDisplay}
        </div>
        
    </>

    // Display when no meme in queue 
    if (!currentMeme && browserSourceIsActive) displayComponent = messageDisplayForNoMeme("No memes currently awaiting approval");

    // Display when toggled off and browser source is not active
    if (!manualApprovalState || !browserSourceIsActive)  displayComponent = <></>

    if (isApiCallInProgress) displayComponent = messageDisplayForNoMeme("Processing meme...");

    if (remotelyApprovedby && !isApiCallInProgress) {
        const action = remotelyApprovedby.action.toLowerCase();
        displayComponent = messageDisplayForNoMeme(`Meme got ${action} by another moderator`);
    }
   

    return displayComponent
}

export default ManualApprovalDisplayLogicDashboard;