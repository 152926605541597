import './topDisplayRight.css';
const TopDisplayRight = () => {

    // To replace the 2 images on top replace the files named 
    // in the public folder extentsionImage1 and extentsionImage2
    // this might have to be done every time we update our extentsion

    return <>
        <div className='displayComponentHomePageRightContainerTop'>
            <div className='displayComponentHomePageRightImage1Container'>
                <img src="/extentsionImage1.png" className="displayComponentHomePageRightImage1" alt="displayComponentHomePageRightImage1" />
            </div>
            <div className='displayComponentHomePageRightImage2Container'>
                <img src="/extentsionImage2.png" className="displayComponentHomePageRightImage2" alt="displayComponentHomePageRightImage2" />
            </div>
        
        </div>
    </>
}

export default TopDisplayRight;