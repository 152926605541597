import Container from "../../../common/Container";
import ContentTextContainer from "../../../common/ContentTextContainer";
import updateDisplayService from '../../../../services/UpdateDisplayService';
import './HelpContent.css';
import eula from '../../../../eula/eula.js'
const helpText = require('./helpText');

const HelpContent = () => {

    let displayComponent = <>
        <div className='helpTopContainer'>
            <h2>Questions?</h2>
        </div>
        <br/>
        <ContentTextContainer animationLeft={true} title={helpText.WhatIsStreamMemes} text={<p>{helpText.WhatIsStreamMemesText}</p>}/>
        <br/>
        <ContentTextContainer animationRight={true} title={helpText.WhatAboutCopyRight} text={<p>{helpText.WhatAboutCopyRightText}</p>}/>
        <br/>
        <ContentTextContainer animationLeft={true} title={helpText.WhyCanINotSetBits} text={<p>{helpText.WhyCanINotSetBitsText}</p>}/>
        <br/>
        <ContentTextContainer animationLeft={true} title={helpText.CanIUseStreamMemesWhileStreaming} text={<p>{helpText.CanIUseStreamMemesWhileStreamingText}</p>}/>
        <br/>
        <ContentTextContainer animationRight={true} title={helpText.CanIUseStreamMemesWithSLOBS} text={<p>{helpText.CanIUseStreamMemesWithSLOBSText}</p>}/>
        <br/>
        <ContentTextContainer animationLeft={true} title={helpText.WhatIsABrowserSource} text={<p>{helpText.WhatIsABrowserSourceText}</p>}/>
        <br/>
        <ContentTextContainer animationRight={true} title={helpText.BrowserSourceIsNotWorking} text={<p>{helpText.BrowserSourceIsNotWorkingtext}</p>}/>
        <br/>
        <ContentTextContainer animationLeft={true} title={helpText.BrowserSourceIsOffline} text={<p>{helpText.BrowserSourceIsOfflineText}</p>}/>
        <br/>
        <ContentTextContainer animationRight={true} title={helpText.HowToImplementInTwitchStudios} text={<p>{helpText.HowToImplementInTwitchStudiosText}</p>}/>
        <br/>
        <ContentTextContainer animationLeft={true} title={helpText.IHearTheSoundButMyViewersDoNot} text={<p>{helpText.IHearTheSoundButMyViewersDoNotText}</p>}/>
        <br/>
        <ContentTextContainer animationRight={true} title={helpText.MyViewersHearTheSoundButIDoNot} text={<p>{helpText.MyViewersHearTheSoundButIDoNotText}</p>}/>
        <br/>
        <ContentTextContainer animationLeft={true} title={helpText.MemesDisplayMultipleTimes} text={<p>{helpText.MemesDisplayMultipleTimesText}</p>}/>
        <br/>
        <ContentTextContainer animationRight={true} title={helpText.CanIUseStreamMemesWithLightStream} text={<p>{helpText.CanIUseStreamMemesWithLightStreamText}</p>}/>
        <br/>
        <p className='termsOfServcieDisplayText' onClick={() => {
            updateDisplayService.setMessage(eula.EulaText);
            updateDisplayService.setTitle('Terms of service');
            updateDisplayService.onCancel(()=> updateDisplayService.clearAll())
        }}>Terms of service</p>
          <br />
        <div className='helpBottomContainer'>
            <p>Could not find your answer? Contact us at support@streammemes.gg</p>
        </div>
    </>

    return  <div className="scrollableContainer"> 
        <Container content={displayComponent} isNoBackground={true} noAnimation={true}/>
    </div>;
}

export default HelpContent;