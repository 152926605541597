/*jshint esversion: 6 */

// © 2021 by Voxny404
//  _   __                    ____ ___  ____
// | | / /__ __ __ ___  __ __/ / // _ \/ / /
// | |/ / _ \\ \ // _ \/ // /_  _/ // /_  _/
// |___/\___/_\_\/_//_/\_, / /_/ \___/ /_/
//                    /___/
// https://voxny404.github.io/portfolio

const errorMessages = require('./errorMessages');

class ErrorHandler {

  handleErrorCode(object) {
    let response = null;
    if (!object.status) response = errorMessages.NO_STATUS_CODE;
    if (!object.body) response = errorMessages.NO_BODY_CODE;

    try {

      if (object.body) {
        let responseBodyObject = JSON.parse(object.body);
        response = this.getErrorResponse(responseBodyObject.errorCode);
      } else {
        response = this.getErrorResponse(object.errorCode);
      }

    } catch (e) {
      console.error(e);
      response = errorMessages.CATCH_ERROR;
    }
    return response;
  }

  bootOutMessage(message) {
    return window.location.href = "/?params=" + message;
  }

  displayMessage(message) {
    return errorMessages[message]
  }

  handleStatusError(status) {
    if (status === 200) return

    let statusMap = {
      403: 'SESSION_EXPIRED', 504: 'SERVER_NOT_REACHABLE'
    }
  
    if (statusMap[status]) this.bootOutMessage(statusMap[status]);
    if (status >= 500) this.bootOutMessage('DEFAULT_ERROR');
    return
  }

  handleFetch(object) {
    try {
      this.handleStatusError(object.status)
      return object.json();
    } catch (e) {
      console.error('FETCH ERROR: ' + object.status);
      this.handleStatusError(object.status)
      return object;
    }
  }

  handleFetchWithoutParsing(object) {
    this.handleStatusError(object.status)
    return object;
  }


  getErrorResponse(e) {
    let errorMessage = errorMessages[e];
    if (!errorMessage) errorMessage = errorMessages.DEFAULT_ERROR;
    return errorMessage;
  }
}
const singleton = new ErrorHandler();
export default singleton;
