import { useState } from "react";
import Container from "../../../common/Container";
import BuildingBlocksRouter from "./BuildingBlocksRouter";
import memeService from '../../../../services/MemeService';
import notificationService from '../../../../services/NotificationService';
import BlueScrollBackToTopButton from "../../../common/BlueScrollBackToTopButton";

const UploadDisplay = ({
    uploadedTemplates,
    setUploadedTemplates,
    userSettings,
    elementDeletedCount,
    setElementDeletedCount,
    selectedTemplatesMap,
    setSelectedTemplatesMap,
}) => {
    
    const [isButtonGray, setIsButtonGray] = useState(false);
    const [isButtonUploadGray, setIsButtonUploadGray] = useState(true);
    const [uploadFileData, setUploadFileData] = useState(null);
    const [nameInput, setNameInput] = useState('');
    const [isUpdatingImage, setIsUpdatingImage] = useState(false);
    const [isPublicUpload, setIsPublicUpload] = useState(true);
    const [uploadMessage, setUploadMessage] = useState('');

    //remove selected templates
    const removeUploadedTemplate = async (item) => {
        
        if (item.id) {
            setIsButtonGray(true);
            item.isLoading = true
            item.message = 'Removing ...'
            
            await memeService.deleteUploadedTemplate(item.id)
            
            item.isHidden = true;
            item.isLoading = false;
            setElementDeletedCount(elementDeletedCount + 1);
            setIsButtonGray(false);
            
            } else {
            notificationService.setMessage('Removing failed!')
            setIsButtonGray(false);
            item.isLoading = false
        }
    };

    const removeUploadedTemplateQuestion = (item) => {
        notificationService.setMessage(`Are you sure you want to delete ${item.title} ?`)
        notificationService.setTitle('Delete')
        notificationService.onClick(() => {
            //reset the window
            notificationService.clearAll()
            removeUploadedTemplate(item)
        })
    }

    // selects uploaded templates
    const selectUploadedTemplate = async (item) => {

        if (item.id) {
            setIsButtonGray(true);
            item.message = 'Adding ...'
            item.isLoading = true;

            try {
                let addedImage = await memeService.addSelectedTemplates('0', item.id, item.title, )
                if (addedImage.message) notificationService.setMessage('Max limit reached !');
                else {
                    let currentMap = selectedTemplatesMap
                    currentMap[item.thumbnailUrl] = item
                    setSelectedTemplatesMap(currentMap);
                    item.isLoading = false;
                    item.message = 'Successfully selected!';
                } 
            } catch (error) {
                notificationService.setMessage('Something went wrong when selecting your Meme!')
            }
            

            setTimeout(() => {
                item.message = '';
                item.isLoading = false;
                setIsButtonGray(false)
            }, 2000);
        } else {
            notificationService.setMessage('Removing failed!')
            item.isLoading = false;
            setIsButtonGray(false);
        }
    };

    //uploads a new template
    const uploadTemplate = async () => {
        setIsUpdatingImage(true);
        if (uploadFileData && nameInput && userSettings.uploadLimit >= uploadedTemplates.length) {
            setUploadMessage('Uploading ...')
            let newTemplate = await memeService.uploadTemplate(nameInput, isPublicUpload, uploadFileData)
            setNameInput('')
            setIsUpdatingImage(false)
            
            if (!newTemplate.template) {
                if (newTemplate.message) notificationService.setMessage('Max limit reached !');
                else notificationService.setMessage('Your Image is too big or text is too long !')
                setUploadMessage('')
            } else {
                if (uploadedTemplates.length) {
                    setUploadedTemplates([newTemplate.template, ...uploadedTemplates]);
                    setUploadMessage('Successfully uploaded!')
                    setUploadFileData(null)
                    setTimeout(() => setUploadMessage(''), 500)
                } else {
                    setUploadedTemplates([newTemplate.template])
                    setUploadMessage('Successfully uploaded!')
                    setUploadFileData(null)
                    setTimeout(() => setUploadMessage(''), 500)
                }
            }
   
        }

        if(!nameInput || !uploadFileData) {
            setIsUpdatingImage(false);
        }
    };

    const setImageAsBinaryString = (readerEvent) => {
        let binaryString = readerEvent.target.result;
        setUploadFileData(btoa(binaryString));
    };

    const convertFile = (e, dragEvent) => {
        const mb = 3
        // This is math I found on stack overflow to convert 3 mb into bits
        // When i first implemented it I also posted the source and how it works
        // We only need to change the first value which is 3 if we want a different mb size
        const maxAllowedSize = mb * 1024 * 1024;
        let file = dragEvent ? e.files[0] : e.target.files[0]
        
        if (file && file.size >= maxAllowedSize) {
            notificationService.setMessage('Your file is too large!');
            return;
        }

        if (!file.type.match(/image.*/)) {
            notificationService.setMessage('Your file is not an image!');
            return;
        }

        if (file && file.size <= maxAllowedSize) {
          const reader = new FileReader();
          reader.onload = setImageAsBinaryString;
          reader.readAsBinaryString(file);
        }
    };

    // remove selected image from upload preview
    const removeSetImage = () => setUploadFileData(false);
    
    //dynamic upload button 
    if (uploadFileData && nameInput.length > 0 && isButtonUploadGray && !isUpdatingImage) {
        setIsButtonUploadGray(false);
    }

    if(isUpdatingImage && !isButtonUploadGray){
        setIsButtonUploadGray(true);
    }

    //main content display 
    let content = <BuildingBlocksRouter 
                        uploadedTemplates={uploadedTemplates}
                        userSettings={userSettings}
                        elementDeletedCount={elementDeletedCount}
                        setUploadFileData={setUploadFileData}
                        setNameInput={setNameInput}
                        nameInput={nameInput}
                        isButtonUploadGray={isButtonUploadGray}
                        uploadTemplate={uploadTemplate}
                        removeUploadedTemplateQuestion={removeUploadedTemplateQuestion}
                        isButtonGray={isButtonGray}
                        selectUploadedTemplate={selectUploadedTemplate}
                        convertFile={convertFile}
                        uploadMessage={uploadMessage}
                        uploadFileData={uploadFileData}
                        selectedTemplatesMap={selectedTemplatesMap}
                        removeSetImage={removeSetImage}
                        isPublicUpload={isPublicUpload}
                        setIsPublicUpload={setIsPublicUpload}
                    />

    return  <div className="scrollableContainer">
        <BlueScrollBackToTopButton buttonShowThreshold={1000} left={true}/>
        <Container content={content} isNoBackground={true} animationLeft={true} />
    </div>
}

export default UploadDisplay;