import { Switch } from 'react-router-dom';
import TopMenu from '../Menus/TopMenu';
import RouteWithSubRoutes from '../../Routes/RouteWithSubRoutes';

const Settings = ({ routes, title, path }) => {
  return (
    <>
      <TopMenu
        routes={routes}
        title={title}
        baseRoute={path}
      />
      <Switch>
        {routes.map((route, i) => (
          <RouteWithSubRoutes key={i} {...route} />
        ))}
      </Switch>
    </>
  )
};

export default Settings;
