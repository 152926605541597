import { useState } from "react";
import './ContentTextContainer.css'

const ContentTextContainer = ({
    title,
    text,
    content,
    alignLeft,
    green,
    animationLeft,
    animationRight,
}) => {
    const [contentDisplay1, setContentDisplay1] = useState(false);
    const [style, setStyle] = useState('contentHelpContainer');
    const [boxStyle, setBoxStyle] = useState('');
    
    let displayComponent = <></>

    if (alignLeft && !boxStyle && !animationLeft && !animationRight) setBoxStyle('Left')
    if (animationLeft && style !== 'contentHelpContainerLeftAnimation') setStyle('contentHelpContainerLeftAnimation')
    if (animationRight && style !== 'contentHelpContainerRightAnimation') setStyle('contentHelpContainerRightAnimation')

    if (text) displayComponent = <>
        <div className={style + boxStyle} onClick={() => setContentDisplay1(!contentDisplay1)}>
            <h4 className={green ? "greenTitle" : ''}>{title}</h4>
            <div className={contentDisplay1 ? 'visibilContent' : 'hiddenContent' }>
                <hr/>
                {text}
            </div>
        </div>
    </>
    
    if (content) displayComponent = <>
        <div className={'contentHelpContainer'+ boxStyle} onClick={() => setContentDisplay1(!contentDisplay1)}>
            <h4>{title}</h4>
            <div className={contentDisplay1 ? 'visibilContent' : 'hiddenContent' }>
                <hr/>
                <>{content}</>
            </div>
        </div>
    </>

    return displayComponent;
}

export default ContentTextContainer;