import ImageDisplayElement from "./ImageDisplayElement";
import LoadingDisplay from "../../../common/LoadingDisplay/LoadingDisplay";

const DisplaySearch = ({ 
  templates,
  setMetaData,
  isLoading,
  markerRef,
  selectedTemplatesMap
}) => {
  //loops through the selected ot search templates
  const loadImages = (loadedImages) => {
    return <ImageDisplayElement 
              loadedImages={loadedImages} 
              setMetaData={setMetaData}
              selectedTemplatesMap={selectedTemplatesMap}
            />
  };

  let content = null;
  if (isLoading) {
    content = (
      <>
      {loadImages(templates)}
      <LoadingDisplay />
      </>
    )
  } else {
    //this will display recent searched images on input
    if (templates.length) {
      content = (
        <>
        {loadImages(templates)}
        </>
        )
    } else {
      content = (<h2 style={{textAlign:"center"}}>No Results</h2>)
    }
  }

  return (
    <>
      {content}
      <div className="marker" ref={markerRef}/>
    </>
  )
};

export default DisplaySearch;