import BlueButton from '../../../common/BlueButton/BlueButton.js';
import './middleDisplay.css';
const MiddleDisplay = () => {

        // To replace the memes in the middle view replace the files 
        // in the public folder named memeDisplayComponent1.png, ..2.png, ..3.png, ..4.png

    return <>
        <div className='displayComponentHomePageMiddleContainer'>
    
            <div className='displayComponentHomePageDescriptionContainer'> 
        
                <div className='displayComponentHomePageDescriptionTextContainer'>
                <fieldset className='displayComponentHomePageDescriptionTitleLegend'>
        
                    <legend className="displayComponentHomePageDescriptionTitleLegend">Increase your viewer engagement!</legend>
                    <div className='displayComponentHomePageDescriptionTextCard'>
                        <p>Install and activate the Stream Memes extension as a panel on your twitch channel. Provide your viewers with the ability to create fully customizable memes directly on your livestream.</p>
                        <div className='displayComponentHomeMemeDisplayComponentContainer'>
                            <img src="/memeDisplayComponent1.png" className="displayComponentHomeMemeDisplayComponent" alt="displayComponentHomeMemeDisplayComponent1" />
                        </div>
                    </div>
                    
                </fieldset>
                
                <fieldset className='displayComponentHomePageDescriptionTitleLegend'>
        
                    <legend className="displayComponentHomePageDescriptionTitleLegendRight">Mobile views!</legend>
                    <div className='displayComponentHomePageDescriptionTextCardReverse'>
                        <div className='displayComponentHomeMemeDisplayComponentContainer'>
                            <img src="/memeDisplayComponent2.png" className="displayComponentHomeMemeDisplayComponent" alt="displayComponentHomeMemeDisplayComponen2" />
                        </div>
                        <p>The Stream Memes extension is integrated into the official twitch app for iOS and Android. Your viewers will be able to create their very own memes without missing the action.</p>
                    </div>
                    
                </fieldset>
        
                <fieldset className='displayComponentHomePageDescriptionTitleLegend'>
        
                    <legend className="displayComponentHomePageDescriptionTitleLegend">Amplify your livestream earnings!</legend>
                    <div className='displayComponentHomePageDescriptionTextCard'>
                        <p>Your viewers are more likely to spend extra bits with Stream Memes. Customize the amount of bits for each meme individually.</p>
                        <div className='displayComponentHomeMemeDisplayComponentContainer'>
                            <img src="/memeDisplayComponent3.png" className="displayComponentHomeMemeDisplayComponent" alt="displayComponentHomeMemeDisplayComponent3" />
                        </div>
                    </div>
                    
                </fieldset>
        
                <fieldset className='displayComponentHomePageDescriptionTitleLegend'>
        
                    <legend className="displayComponentHomePageDescriptionTitleLegendRight">It looks the way you want it to!</legend>
                    <div className='displayComponentHomePageDescriptionTextCardReverse'>
                        <div className='displayComponentHomeMemeDisplayComponentContainer'>
                            <img src="/memeDisplayComponent4.png" className="displayComponentHomeMemeDisplayComponent" alt="displayComponentHomeMemeDisplayComponent4" />
                        </div>
                        <p>Simply upload your own images or use the Stream Memes library and select your favorite images for your viewers to create unique memes with.</p>
                    </div>
                    
                </fieldset>
        
                </div>
                
                <div className='displayComponentHomePageDescriptionButtonInstall'>
                    <a href='https://www.twitch.tv/ext/vry90hirenzsmkptzdqlb152l7n5mk' target='_blank' rel="noreferrer">
                        <BlueButton text='Install Extension' isLarge={true} onClick={()=> null}/>
                    </a>
                </div>
        
            </div>
        </div>
    </>
}

export default MiddleDisplay;