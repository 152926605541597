import { useState, useEffect } from 'react';
import memeService from '../../../services/MemeService'
import errorHandler from '../../../services/ErrorHandler'
import notificationService from '../../../services/NotificationService';
import TestMemeButtonDisplay from './TestMemeButtonDisplay';
import TestMemeSelectorDisplay from './TestMemeSelectorDisplay';

import './index.css';

const TestMemeContainer = ({
  extended
}) => {
  const [testMemeMessage, setTestMemeMessage] = useState('Create a Test Meme.');
  const [selectedTemplatesMap, setSelectedTemplatesMap] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [metaData, setMetaData] = useState(null);
  const [userInputTopText, setUserInputTopText] = useState("")
  const [userInputBottomText, setUserInputBottomText] = useState("")

  //creates a test meme button only
  const createTestMemeOnClick = async () => {
    setTestMemeMessage('Testing ... ');
    setIsLoading(true)
    
    try {

      const topText = userInputTopText.length > 0 ? userInputTopText : null
      const bottomText = userInputBottomText.length > 0 ? userInputBottomText : null
      const templateId = metaData ? metaData.url.split("/")[4].replace(".jpg", "") : null
  
      if (extended) {
        if (!topText && !bottomText) {
          setIsLoading(false)
          notificationService.setMessage("No text detected!");
          return
        }
      }
      
      let testMemeResopnse = extended
      ? await memeService.createTestMeme(topText, bottomText, templateId) 
      : await memeService.createTestMeme();
      
      if (testMemeResopnse.errorCode) {
        setTestMemeMessage(errorHandler.handleErrorCode(testMemeResopnse));
      }

      if (testMemeResopnse.status === "null"){
        setTestMemeMessage('Displaying test meme');
      }

      if (testMemeResopnse.status !== "null"){
        notificationService.setMessage(testMemeResopnse.status);
      }

      setTimeout(() => {
        setTestMemeMessage('Create a test meme.')
        setIsLoading(false)
        setMetaData(null)
        setUserInputTopText("")
        setUserInputBottomText("")
      }, 4000);
    } catch (e) {
      console.log(e, metaData);
      notificationService.setMessage('Failed to create a test meme!');
    }
  };

  const getSelectedTemplates = async () => {
    setSelectedTemplatesMap({});
    setTestMemeMessage('Getting memes ... ');

    let templateAccumulationMap = {};
    let paginationToken = null;
    try {
        setIsLoading(true)

        do {
        let responseObject = await memeService.listSelectedTemplates(paginationToken);

        responseObject.templates.forEach((template) => {
            return templateAccumulationMap[template.thumbnailUrl] = template;
        });

        setSelectedTemplatesMap(templateAccumulationMap);
        paginationToken = responseObject.paginationToken;
        } while (paginationToken);

        setIsLoading(false);
    } catch (e) {
        notificationService.setMessage('I could not load your selected memes!')
        setSelectedTemplatesMap({});
    }
  }

  useEffect(() => {
    getSelectedTemplates()
  }, [])

  let displayComponent = !extended ? <TestMemeButtonDisplay 
    createTestMemeOnClick={createTestMemeOnClick} 
    testMemeMessage={testMemeMessage} 
  /> : <TestMemeSelectorDisplay
    createTestMemeOnClick={createTestMemeOnClick} 
    testMemeMessage={testMemeMessage} 
    selectedTemplatesMap={selectedTemplatesMap}
    isLoading={isLoading}
    setMetaData={setMetaData}
    metaData={metaData}
    setUserInputTopText={setUserInputTopText}
    setUserInputBottomText={setUserInputBottomText}
    userInputTopText={userInputTopText}
    userInputBottomText={userInputBottomText}
  />

  return displayComponent
};

export default TestMemeContainer;
