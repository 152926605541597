import BlueButton from "../../../../common/BlueButton/BlueButton";
import PriceSelector from '../../../../common/PriceSelector';
import { useEffect } from 'react';

const ImageSelectorFrame = ({
    setMetaData,
    setIsReporting,
    metaData,
    addSelectedTemplate,
    buttonIsHidden,
    statusMessage,
    selectedTemplatesMap,
    templatePriceList
}) => {
    
    const defaultValueForPriceList = templatePriceList.length > 1 ? "100": "0"
    const templateIsSelected = selectedTemplatesMap[metaData.thumbnailUrl]

    useEffect(() => {
        metaData.newPrice = defaultValueForPriceList
    }, [defaultValueForPriceList, metaData]) 

    //render button or message
    let buttonState =  <BlueButton 
                        text='Select'
                        onClick={addSelectedTemplate}
                        isCenter={true}
    />  

    if (buttonIsHidden) buttonState = <p className='ImageSelectorStatusMessage'>{statusMessage}</p>
    if (templateIsSelected) buttonState = <p className='ImageSelectorStatusMessage'>Selected.</p>
    
    const buttonXClick = () => {
        const animationduration = 100
        const notifcationContainerAnimation = document.getElementById('ImageSelectorAnimationContainer');
        if (notifcationContainerAnimation) notifcationContainerAnimation.animate(
            [
                { transform: 'scale(1)' },
                { transform: 'scale(0,1)' }
            ], {
                duration: animationduration,
                once: true
            }
        )

        setTimeout(() => setMetaData(null), animationduration)
    }

    const priceSelectorContent = templateIsSelected ? <></> :  <PriceSelector 
        defaultValue={defaultValueForPriceList}
        onChange={e =>  metaData.newPrice = e.target.value }
        priceList={templatePriceList}
        isValid={true}
    />

    let content = <>
        <div className='ImageSelectorBackButtonContainer'>
            <button className='ImageSelectorBackButton' onClick={buttonXClick}>X</button>
        </div>
        <div className='reportFlagContainer' >
        <p className='reportFlag' onClick={() => setIsReporting(true)} >Report image</p>
        </div>
        <h3 className='ImageSelectorTitle'>{metaData.name ? metaData.name : metaData.title}</h3>
        <hr className='ImageSelectorLineBreak' />
        <br/>
        <img
            className='imageGridDisplay'
            src={metaData.thumbnailUrl}
            alt={metaData.title}
            onError={(e) => {
                e.target.onerror = null;
                e.target.src = '/imageNotFound.jpg';
                }
            }
        />
        <br/>
        <div className='ImageSelectorMetaDataContent'>
            <p>{`Creator : ${metaData.uploader} `}</p>
            {priceSelectorContent}
        </div>
        <br/>
        <br/>
        {buttonState}
    </>
    
    return content;
}

export default ImageSelectorFrame;