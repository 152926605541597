import BlueButton from '../BlueButton/BlueButton';
import LoadingDisplay from '../LoadingDisplay/LoadingDisplay';
import ImageLoader from './ImageLoader';
import InputBarSmall from '../InputBar/small';

const TestMemeSelectorDisplay = ({
    createTestMemeOnClick,
    testMemeMessage,
    selectedTemplatesMap,
    isLoading,
    setMetaData,
    metaData,
    setUserInputTopText,
    setUserInputBottomText,
    userInputTopText,
    userInputBottomText,
  }) => {
    const loadingDisplay = <>
        <LoadingDisplay invert={true} globalDivOff={true}/>
        <p className='testMemeLoadingText'>{testMemeMessage}</p>
    </>
    
    let displayComponent = loadingDisplay

    if (!isLoading && !metaData) displayComponent = <div className='testMemeContainerImage'>
        <h3 className='testMemeTitleSelection'>Select a meme to test</h3>
        <ImageLoader loadedImages={selectedTemplatesMap} setMetaData={setMetaData}/>
    </div>

    else if (isLoading) displayComponent = loadingDisplay
    
    else if (metaData && !isLoading) displayComponent = <div className='testMemeSelectionContainer'>
        <div className='testMemeBackButtonContainer'>
            <button className='testMemeBackBackButton' onClick={()=> setMetaData(null)}>X</button>
        </div>
        <h3 className='testMemeTitleSelection'>Meme creation</h3>
        <img
            className='testMemeImageLoaderImageDisplaySelected'
            src={metaData.thumbnailUrl}
            alt={metaData.title}
            
            onError={(e) => {
                e.target.onerror = null;
                e.target.src = '/imageNotFound.jpg';
                }
            }
        />
        <div className='testMemeButtonContainerSelection'>
            <InputBarSmall value={userInputTopText} onInput={e => setUserInputTopText(e.target.value)} placeholder="Top text"/>
            <InputBarSmall value={userInputBottomText} onInput={e => setUserInputBottomText(e.target.value)} placeholder="Bottom text"/>
            <BlueButton text={'Test'} onClick={createTestMemeOnClick} isLarge={false}/>
        </div>
        
    </div>
   

    return displayComponent
  }

export default TestMemeSelectorDisplay;