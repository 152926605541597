import MostRecentMemeContainerElement from "./mostRecentMemeContainerElement.js";
import './mostRecentMemes.css'
import { useCallback  } from 'react';
const MostRecentMemesLogic = ({ 
    isLoading,
    mostRecentMemes,
    currentImageDisplay,
    setCurrentImageDisplay,
    setMostRecentMemes
}) => {
    
    const changeDisplayImage = useCallback(() => {

        if (!mostRecentMemes) return
        
        let searchIndexInImageArray = currentImageDisplay + 1

        // look for existing images and ignore hidden / deleted images
        while (!mostRecentMemes[searchIndexInImageArray]) {

            if (mostRecentMemes.length <= searchIndexInImageArray) {
                searchIndexInImageArray = 0
                break;
            }
            searchIndexInImageArray = searchIndexInImageArray + 1
        }

        setCurrentImageDisplay(searchIndexInImageArray)
    }, [currentImageDisplay, mostRecentMemes, setCurrentImageDisplay]);

    const displayMostRecentmemes = () => {
        
        if(!isLoading) {
            return (<MostRecentMemeContainerElement 
                item={mostRecentMemes} 
                currentImageDisplay={currentImageDisplay} 
                setCurrentImageDisplay={setCurrentImageDisplay} 
                mostRecentMemes={mostRecentMemes} 
                setMostRecentMemes={setMostRecentMemes} 
                changeDisplayImage={changeDisplayImage}
            />)
        } 
    };

    return <div className="MostRecentMemeContainer">{displayMostRecentmemes()}</div>
}

export default MostRecentMemesLogic;