import { useEffect, useState, useReducer } from "react";
import Container from "../../../common/Container";
import LoadingDisplay from "../../../common/LoadingDisplay/LoadingDisplay";
import SelectManager from "./SelectManager";
import memeService from '../../../../services/MemeService';
import './Selected.css';
import BlueProgressBar from "../../../common/BlueProgressBar";
import notificationService from '../../../../services/NotificationService';
import BlueScrollBackToTopButton from "../../../common/BlueScrollBackToTopButton";

const Selected = () => {

    const [selectedTemplates, dispatchTemplateAction] = useReducer((state, action) => {
        switch (action.type) {
            case 'update':
                return state.map((currentTemplate) => {
                    if(action.template.id === currentTemplate.id) {
                        return action.template;
                    } else {
                        return currentTemplate;
                    }})
            case 'delete': 
                return state.filter((currentTemplate) => {
                    if (action.template.id === currentTemplate.id) {
                        return false;
                    }
                    return true;
                })
            case 'setState':
                return action.state
            default: 
                return state;
        }
    });
    const [isLoading, setIsLoading] = useState(true);
    const [userSettings, setUserSettings] = useState(null);
    const [isMounted, setIsMounted] = useState(false);

    const updateTemplateState = (template) => {
        dispatchTemplateAction({type: 'update', template: template})
    }

    const deleteTemplateState = (template) => {
        dispatchTemplateAction({type: 'delete', template: template})
    }

    const setSelectedTemplates = (templatesArray) => {
        dispatchTemplateAction({type: 'setState', state: templatesArray });
    }

    //get selected memes
    const getSelectedTemplates = async () => {
        setSelectedTemplates([]);
        let templateAccumulationArray = [];
        let paginationToken = null;
        
        try {
    
          do {
            let responseObject = await memeService.listSelectedTemplates(paginationToken);
            

            
            responseObject.templates.forEach((template) => {
                template.priceMap = responseObject.priceMap
                template.changed = false;
                template.loading = false;
                return templateAccumulationArray.push(template);
            });
    
            setSelectedTemplates(templateAccumulationArray);
            paginationToken = responseObject.paginationToken;
          } while (paginationToken);
          return selectedTemplates;
        } catch (e) {
            notificationService.setMessage('I could not load your selected memes!')
            setSelectedTemplates([]);
        }
    
    };

    const loadUserSettings = async () => {
        let settings = await memeService.getBroadcasterSettings();
        setUserSettings(settings);
        return;
    };

    const loadPage = async () => {
        await getSelectedTemplates();
        await loadUserSettings();
        return;
    };

    useEffect(() => {
        setIsMounted(true);
        if (isMounted) {
            setIsLoading(true);
            loadPage().then(()=> setIsLoading(false));
        }
        
         //fixing memory leak
         return () => setIsMounted(false)
    },[isMounted]) // eslint-disable-line react-hooks/exhaustive-deps

    
    //display either loading or selected templates
    const displaySelection = () => {

        if(isLoading) return <LoadingDisplay />
        
        if(!isLoading) {
            return (
                <div className="scrollableContainer">
                    <BlueScrollBackToTopButton buttonShowThreshold={1000} left={true}/>
                    <Container isNoBackground={true}
                     content={
                         <BlueProgressBar
                            title='Memes'
                            max={userSettings.storageLimit}
                            selected={selectedTemplates.length}
                         />
                    }/>
                    <Container isNoBackground={true} maxWidth={'1024px'}
                        content={<SelectManager 
                                    selectedTemplates={selectedTemplates}
                                    updateTemplateState={updateTemplateState}
                                    deleteTemplateState={deleteTemplateState} 
                                />} 
                    />
                </div>)
            
        }
    };
   
    return displaySelection();
}

export default Selected;