module.exports = {
    WhatIsStreamMemes: 'What is Stream Memes?',
    WhatIsStreamMemesText: 'Stream Memes is an interactive twitch extension that allows your viewers to generate their own memes on your channel, bringing a new level of engagement and entertainment to your stream. With the ability to create fresh, timely memes, your audience will enjoy a more interactive and dynamic experience, and have the ability to share their creations with the community. Say goodbye to stagnant content and hello to a constant flow of new and exciting memes generated by your viewers.',
    WhatAboutCopyRight: 'What about copy right?',
    WhatAboutCopyRightText: 'By uploading an image to Stream Memes, users confirm that they hold the rights to the image. If they opt to make their upload public, it will be added to a searchable library and they grant other streamers permission to use it.',
    WhyCanINotSetBits: 'Why can I not set Bits for memes?',
    WhyCanINotSetBitsText: 'You must be a Twitch Partner or Affiliate to be able to set Bits for an image.  If you are Partner or Affiliate and unable to set Bits for your images, please contact us at support@streammemes.gg for assistance.',
    CanIUseStreamMemesWhileStreaming: 'Can I use Stream Memes while streaming via console or smartphone?',
    CanIUseStreamMemesWhileStreamingText: "Stream Memes can be used with many broadcasting software and services, including OBS, Streamlabs and more. However, in order to use Stream Memes, you must first implement it as a browser source in your broadcasting software. This can be done easily, but if you cannot do this, you may not be able to stream with Stream Memes. If you are streaming directly with a game console or smartphone, it is not possible to implement a browser source and therefore Stream Memes cannot be used. If you want to stream your game console gameplay, we recommend capturing the game console signal with a capture device on your PC and streaming your console gameplay via PC.",
    CanIUseStreamMemesWithSLOBS: 'Can I use Stream Memes with Stream Labs OBS?',
    CanIUseStreamMemesWithSLOBSText:"Yes, most broadcasting software (Streamlabs OBS, OBS, Twitch Studios, Xsplit, etc.) can be used for Stream Memes by adding a browser/webpage source layer. This allows the user to open a webpage with the Stream Memes overlay and place it over their stream for viewers to see.",
    WhatIsABrowserSource:'What is a browser source?',
    WhatIsABrowserSourceText:"A browser source is a layer that can be implemented in your broadcasting software, such as OBS, OBS.Live, Streamlabs OBS, XSplit, or Player.me, to display Stream Memes created memes and notification sounds. It is important to note that browser sources are not supported by Twitch, but are supported by major broadcasting software. Depending on the software, browser sources may also be referred to as Webpages.",
    BrowserSourceIsNotWorking:'Browser source is not working',
    BrowserSourceIsNotWorkingtext:`To ensure you have the correct browser source URL, open your broadcasting software and check the URL for the browser source. If the URL is correct, try refreshing the cache of the browser source. If this does not help, open the settings and go to the Advanced tab. Try enabling or disabling the Browser Source Hardware Acceleration and see if this helps.`,
    BrowserSourceIsOffline:'Browser source is offline / not active',
    BrowserSourceIsOfflineText:`If the Stream Memes extension on Twitch says your browser source is offline, you need to ensure that the browser source of Stream Memes is active in your current scene of your broadcasting software. If it's still not online, try refreshing the cache of the browser source. If this still does not work, go to your advanced settings and try enabling or disabling the Browser Source Hardware Acceleration to see if that helps.`,
    HowToImplementInTwitchStudios: 'What if I want to use the Twitch Studios broadcasting software?',
    HowToImplementInTwitchStudiosText: `First, open Twitch Studios and select a layout. Then, edit the layout by adding a new Embed Webpage layer. Paste the Stream Memes browser source URL in the webpage text field and open the Stream Memes configuration. Send a test alert to make sure it is working properly. If you need to, increase the size of this layer. Repeat these steps for every other layout you want to have Stream Memes active in.`,
    IHearTheSoundButMyViewersDoNot: 'I hear the notification but my viewers do not',
    IHearTheSoundButMyViewersDoNotText:'This differs depending upon your broadcasting software.  For example, in OBS open the Audio Settings from the OBS Audio Mixer and make sure the Stream Memes browser source is set to Monitor and Output',
    MyViewersHearTheSoundButIDoNot:"My viewers hear the notification but I don't.",
    MyViewersHearTheSoundButIDoNotText:`If you are using OBS, open the Audio Settings from the OBS Audio Mixer and ensure that the Stream Memes Browser source is set to Monitor and Output. Adjust the volume levels as needed to ensure that the audio is loud and clear.`,
    MemesDisplayMultipleTimes: 'Memes are displyed multiple times at once.',
    MemesDisplayMultipleTimesText: `Make sure you implement the Stream Memes browser source only once in your main scene of your streaming software. Copy and paste the browser source URL to the browser sources of the other scenes instead of creating a new browser source for each scene. This will help reduce the load on your system resources and optimize your streaming performance.`,
    CanIUseStreamMemesWithLightStream: 'Can I use Stream Memes with Lightstream?',
    CanIUseStreamMemesWithLightStreamText: `Unfortunately, Lightstream does not have a browser source feature which is necessary to use Stream Memes.`,
};