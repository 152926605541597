import BlueButton from "../../common/BlueButton/BlueButton";
import CardContainer from "../../common/CardContainer";
import notificationService from '../../../services/NotificationService';

const ContentCard = ({
    reportedUploadedTemplates,
    deleteReported,
    isButtonGray,
    deleteReportedImageFromSearch,
    deleteReportedImage,
    timeConverter
}) => {
    
    const deleteReportedImageFromSearchQuestion = (templateId,item) => {
        notificationService.setMessage(`Are you sure you want to delete ${templateId} from SEARCH ?`)
        notificationService.setTitle('Delete')
        notificationService.onClick(() => {
            //reset the window
            notificationService.clearAll()

            deleteReportedImageFromSearch(templateId,item)
            
        })
    }


    const deleteReportedQuestion = (templateId,item) => {
        notificationService.setMessage(`Are you sure you want to delete the report for ${templateId} ?`)
        notificationService.setTitle('Delete')
        notificationService.onClick(() => {
            //reset the window
            notificationService.clearAll()
            
            deleteReported(templateId,item)
        
        })
    }

    const deleteReportedImageQuestion = (templateId,item) => {
        notificationService.setMessage(`Are you sure you want to delete ${templateId} COMPLETELY?`)
        notificationService.setTitle('Delete')
        notificationService.onClick(() => {
            //reset the window
            notificationService.clearAll()

            deleteReportedImage(templateId,item)
            
        })
    }
           //loop through each elemnt
           let arr = [];

           if (reportedUploadedTemplates.length) {
               reportedUploadedTemplates.forEach((item, i) => {
               let reporterList = item.reporters.replace(/[^0-9\n]/g,'')

               let buttons = item.imageRemoved === 'true' ? <p>{item.message ? item.message : 'Deletion Pending!'}</p> : <>
                    <p>{item.message ? item.message : 'Select options'}</p>
                    <BlueButton 
                            isCenter={true} 
                            text='Remove from search' 
                            onClick={() => deleteReportedImageFromSearchQuestion(item.templateId, item)}
                            isLarge={true}
                        />
                    <br/>
                    <br/>
                    <BlueButton 
                            isCenter={true} 
                            text='Remove completly' 
                            onClick={() => deleteReportedImageQuestion(item.templateId, item)}
                            isLarge={true}
                        />
               </>
               
               let content = <CardContainer 
                                   item={item} 
                                   removeItem={() => deleteReportedQuestion(item.templateId, item)} 
                                   isButtonGray={isButtonGray} 
                                   contentRight={
                                       <>
                                          {buttons}
                                       </>
                                   }
                                   contentLeft={
                                       <>
                                           <p>Report reason : {item.reason ? item.reason : 'NO INFO'}</p>
                                           <p>Report count  : {item.count ? item.count : 'NO INFO'}</p>
                                           <p>Image creator : {item.creator ? item.creator : 'NO INFO'}</p>
                                           <p>Last reported : {item.time ? timeConverter(item.time) : 'NO INFO'}</p>
                                           <p>Searchable   : {item.searchable ? item.searchable : 'NO INFO'}</p>
                                           <p>Completly Deleted   : {item.imageRemoved ? item.imageRemoved : 'NO INFO'}</p>
                                           <p>Reported by   : {JSON.stringify(reporterList)}</p>
                                       </>
                                   }
                                   reportCount={item.count ? item.count : 0}
                               />
   
                   //here we sort out the deleted elements
                   if(item.isHidden) content = <></>
   
                   arr.push((<div key={i}>{content}</div>))        
               });
           }
           return (<>{arr}</>);
}

export default ContentCard;