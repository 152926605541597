import { useEffect, useRef, useState } from 'react';

export const usePollingInterval = (callback, delay, isMounted=true) => {

  const [hasMadeInitialCall, setHasMadeInitialCall] = useState(false);

  const savedCallback = useRef();

  useEffect(() => {
    if (isMounted && !hasMadeInitialCall) {
      callback();
      setHasMadeInitialCall(true);
    }
  },[callback, isMounted, hasMadeInitialCall])

  useEffect(() => {
    if (hasMadeInitialCall) {
      savedCallback.current = callback;
    }
  }, [callback, hasMadeInitialCall]);


  useEffect(() => {
    if (hasMadeInitialCall) {
      function tick() {
        savedCallback.current();
      }
      if (delay !== null) {
        const id = setInterval(tick, delay);
        return () => clearInterval(id);
      }
    }
  }, [delay, hasMadeInitialCall]);
}

const pollingService = {usePollingInterval}

export default pollingService;