class UpdateDisplayService {
    constructor () {
        this.title = null;
        this.onClickFunction = null;
        this._registeredSetMessageFunction = null;
        this._registeredSetTitleFunction = null;
        this._registeredSetOnClickFunction = null;
        this._registeredSetOnClickFunction2 = null;
        this._registeredSetButtonText = null;
        this._registeredSetButtonText2 = null;
        this._registeredSetButtonGray = null;
        this._registeredSetButtonGray2 = null;
    }
    _registerSetMessageFunction(setMessageFunction) {
        this._registeredSetMessageFunction = setMessageFunction;
    }

    _registerSetTitleFunction(setTitleFunction) {
        this._registeredSetTitleFunction = setTitleFunction;
    }

    _registerSetOnClickFunction(setOnClickFunction) {
        this._registeredSetOnClickFunction = setOnClickFunction;
    }

    _registerSetOnClickFunction2(setOnClickFunction2) {
        this._registeredSetOnClickFunction2 = setOnClickFunction2;
    }

    clearMessage() {
        this._registeredSetMessageFunction(null);
    }

    clearAll() {
        this._registeredSetMessageFunction(null);
        this._registeredSetOnClickFunction(null);
        this._registeredSetOnClickFunction2(null);
        this._registeredSetTitleFunction(null);
    }
    
    setMessage(message) {
        this._registeredSetMessageFunction(message);
    }
    
    setTitle(title) {
        this._registeredSetTitleFunction(title);
    }


    onCancel(fun) {
        this._registeredSetOnClickFunction(fun);
    }

    onAccept(fun) {
        this._registeredSetOnClickFunction2(fun);
    }

}

const singleton = new UpdateDisplayService();
export default singleton;