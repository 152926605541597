import BlueButton from '../BlueButton/BlueButton';
const TestMemeButtonDisplay = ({
    createTestMemeOnClick,
    testMemeMessage
  }) => {
    return (
        <div className='testMemeContainer'>
          <div className='testMemeContent'>
            <BlueButton text={'Test'} onClick={createTestMemeOnClick} isLarge={true}/>
            <span className='testMemeMessageText'>{testMemeMessage}</span>
          </div> 
        </div>
      );
  }

export default TestMemeButtonDisplay;
