import './BrowserSourceContent.css';
import { useState } from 'react';
import BlueButton from '../../../../common/BlueButton/BlueButton';
import Container from '../../../../common/Container';
import notificationService from '../../../../../services/NotificationService';

const BrowserSourceLogic = ({ fetchedBrowserSource }) => {
  const [urlIdClass, setUrlIdClass] = useState('urlIDTextDisplayBlurHomeContent')
  
  const copyToClipboard = async() => {
    await navigator.clipboard.writeText(fetchedBrowserSource);
    notificationService.setMessage(`Successfully copied to clipboard!`)
    notificationService.setTitle('Browser Source')
  };

  const changeUrlDisplay = () => {
    if (urlIdClass === 'urlIDTextDisplayBlurHomeContent') setUrlIdClass('urlIDTextDisplayHomeContent')
    else setUrlIdClass('urlIDTextDisplayBlurHomeContent')
  };
  
  let content1 = <div className='urlID'>
                  <p className="browserSourceDescriptionHome">To successfully configure stream memes, you must add a browser source layer to your streaming software with the proper URL. This will allow for memes to be displayed on your stream for your viewers to enjoy.</p>
                 <div className='browserSourceLinkAndButtonHome'>
                  <BlueButton text={urlIdClass === 'urlIDTextDisplayBlurHomeContent' ? 'Show' : 'Hide'} onClick={changeUrlDisplay} isDark={true} isGray={false}/>
                  <p className={urlIdClass}>{fetchedBrowserSource}</p>
                 </div>
                 <div className='CopyButtonBrowserSourceHome'>
                  <BlueButton isLarge={true} text='Copy' onClick={copyToClipboard} isGray={false}/>
                 </div>
                  <div className='warningContainerUrlId'>
                    <img className='warningSignImage' src='/warningSign.svg' alt='warning' />
                    <span className='warningSignText'> Browser Source URL contains sensitive information. Do not share it! </span >
                  </div>  
                </div>
  
  return (
    <div>
      <Container title={'Your OBS Browser Source URL'} content={content1}/>
    </div>
  );
};

export default BrowserSourceLogic;
